import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Link from "@material-ui/core/Link";

import attachmentsModalStyle from "../../../assets/jss/user/attachmentsModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const AttachmentsModal = class extends React.Component {
    getFile(file, key){
        return (
            <Link href={file.downloadLink} target="_blank" key={key}>
                {file.name}
            </Link>
        );
    }
    render() {
        const { classes, attachmentFiles } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="attachments-modal-slide-title"
                aria-describedby="attachments-modal-slide-description"
            >
                <DialogTitle
                    id="attachments-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        <h4 className={classes.modalTitle+" "+classes.textCenter}>{this.props.title}</h4> 
                </DialogTitle>
                <DialogContent id="attachments-modal-slide-description" className={classes.modalBody}>
                    {
                        attachmentFiles && attachmentFiles.length > 0 ?
                            attachmentFiles.map((file, key) => {
                                return this.getFile(file, key)
                            })
                        :
                        <></>
                    }
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    <Button color="custom" onClick={() => this.props.onClose()}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

AttachmentsModal.defaultProps = {
    open: false,
    title: "Attachment Files"
}
AttachmentsModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    title: PropTypes.string
}
export default withStyles(attachmentsModalStyle)(AttachmentsModal);
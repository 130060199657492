import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomSelect from "../CustomInput/CustomSelect";
import Close from "@material-ui/icons/Close";
import LoaderComponent from "../Loader";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";

import shareUserStatusModalStyle from "../../../assets/jss/user/shareUserStatusModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const ShareUserStatusModal = class extends React.Component {
    constructor(props){
        super(props);

        let status = this.props.status;
        this.state = {
            status: status,
            notify: false,
            checkboxText: this.getcheckboxText(status)
        };

        this.handleStatusChange = this.handleStatusChange.bind(this);

        if(status === 'pending'){
            this.statusOptions = [
                { value: "Pending", key: "pending" },
            ];    
        }else if(status === 'viewonly'){
            this.statusOptions = [
                { value: "view only", key: "viewonly" },
            ];    
        }else if(status === 'active'){
            this.statusOptions = [
                { value: "Active", key: "active" },
                { value: "Expired", key: "expired" },
                { value: "Suspend", key: "suspend" }
            ];
        }else if(status === 'expired'){
            this.statusOptions = [
                { value: "Active", key: "active" },
                { value: "Expired", key: "expired" },
                { value: "Suspend", key: "suspend" }
            ];
        }else if(status === 'suspend'){
            this.statusOptions = [
                { value: "Active", key: "active" },
                { value: "Expired", key: "expired" },
                { value: "Suspend", key: "suspend" }
            ];
        }
    }
    getcheckboxText(status){
        if(status === 'pending'){
            return "Resend Activation Email";
        }else{
            return "Notify Member";
        }
    }
    handleStatusChange(e, name){
        this.setState({
            [name]: e.target.value,
            checkboxText: this.getcheckboxText(e.target.value)
        });
    }
    handleCheckbox(e, name){
        this.setState({
            [name]: e.target.checked
        });
    }
    render() {
        const { classes, saving, confirmationMessage } = this.props;
        const { status, notify, checkboxText } = this.state;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="confirmationModal-modal-slide-title"
                aria-describedby="confirmationModal-modal-slide-description"
            >
                <DialogTitle
                    id="confirmationModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        <Button
                            simple
                            className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.onClose()}
                        >
                            {" "}
                            <Close className={classes.modalClose} />
                        </Button>
                        {
                            saving ?
                                <></>
                            :
                            <h4 className={classes.modalTitle+" "+classes.textCenter}>{confirmationMessage}</h4> 
                        }
                </DialogTitle>
                <DialogContent
                    id="confirmationModal-modal-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            saving ?
                                <LoaderComponent color="custom" align="center" />
                            :
                            <>
                                <CustomSelect 
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    labelText={"Status"}
                                    selectProps={{
                                        onChange: (e) => this.handleStatusChange(e, 'status'),
                                        value: status
                                    }}
                                    inputProps={{
                                        name: "status",
                                    }}
                                    color="custom"
                                    items={this.statusOptions}
                                    itemLabel="value"
                                    itemValue="key"
                                />
                                <FormControlLabel
                                    classes={{label: classes.label}}
                                    control={
                                        <Checkbox                                                
                                            tabIndex={-1}
                                            onClick={(e) => this.handleCheckbox(e, "notify")}
                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                            icon={<Check className={classes.uncheckedIcon} />}
                                            classes={{checked: classes.checked, root: classes.checkRoot}}
                                            checked={(notify ? true : false)}
                                        />
                                    }
                                    label= {checkboxText}
                                />
                            </>
                        }
                </DialogContent>
                {
                    saving ?
                        <></>
                    :
                    <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                        <Button color="custom" disabled={notify === true ? false : true} className={classes.changeButton} onClick={() => this.props.onSuccess(status)}>
                            Submit
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        )
    }
}

ShareUserStatusModal.defaultProps = {
    open: false,
    confirmationMessage: "",
    saving: false,
}
ShareUserStatusModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    confirmationMessage: PropTypes.string,
    saving: PropTypes.bool,
}
export default withStyles(shareUserStatusModalStyle)(ShareUserStatusModal);
import { grayColor, whiteColor, blackColor, hexToRgb } from "../material-kit-pro-react.jsx";

const newUpdateModalStyle = theme => ({
  modalRoot: {
    overflow: "auto",
    display: "block"
  },
  modal: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "950px",
      margin: "auto"
    },
    borderRadius: "6px",
    overflow: "visible",
    maxHeight: "unset",
    width: "100%",
    marginTop: "50px !important",
    marginBottom: "50px !important",
    backgroundColor: "#2b3249",
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(3)
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "30px !important",
      margin: theme.spacing(2),
      width: "400px",
      maxWidth: "100%"
    }
  },
  modalHeader: {
    borderBottom: "none",
    paddingTop: "0px",
    paddingRight: "0px",
    paddingBottom: "0px",
    paddingLeft: "0px",
    minHeight: "0px"
  },
  modalTitle: {
    margin: "0",
    marginTop: "20px",
    lineHeight: "1.5",
    textAlign: "center"
  },
  modalCloseButton: {
    "&, &:hover": {
      color: whiteColor,
      opacity: "1"
    },
    "&:hover": {
      opacity: "1"
    },
    cursor: "pointer",
    padding: "1rem",
    margin: "-1rem -1rem -1rem auto",
    backgroundColor: "transparent",
    border: "0",
    WebkitAppearance: "none",
    float: "right",
    fontSize: "1.5rem",
    fontWeight: "500",
    lineHeight: "1",
    textShadow: "0 1px 0 " + whiteColor,
    opacity: ".5"
  },
  modalClose: {
    width: "16px",
    height: "16px"
  },
  modalBody: {
    paddingTop: "20px",
    paddingRight: "15px",
    paddingBottom: "15px",
    paddingLeft: "15px",
    position: "relative",
    overflow: "visible",
    textAlign: "center",
    marginTop: "15px",
    "& .second-grid": {
        marginTop: "25px",
    },
    "& .popup-listing-content": {
        paddingBottom: "15px",
        textAlign: "center",
        "&.align-left": {
            textAlign: "left",
        }
    },
    "& .sd-post-image-bg": {
        position: "relative",
        "& .play-icon": {
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
            "& svg": {
                fill: theme.customize.iconsColor,
                width: "60px",
                height: "60px",
            }
        },
        "& img": {
            display: "inline-block",
            maxWidth: "100%",
        }
    },
    "& .sd-post-margin-content": {
        background: whiteColor,
        padding: "10px 0px",
        maxWidth: "100%",
        margin: "0px auto 0 auto",
        position: "relative",
        "& h2": {
            marginBottom: "0px",
            marginTop: "0px",
            fontSize: "14px",
            lineHeight: "normal",
            fontWeight: "400",
            color: "#515151"
        },
        "& .sd-post-short-content": {
            marginBottom: "15px",
            fontSize: "14px",
        },
        "& .sd-readmorebtn": {
            transition: "all 0.5s ease-out 0s",
            textDecoration: "none !important",
            color: grayColor[4],
            border: "2px solid "+grayColor[4],
            padding: "8px 15px",
            fontSize: "13px",
            display: "inline-block",
            boxSizing: "border-box",
            lineHeight: "normal !important",
            "&:hover": {
                background: grayColor[4],
                color: whiteColor,
            }
        }
    },
    "& .sd-video-iframe": {
        marginBottom: "30px",
        "& iframe": {
            minHeight: "350px",
            maxWidth: "600px",
            width: "100%",
            [theme.breakpoints.down("xs")]: {
                minHeight: "280px"
            }
        },
        "& p": {
            display: "block",
            fontSize: "18px",
            color: whiteColor
        }
    }
  },
  modalFooter: {
    padding: "15px",
    paddingTop: "0",
    margin: "0 auto"
  },
  modalFooterCenter: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  instructionNoticeModal: {
    marginBottom: "25px"
  },
  imageNoticeModal: {
    maxWidth: "150px"
  },
  modalLarge: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "800px"
    }
  },
  modalSmall: {
    [theme.breakpoints.up("sm")]: {
      width: "300px",
      margin: "auto"
    },
    margin: "0 auto"
  },
  modalSmallBody: {
    marginTop: "20px"
  },
  modalSmallFooterFirstButton: {
    margin: "0",
    paddingLeft: "16px",
    paddingRight: "16px",
    width: "auto"
  },
  modalSmallFooterSecondButton: {
    marginBottom: "0",
    marginLeft: "5px"
  },
  modalLogin: {
    maxWidth: "360px",
    overflowY: "visible",
    width: "100%",
    "& $modalCloseButton": {
      color: whiteColor,
      top: "-10px",
      right: "10px",
      textShadow: "none",
      position: "relative"
    },
    "& $modalHeader": {
      borderBottom: "none",
      paddingTop: "24px",
      paddingRight: "24px",
      paddingBottom: "0",
      paddingLeft: "24px"
    },
    "& $modalBody": {
      paddingBottom: "0",
      paddingTop: "0"
    },
    "& $modalFooter": {
      paddingBottom: "0",
      paddingTop: "0"
    }
  },
  modalLoginCard: {
    marginBottom: "0",
    margin: "0",
    "& $modalHeader": {
      paddingTop: "0"
    }
  },
  modalSignup: {
    maxWidth: "900px",
    width: "100%",
    "& $modalHeader": {
      paddingTop: "0"
    },
    "& $modalTitle": {
      textAlign: "center",
      width: "100%",
      marginTop: "0.625rem"
    },
    "& $modalBody": {
      paddingBottom: "0",
      paddingTop: "0"
    }
  },
  modalSignupCard: {
    padding: "40px 0",
    margin: "0"
  },
  modalCloseButtonCustom: {
    top: "16px !important",
    right: "16px !important",
    position: "absolute !important"
  },
  skipLink: {
      textDecoration: "none !important",
      cursor: "pointer",
      color: whiteColor,
      padding: "8px 30px",
      marginBottom: "5px",
      backgroundColor: "rgba(" + hexToRgb(blackColor) + ",0.5)",
      "&:hover": {
        color: whiteColor,
      }
  },
    justifyContentCenter: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "center !important"
    },
});

export default newUpdateModalStyle;

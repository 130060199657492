/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
// core components

import customInputStyle from "../../../assets/jss/material-kit-pro-react/components/customInputStyle.jsx";

function CustomInput({ ...props }) {
  const {
    classes,
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    verified,
    tags,
    onTagRemove
  } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white
  });
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
    [classes.inputIcon]: error || success || verified ? true : false
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  function selectText(e){
      if(e.target && e.target.type === "text"){
        e.target.setSelectionRange(0, e.target.value.length);
      }    
  }
  function renderInput(){
    return (
        <Input
            classes={{
            input: inputClasses,
            root: marginTop,
            disabled: classes.disabled,
            underline: underlineClasses
            }}
            id={id}
            onClick={selectText}
            {...inputProps}
        />
    )
  }
  return (
    <FormControl {...formControlProps} className={formControlClasses+" "+(tags !== null ? classes.tagsInput : "")}>
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + " " + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      {
        tags !== null && Array.isArray(tags) ?
            <div className={classes.tags}>
                {
                    tags.map((tag) => {
                        return (
                            <span className={classes.tag} key={tag}>
                                {tag}
                                <span className={classes.closeTag} onClick={() => onTagRemove(tag)}><Close /></span>
                            </span>
                        )
                    })
                }
                {renderInput()}
            </div>
        :
        renderInput()
      }
      
      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : verified ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess+" "+classes.verifiedCheck} />
      ) : null}
    </FormControl>
  );
}

CustomInput.defaultProps = {
    tags: null,
    onTagRemove: function(){}
}
CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  verified: PropTypes.any,
  tags: PropTypes.array,
  onTagRemove: PropTypes.func
};

export default withStyles(customInputStyle)(CustomInput);

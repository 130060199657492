import React from 'react';
import { Helmet } from 'react-helmet';
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import Auth from "../../../assets/js/utils/Auth";
import PropTypes from "prop-types";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import AccountSidebar from "../../components/User/AccountSidebar";
import Button from "../../components/CustomButtons/Button";
import LoaderComponent from '../../components/Loader';
import GridItem from "../../components/Grid/GridItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

import withStyles from "@material-ui/core/styles/withStyles";
import userSettingsPageStyle from "../../../assets/jss/user/userSettingsPageStyle.jsx";

const UserExtensions = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        const { user } = this.store.getState();
        this.state = {
            user: user,
            extensions: user.extensions,
            saving: false,
            validation: {
                isValid: false,
            },
            cancelToken: null,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount(){
        const { authorized, user } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
            return;
        }

        if(authorized &&user.hasOwnProperty('businessUser') && user.businessUser !== null && user.member_type !== 'business'){
            let redirectUrl = "/user/home";
            if(user.level_id === 5){
                redirectUrl = "/user/shared";
            }
            this.history.push(redirectUrl);
        }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    handleSubmit(e){
        e.preventDefault();
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }

        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, saving: true});
        const { extensions } = this.state;
        const requestData = {
            extensions: extensions,
        }

        Api.updateUserExtensions(requestData, source).then(data => {
            localStorage.setItem("sendlinx_userinfo", JSON.stringify(data.body));
            let newUser = Api.prepareMemberObject(data.body);
            this.store.dispatch({type: "UPDATE_STATE",state: {user: newUser}});
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({cancelToken: null, saving: false});
            }  
        });
    }
    handleCheckbox(e, name){
        this.setState({
            extensions: {
                ...this.state.extensions, 
                [name]: {
                    'status': e.target.checked ? 1 : 0
                }
            }
        }, () => {
            if(!this.state.extensions.one_drive.status){
                this.setState({
                    extensions: {
                        ...this.state.extensions, 
                        one_drive: {
                            ...this.state.extensions.one_drive,
                            options: {
                                transfer_to_my_docs : 0,
                                sync_to_my_docs : 0
                            }
                        }
                    }
                })
            }
        });
    }
    handleOptionCheckbox(e, name, optionName){
        if(!this.state.extensions.one_drive.status){
            return;
        }

        this.setState({
            extensions: {
                ...this.state.extensions, 
                [name]: {
                    ...this.state.extensions[name],
                    'options': {
                        ...this.state.extensions[name]['options'],
                        [optionName] : e.target.checked ? 1 : 0
                    }
                }
            }
        });
    }
    validateForm(){
        let validation = {
            isValid: true
        };

        return validation.isValid;
    }    
    saveButtonDisabled(){
        const { user, extensions } = this.state;

        if(
            (user.extensions.ms_office.status !== extensions.ms_office.status) ||
            (user.extensions.google_workspace.status !== extensions.google_workspace.status) ||
            (user.extensions.docusign.status !== extensions.docusign.status) ||
            (user.extensions.autodesk.status !== extensions.autodesk.status)
        ){
            return false;
        }

        return false;
    }
    render() {
        const { classes } = this.props;
        const { saving, extensions } = this.state;
        const { authorized } = this.store.getState();
        
        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    <div className={classes.sidebar}>
                        <AccountSidebar store={this.store} />
                    </div>
                    <div className={classes.content}>
                        <GridItem>
                            <div className={classes.settingsContainer}>
                                <h3 className={classes.title}>Extensions</h3>
                                <form onSubmit={this.handleSubmit} className={classes.formFields}>
                                    <div className={classes.alignLeft}>
                                        <FormControlLabel
                                            classes={{label: classes.label}}
                                            control={
                                                <Switch
                                                    checked={(extensions.ms_office.status ? true : false)}
                                                    onChange={(e) => this.handleCheckbox(e, 'ms_office')}
                                                    value="ms_office"
                                                    classes={{
                                                        switchBase: classes.switchBase,
                                                        checked: classes.switchChecked,
                                                        thumb: classes.switchIcon,
                                                        track: classes.switchBar
                                                    }}
                                                />
                                            }
                                            label={"MS Office"}
                                        />
                                    </div>
                                    <div className={classes.alignLeft}>
                                        <FormControlLabel
                                            classes={{label: classes.label}}
                                            control={
                                                <Switch
                                                    checked={(extensions.google_workspace.status ? true : false)}
                                                    onChange={(e) => this.handleCheckbox(e, 'google_workspace')}
                                                    value="google_workspace"
                                                    classes={{
                                                        switchBase: classes.switchBase,
                                                        checked: classes.switchChecked,
                                                        thumb: classes.switchIcon,
                                                        track: classes.switchBar
                                                    }}
                                                />
                                            }
                                            label={"Google Workspace"}
                                        />
                                    </div>
                                    <div className={classes.alignLeft}>
                                        <FormControlLabel
                                            classes={{label: classes.label}}
                                            control={
                                                <Switch
                                                    checked={(extensions.docusign.status ? true : false)}
                                                    onChange={(e) => this.handleCheckbox(e, 'docusign')}
                                                    value="docusign"
                                                    classes={{
                                                        switchBase: classes.switchBase,
                                                        checked: classes.switchChecked,
                                                        thumb: classes.switchIcon,
                                                        track: classes.switchBar
                                                    }}
                                                />
                                            }
                                            label={"Docusign"}
                                        />
                                    </div>
                                    <div className={classes.alignLeft}>
                                        <FormControlLabel
                                            classes={{label: classes.label}}
                                            control={
                                                <Switch
                                                    checked={(extensions.autodesk.status ? true : false)}
                                                    onChange={(e) => this.handleCheckbox(e, 'autodesk')}
                                                    value="autodesk"
                                                    classes={{
                                                        switchBase: classes.switchBase,
                                                        checked: classes.switchChecked,
                                                        thumb: classes.switchIcon,
                                                        track: classes.switchBar
                                                    }}
                                                />
                                            }
                                            label={"Autodesk"}
                                        />
                                    </div>
                                    <div className={classes.alignLeft}>
                                        <FormControlLabel
                                            classes={{label: classes.label}}
                                            control={
                                                <Switch
                                                    checked={(extensions.one_drive && extensions.one_drive.status ? true : false)}
                                                    onChange={(e) => this.handleCheckbox(e, 'one_drive')}
                                                    value="one_drive"
                                                    classes={{
                                                        switchBase: classes.switchBase,
                                                        checked: classes.switchChecked,
                                                        thumb: classes.switchIcon,
                                                        track: classes.switchBar
                                                    }}
                                                />
                                            }
                                            label={"One Drive"}
                                        />
                                    </div>
                                    <div className={classes.subOptions}>
                                        <div className={classes.alignLeft}>
                                            <FormControlLabel
                                                classes={{label: classes.label}}
                                                control={
                                                    <Checkbox                                                
                                                        tabIndex={-1}
                                                        onClick={(e) => this.handleOptionCheckbox(e, "one_drive", "transfer_to_my_docs")}
                                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{checked: classes.checked, root: classes.checkRoot}}
                                                        checked={(extensions.one_drive && extensions.one_drive.options && extensions.one_drive.options.transfer_to_my_docs ? true : false)}
                                                    />
                                                }
                                                label="Transfer to My Docs"
                                            />
                                        </div>
                                        <div className={classes.alignLeft}>
                                            <FormControlLabel
                                                classes={{label: classes.label}}
                                                control={
                                                    <Checkbox                                                
                                                        tabIndex={-1}
                                                        onClick={(e) => this.handleOptionCheckbox(e, "one_drive", "sync_to_my_docs")}
                                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{checked: classes.checked, root: classes.checkRoot}}
                                                        checked={(extensions.one_drive && extensions.one_drive.options && extensions.one_drive.options.sync_to_my_docs ? true : false)}
                                                    />
                                                }
                                                label="Sync to My Docs"
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.formFooter}>
                                        <Button color="custom" type="submit" disabled={this.saveButtonDisabled()}>
                                            {
                                                saving ?
                                                    <LoaderComponent color="white" align="center" saving={true} />
                                                :
                                                "Save"
                                            }
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </GridItem>
                    </div>
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

UserExtensions.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userSettingsPageStyle)(UserExtensions);

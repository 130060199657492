import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import aboutPageStyle from "../../assets/jss/aboutPageStyle.jsx";

const About = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;

        this.state = {
            
        };
    }  
    render() {
        const { classes } = this.props;
        return (
            <div>
                <div className={classes.panelContent+" "+classes.textLeft}>
                    <div className={classes.leagalContent}>
                        <h1>About Sendlinx</h1>
                        <p>Sendlinx was founded in January of 2020 bya group of likeminded IT professionals who recognizethe growing population of users whoneed to sendlarge filesthat exceed email attachment size restrictions. As our name suggests, our framework allows senders to upload large files or folders to a secure location, generate a shareable link, send that link to one or more recipient(s) via email, and allow the recipient(s) to download the files or folders being shared. The typical user experience in this market has not been as simple or secure as it should be, particularly for those who may use company devices for both business and personal emails while still trying tostay in line with existing organizational IT policies.By current estimates,close to 85% of the workforce in any given industry engages in personal use of company email, accounting for nearly 10% of all incoming and outgoing email traffic on company resources. As we move towards an increasingly immersive and integrated work/life dynamic, these numbers are likely to keep rising.</p>
                        <p>At Sendlinx, we aim to improve the user landscape by giving everyone what they want; individuals have the tools to customize their file sharing activity and organizations have the framework to manage device use and maintain security protocols. To address the needs and concerns of both sides with our design, we approached the issue from both directions:</p>
                        <p>From a user perspective, (for both work and personal) individuals require:</p>
                        <ul>
                            <li>The ability to share large files and folders with one or more recipient(s);</li>
                            <li>The option to Zip, Encrypt, and Password Protect files and folders being shared;</li>
                            <li>The ability to Set Expiry Dates foraccess to thefiles and folders being shared;</li>
                            <li>The ability Change Linksto redirect recipient(s) to different or updated files and folders;</li>
                            <li>The ability to Delete Files or Foldersto preventrecipient(s) from downloading files and folders shared by mistake;</li>
                            <li>Notification when recipient(s)downloadthe files and folders being shared;</li>
                            <li>The ability to use Different Accounts (work versus personal) to upload files and folders to protect user privacy and adhere to company IT policies.</li>
                        </ul>
                        <p>From an organizationalperspective, network administrators require:</p>
                        <ul>
                            <li>The ability to Centrally Manage company user accounts for both Sendlinx Outlook Add-in and Sendlinx.com(if using web-based service);</li>
                            <li>The ability to generate User Usage reports;</li>
                            <li>The option to offer Outlook user access to acompany FTPServer versusone or more Cloud Services for use with Sendlinx Outlook Add-in.</li>
                        </ul>
                        <p>From an executive perspective, stakeholders & owners require:</p>
                        <ul>
                            <li>Employees to have the tools to communicate faster and collaboratively.</li>
                            <li>Happy teams,means more productivity and increase employee retention.</li>
                            <li>Ensure Security of the company’s IP related data.</li>
                        </ul>
                        <p>To sum it up, Sendlinx is here to make Large file Transfers made Simple & Secure.</p>
                        <h3>Values, Mission and Vision</h3>
                        <h3>Our Values:</h3>
                        <ul>
                            <li>Integrity</li>
                            <li>Innovation</li>
                            <li>Teamwork</li>
                            <li>Simplicity</li>
                            <li>Commitment to Customers</li>
                        </ul>
                        <h3>Our Mission:</h3>
                        <p>To help organize the world's information and make it universally accessible and useful.</p>
                        <h3>Our Vision:</h3> 
                        <p>To be innovative leaders in sending big data as fast as possible between users.</p>
                    </div>
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

About.propTypes = {
    classes: PropTypes.object
};

export default withStyles(aboutPageStyle)(About);

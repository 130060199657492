import { dangerColor, grayColor, whiteColor, blackColor } from "../material-kit-pro-react.jsx";
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
import homePageStyle from "../material-kit-pro-react/views/homePageStyle";
const shareLinkModalStyle = theme => ({
    ...modalStyle(theme),
    ...homePageStyle(theme),
    textCenter: {
        textAlign: "center"
    },
    justifyContentCenter: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "center !important",
        alignSelf: "center !important"
    },
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "500px !important",
        marginTop: "20px !important"
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingBottom: "20px",
        paddingTop: "0px",
        paddingRight: "10px",
        paddingLeft: "10px",
        "& .sd_loader": {
            width: "300px !important",
            height: "300px !important",
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
            "& > div": {
                width: "250px !important",
                height: "250px !important",
                [theme.breakpoints.down("xs")]: {
                    width: "150px !important",
                    height: "150px !important",
                },
            },
            "& svg": {
                "& circle": {
                    strokeWidth: "1px"
                }
            }
        },
        "& h4": {
            textAlign: "center",
            marginTop: "0px",
            marginBottom: '15px',
        },
        "& .viewPasswordIcon": {
            cursor: "pointer",
            marginTop: "0px",
            position: "absolute",
            right: "5px"
        },
        "& $transferForm h3": {
            color: blackColor,
            textShadow: "none",
            marginBottom: "15px",
            textAlign: "center"
        },
        "& $homepageFormFields": {
            "& input": {
                border: "1px solid "+grayColor[6],
                "&[name=linkPassword]": {
                    paddingRight: "35px"
                }
            },
            "& .formElement p": {
                color: blackColor,
                textShadow: "none"
            }
        },
        "& $homeFileSendingForm": {
            backgroundColor: "transparent",
            border: "0px",
            "& button": {
                maxWidth: "110px",
                opacity: "1",
                color: whiteColor+" !important",
                backgroundColor: theme.customize.buttonsColor+" !important",
                textAlign: "center",
                "& svg": {
                    fill: whiteColor,
                    opacity: "1"
                }
            }
        },
        "& $fromEmail .MuiInput-root:before": {
            left: "5px"
        }
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        marginTop: theme.spacing(2),
        justifyContent: "space-between !important",
        MsFlexPack: "space-between !important",
        WebkitBoxPack: "space-between !important",
    },
    errorMessage: {
        color: dangerColor[0],
        marginBottom: "0px"
    },
    downloadLink: {
        wordBreak: "break-all",
        textAlign: "left",
        "& .share-icon-lists": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            listStyleType: "none",
            padding: '0px',
            marginBottom: theme.spacing(3),
            "& li": {
                width: "50px",
                height: "50px",
                borderRadius: '100%',
                "& a": {
                    cursor: "pointer"
                },
                "& img": {
                    maxWidth: '50px',
                    width: "100%",
                    display: 'block'
                },
                [theme.breakpoints.down("xs")]: {
                    width: "40px",
                    height: "40px", 
                }
            },
            "& .sendlinx-icon": {
                backgroundColor: "#888787",  
                "& img": {
                    padding: "5px"
                }
            },
        },
        "& .download-link-section": {
            display: 'flex',
            border: '1px solid '+grayColor[2],
            borderRadius: '30px',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '0px 1px',
            "& p": {
                fontSize: '11px',
                fontWeight: 'bold',
                margin: '0px',
                flex: '1',
            },
            "& button": {
                margin: "1px 0px",
                textTransform: 'none',
                padding: '0.3rem 1.0rem',
            },
            '& .icon': {                
                borderRight: "1px solid "+grayColor[17],
                paddingRight: '4px',
                display: 'flex',
                paddingLeft: '4px',
                marginRight: '5px',
                "& svg": {
                    fill: grayColor[17],
                    width: '20px',
                }
            }
        }
    },
    dNone: {
        display: 'none'
    },
    modalCloseButton: {
        "&, &:hover": {
            color: grayColor[0]
        },
        "&:hover": {
            opacity: "1"
        },
        cursor: "pointer",
        padding: "0.5rem",
        margin: "-1rem -1rem -1rem auto",
        backgroundColor: "transparent",
        border: "0",
        WebkitAppearance: "none",
        float: "right",
        fontSize: "1.5rem",
        fontWeight: "500",
        lineHeight: "1",
        textShadow: "0 1px 0 " + whiteColor,
        opacity: ".5",
    },
    modalClose: {
        width: "16px",
        height: "16px"
    },
    modalCloseButtonCustom: {
        top: "16px !important",
        right: "16px !important",
        position: "absolute !important",
        "& svg": {
            marginRight: "0px"
        }
    },
    fileSendingForm: {
        top: "0px",
        borderRadius: "0px",
        backgroundColor: "transparent",
        marginBottom: "0px",
        "& >div": {
            width: "100%",
            overflow: "hidden",
            clear: "both"
        },
        [theme.breakpoints.down("sm")]: {
            left: "0px",
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            paddingRight: "10px",
            paddingLeft: "10px",
        }
    },
    filesList: {
        listStyle: "none",
        margin: "0",
        padding: "0",
        color: blackColor,
        "& .file": {
            borderBottom: "1px solid #e8ebed",
            cursor: "default",
            margin: "0px",
            outline: "0",
            padding: "0.5em 0.625em",
            position: "relative",
            textAlign: "left",
            color: blackColor,
            overflow: "hidden"
        },
        "& .file-header": {
            position: "relative",
            paddingRight: "40px"
        },
        "& .file-title": {
            fontWeight: "400",
            display: "block",
            fontSize: "0.875em",
            lineHeight: "1.4285714286em",
            margin: "0",
            overflow: "hidden",
            padding: "0",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            textTransform: "initial",
            color: blackColor
        },
        "& .file-details": {
            fontWeight: "400",
            display: "block",
            fontSize: "0.75em",
            lineHeight: "1.1666666667em",
            margin: "0",
            color: blackColor
        },
        "& .file-detail":{
            "& svg": {
                width: "9px",
                height: "7px"
            },
            "&:after": {
                content: "'\\00B7'",
                display: "inline-block",
                padding: "0 0.375em",
                textDecoration: "none",
            },
            "& .folder-detail": {
                marginLeft: "0.25em"
            }
        },
        "& .file:hover": {
            "& .filelist-action-delete": { 
                display: "block"
            }
        },
        "& .downloadIcon": {
            position: "absolute",
            right: "0px",
            top: "0px",
            bottom: "0px",
            margin: "auto",
            "& .MuiButton-label": {
                width: "auto"
            },
            "& button": {
                margin: "0px",
                marginTop: "2px"
            },
            "& svg": {
                fill: theme.customize.color
            }
        }
    },
    tagsInput: {
        ...homePageStyle(theme).tagsInput,
        backgroundColor: "transparent",
        border: "1px solid "+theme.customize.borderColorModal,
        "& span": {
            color: blackColor,
            textTransform: "none",
            backgroundColor: "transparent !Important",
            borderRight: "1px solid "+theme.customize.borderColorModal,
            borderRadius: "0px",
            lineHeight: "normal",
            paddingTop: "0px",
            paddingBottom: "0px",
            "& a": {
                top: "1px"
            }
        }
    },
});
  
export default shareLinkModalStyle;
  
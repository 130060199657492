import {
    whiteColor,
    grayColor,
} from "../material-kit-pro-react.jsx";
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
import customCheckboxRadioSwitchStyle from "../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
const shareUserStatusModalStyle = theme => ({
    ...customCheckboxRadioSwitchStyle,
    ...modalStyle(theme),
    textCenter: {
        textAlign: "center"
    },
    justifyContentCenter: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "center !important",
        alignSelf: "center !important",
        marginTop: "0px"
    },
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "400px !important"
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingBottom: "0px",
        paddingTop: "0px",
        "& .sd_loader": {
            width: "300px !important",
            height: "300px !important",
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
            "& > div": {
                width: "250px !important",
                height: "250px !important",
                [theme.breakpoints.down("xs")]: {
                    width: "150px !important",
                    height: "150px !important",
                },
            },
            "& svg": {
                "& circle": {
                    strokeWidth: "1px"
                }
            }
        }
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        marginTop: theme.spacing(2),
        justifyContent: "space-between !important",
        MsFlexPack: "space-between !important",
        WebkitBoxPack: "space-between !important",
    },
    modalCloseButtonCustom: {
        position: "absolute",
        top: "0px",
        right: "0px",
        margin: "0px",
        "& svg": {
            marginRight: "0px"
        }
    },
    uncheckedIcon: {
        ...customCheckboxRadioSwitchStyle.uncheckedIcon,
        borderColor: grayColor[11],
    },
    checkedIcon: {
        ...customCheckboxRadioSwitchStyle.checkedIcon,
        borderColor: "#2171C6",
        background: "#2171C6",
        fill: whiteColor
    },
});
  
export default shareUserStatusModalStyle;
  
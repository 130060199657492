import headerLinksStyle from "./material-kit-pro-react/components/headerLinksStyle.jsx";
const sidebarStyle = theme => ({
    ...headerLinksStyle(theme),
    container: {
        width: "190px",
        borderRight: "1px solid "+theme.customize.borderColor,
        position: "fixed",
        [theme.breakpoints.down("sm")]: {
            position: "static",
            border: "none",
            width: "100%"
        },
        "& .MuiList-root": {
            marginTop: "0px",
            overflow: "hidden",
            marginLeft: "0px",
            paddingLeft: "15px",
            display: "block",
            "& .dropdown-submenu": {
                paddingLeft: "15px",
                color: theme.customize.borderColor,
            }
        }
    },
    listItem: {
        ...headerLinksStyle(theme).listItem,
        float: "none",
        width: "100%",
        border: "none !important",
        "& a": {
            color: theme.customize.sidebarMenuLinkColor,
            "& button": {
                fontWeight: theme.customize.sidebarMenuWeight,
            },
            "&:hover": {
                "& .MuiButton-label": {
                    textShadow: theme.customize.sidebarMenuLinkShadow
                }
            }
        },
        "& ul": {
            overflow: "auto",
            marginTop: "0px",
            marginLeft: "10px",
            "& a": {
                color: theme.customize.sidebarSubMenuLinkColor,
                "& button": {
                    fontWeight: "normal"
                }
            },
        },
        "&:not(:last-child)": {
            "&:after": {
                display: "none"
            }
        },
        "& button": {
            margin: "0px",
            padding: "6px 12px",
            textTransform: "none",
            overflow: "visible"
        }
    },
    toggleMenu: {
        textAlign: "left",
        "& svg": {
            color: theme.customize.color,
            fill: theme.customize.color
        }
    },
    collapse: {
        display: "none",
        "&.active": {
            display: "block"
        }
    },
    navLink: {
        "& a:hover": {
            textDecoration: "none"
        },
    },
    textMenu: {
        color: theme.customize.sidebarMenuLinkColor,
        fontWeight: theme.customize.sidebarMenuWeight,
        "&:hover": {
            color: theme.customize.sidebarMenuLinkColor,
            cursor: "auto"
        }
    },
    navLinkCustom: {
        "& svg": {
            marginLeft: "-22px",
            marginTop: "-2px",
            fill: theme.customize.sidebarMenuIconsColor,
            color: theme.customize.sidebarMenuIconsColor,
        },
        "& img": {
            width: "16px",
            marginRight: "5px",
            marginLeft: "-23px",
            "&.just-icon": {
                maxWidth: "80px",
                width: "100%",
                marginLeft: "0px",
            }
        },
        "&.just-icon": {
            paddingLeft: "0px",
            paddingRight: "0px",
            marginLeft: "-5px"
        }
    },
    navButtonLink: {
        color: theme.customize.sidebarMenuLinkColor+" !important",
        fontWeight: theme.customize.sidebarMenuWeight,
    }
});

export default sidebarStyle;

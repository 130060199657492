import {
    grayColor,
    whiteColor,
    infoColor
  } from "./material-kit-pro-react.jsx";

const helpPageStyle = theme => ({
    panelContent: {
        paddingLeft: "5em",
        paddingRight: "5em",
        paddingTop: "5.5em",
        [theme.breakpoints.down("md")]: {
            paddingLeft: "3em",
            paddingRight: "3em"
        },
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "2em",
            paddingRight: "2em",
        },
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "1em",
            paddingRight: "1em"
        }
    },
    heading: {
        marginBottom: "40px",
        [theme.breakpoints.down("xs")]: {
            "& >div": {
                textAlign: "center",
            }
        },
        "& h1": {
            fontSize: "2.5em",
            marginTop: "0px",
            marginBottom: "0px",
            fontWeight: "500",
            [theme.breakpoints.down("sm")]: {
                fontSize: "2em",
            },
        },
        "& a.status": {
            color: infoColor[0],
            "&::before": {
                borderRadius: "0.1875em",
                content: '""',
                display: "inline-block",
                height: "0.375em",
                width: "0.375em",
                backgroundColor: infoColor[0],
                verticalAlign: "middle",
                marginRight: "5px",
                marginTop: "-1px",
            }
        }
    },
    helpForm: {
        "& .textfield": {
            border: "1px solid "+grayColor[17],
            borderRadius: "5px",
            color: grayColor[0],
            height: "3.75em",
        },
        "& .textfield-field": {
            border: "0",
            borderRadius: "5px",
            height: "100%",
            padding: "0 1.25em",
            textOverflow: "ellipsis",
            width: "100%",
            overflow: "hidden",
            fontSize: "1em",
            backgroundColor: "transparent"
        },
        "& button": {
            background: infoColor[0],
            border: 0,
            borderRadius: "5px",
            color: whiteColor,
            fontSize: "0.875em",
            height: "4.2857142857em",
            padding: "0 1.2857142857em",
            width: "100%",
            cursor: "pointer",
            margin: "0px",
            "-webkit-transition": "border-color 0.2s cubic-bezier(0.77, 0, 0.175, 1),background-color 0.2s cubic-bezier(0.77, 0, 0.175, 1),color 0.2s cubic-bezier(0.77, 0, 0.175, 1)",
            transition: "border-color 0.2s cubic-bezier(0.77, 0, 0.175, 1),background-color 0.2s cubic-bezier(0.77, 0, 0.175, 1),color 0.2s cubic-bezier(0.77, 0, 0.175, 1)",
        }
    },
    textLeft: {
        textAlign: "left"
    },
    textRight: {
        textAlign: "right",
    },
    alignCenter: {
        alignSelf: "center"
    },
    marginZero: {
        margin: "0px"
    },
    noPaddingLeft: {
        paddingLeft: "0px"
    },
    frequentQuestions: {
        "& >div": {
            alignItems: "normal",
            marginBottom: "1.25em",
        },
        [theme.breakpoints.down("xs")]: {
            "& >div": {
                marginBottom: "0px",
                "& >div": {
                    marginBottom: "1.25em",
                },
            }
        },
        marginTop: "2.8125em",
        "& .question": {
            display: "block",
            border: "1px solid "+grayColor[17],
            borderRadius: "0.3125em",
            minHeight: "11.25em",
            height: "100%",
            padding: "1.25em",
            color: grayColor[1],
            "&:hover": {
                backgroundColor: grayColor[16],
                "& svg": {
                    opacity: "1"
                }                
            },
            "& h3": {
                fontSize: "1.375em",
                lineHeight: "1.2727272727em",
                marginBottom: "0.625em",
                marginTop: "0",
                maxWidth: "calc(100% - 20px)",
            },
            "& p": {
                fontSize: "0.8125em",
                lineHeight: "1.6153846154em",
                marginBottom: "0"
            },
            "& span": {
                fontSize: "0.8125em",
                textDecoration: "underline"
            },
            "& svg": {
                opacity: "0",
                position: "absolute",
                right: "1.25em",
                top: "1.25em",
                width: "12px",
                height: "10px"
            }
        }
    },
    moreAnswers: {
        "& a": {
            lineHeight: "1em",
            padding: "1.2857142857em",
            backgroundColor: infoColor[0],
            fontSize: "0.875em",
            textDecoration: "none",
            color: whiteColor,
            display: "inline-block",
            margin: "1.875em auto 3.125em",
            borderRadius: "5px"
        }
    },
    socialLinksContent: {
        backgroundColor: grayColor[16],
        minHeight: "1.125em",
        padding: "2.25em 0 2.625em",
        position: "relative",
        left: "-5em",
        width: "calc(100% + 10em)",
        [theme.breakpoints.down("md")]: {
            left: "-3em",
            width: "calc(100% + 6em)",
        },
        [theme.breakpoints.down("sm")]: {
            left: "-2em",
            width: "calc(100% + 4em)",
        },
        [theme.breakpoints.down("xs")]: {
            left: "-1em",
            width: "calc(100% + 2em)",
        },
        "& h4": {
            fontSize: "1.125em",
            marginBottom: "1.33em",
            marginTop: "1.33em",
            lineHeight: "1.3",
        },
        "& ul": {
            marginTop: "1em",
            marginBottom: "1em",
            padding: "0px",
            listStyleType: "none",
            "& li": {
                display: "inline-block",
                margin: "0 0.25em",
                "& a": {
                    backgroundColor: infoColor[0],
                    display: "block",
                    borderRadius: "100%",
                    padding: "10px",
                },
                "& svg": {
                    height: "1.3em",
                    width: "1.3em",
                    fontSize: "inherit",
                    color: whiteColor,
                    display: "block"
                }
            }
        }
    }
});

export default helpPageStyle;

import React from 'react';
import Config from "../../../Config";
import withStyles from "@material-ui/core/styles/withStyles";
import splasScreenStyle from "../../assets/jss/splashScreenStyle.jsx";

const SplashScreen = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
    }  
    render() {
        const cdn = Config.getCdnUrl();
        const { classes } = this.props;
        const logoUrl = cdn+"/app/icon_trans393x551.png";
        return (
            <div className={classes.overlay}>
                <img src={logoUrl}  alt="Loading" className={classes.loader}/>
            </div>
        )
    }
}

export default withStyles(splasScreenStyle)(SplashScreen);

import React from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import IconButton from "@material-ui/core/IconButton";

import CustomInput from "../CustomInput/CustomInput.jsx";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import PlusIcon from "@material-ui/icons/AddBox";
import AdvancedSearchModal from "./AdvancedSearchModal";

import searchInputStyle from "../../../assets/jss/searchInputStyle";

class SearchInput extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;
        const { searchFormData } = this.props;

        this.state = {
            searchFormData: searchFormData,
            advancedSearchModal: false
        };
    }
    componentWillReceiveProps(props){
        this.setState({
            searchFormData: props.searchFormData,
        });
    }
    handleChange(e, name){
        this.setState({
            searchFormData: {
                ...this.state.searchFormData,
                search: e.target.value
            }
        });
    }
    onAdvancedSearchModal(status){
        this.setState({
            advancedSearchModal: status,
        });
    }
    onClearSearch(){
        this.onAdvancedSearchModal(false);
        this.props.clearSearch();
    }
    onAdvancedSearchModalSuccess(searchData){
        this.onAdvancedSearchModal(false);
        this.props.onSuccess(searchData);
    }
    handleSubmit(){
        this.props.onSuccess(this.state.searchFormData);
    }
    onEnterSubmit(e){
        e.preventDefault();
        if(e.nativeEvent.keyCode === 13){
            this.props.onSuccess(this.state.searchFormData);
        }
    }
    render(){
        const { classes, inputProps } = this.props;
        const { searchFormData, advancedSearchModal } = this.state;
        return (
            <div className={classes.searchInput}>
                <CustomInput
                    id="input-search"
                    inputProps={{
                        onChange: (e) => this.handleChange(e,'search'),
                        onKeyUp: (e) => this.onEnterSubmit(e),
                        value: searchFormData.search,
                        name: "search",
                        type: "text",
                        endAdornment: (
                            <InputAdornment 
                                className={classes.inputAdornment+' advanceSearchIcon'} 
                                onClick={() => this.onAdvancedSearchModal(true) }
                                position='end'
                            >
                                <PlusIcon className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                        ),
                        ...inputProps
                    }}
                    color="custom"
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses+" search-input"
                    }}
                />
                <IconButton
                    color="inherit"
                    aria-label="search"
                    onClick={() => this.handleSubmit()}
                    className={classes.searchIcon}
                    title="Advanced Search"
                    disabled={searchFormData.search ? false : true}
                >
                    <SearchIcon />
                </IconButton>
                {
                    advancedSearchModal === true ?
                        <AdvancedSearchModal store={this.store} searchFormData={this.props.searchFormData} open={advancedSearchModal} onClose={() => this.onAdvancedSearchModal(false)} onSuccess={(searchData) => this.onAdvancedSearchModalSuccess(searchData)} clearSearch={() => this.onClearSearch()} history={this.history}  />
                    :
                        <></>
                }
            </div>
        )
    }
}

SearchInput.defaultProps = {
    align: "center",
    color: "primary",
    inputProps: {}
}
SearchInput.propTypes = {
    classes: PropTypes.object,
    inputProps: PropTypes.object
};

export default (withStyles(searchInputStyle)(SearchInput));
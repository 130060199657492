import React from "react";
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import Cookie from "../../../assets/js/utils/Cookie";
import { helper } from "../../../assets/js/utils/Element";
import withStyles from "@material-ui/core/styles/withStyles";
import moment from 'moment';
import PropTypes from "prop-types";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import LoaderComponent from "../../components/Loader";
import Button from "../CustomButtons/Button";
import ArrowBack from "@material-ui/icons/ArrowBackIos";
import { Helmet } from 'react-helmet';

import MaterialIcon from "@mdi/react";
import { mdiArrowExpand, mdiArrowCollapse, mdiDotsVertical } from '@mdi/js';
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import GridContainer from "../../components/Grid/GridContainer.jsx";
// import GridItem from "../../components/Grid/GridItem";
// import Link from "@material-ui/core/Link";
import officeFileModalStyle from "../../../assets/jss/user/officeFileModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" timeout={500} ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const OfficeFileModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;
        this.modelBodyRef = React.createRef();
        this.formRef = React.createRef();
        this.iframeRef = React.createRef();

        this.state = {
            action: this.props.action,
            file: this.props.file,
            sharedFileId: this.props.sharedFileId,
            appUrl: null,
            favIconUrl: null,
            fileData: null,
            loading: false,
            expanded: (window.innerWidth < 768),
            responsive: (window.innerWidth < 768)
        };

        this.resizeIframe.bind(this);
    }
    componentDidMount(){
        this.discoverLink();
        setTimeout(() => {
            this.resizeIframe();
        }, 100);
        window.addEventListener("resize", this.resizeIframe.bind(this));
    }
    componentWillUnmount(){
        window.removeEventListener("resize", this.resizeIframe);
    }
    resizeIframe(){
        try{
            const { appUrl } = this.state;
            if(this.modelBodyRef && this.modelBodyRef.current !== null){
                this.modelBodyRef.current.style.width = window.innerWidth+"px";
                this.modelBodyRef.current.style.height = window.innerHeight+"px";
            }
            if(window.innerWidth < 768){
                this.setState({
                    expanded: true,
                    responsive: true
                })
            }
            if(appUrl === null){
                return;
            }
            // this.iframeRef.current.style.width = window.innerWidth+"px";
            // this.iframeRef.current.style.height = window.innerHeight+"px";
        }catch(e){}
    }
    onClose(){
        window.removeEventListener("resize", this.resizeIframe);
        this.props.onClose();
    }
    discoverLink(){
        const { action, file, sharedFileId } = this.state;
        const requestData = {
            access_token: Cookie.read("oauth_token")+":"+Cookie.read("oauth_secret"),
            action: action
        };
        if(sharedFileId !== null){
            requestData['sharedFileId'] = sharedFileId;
        }
        const source = axios.CancelToken.source();
        Api.discoverFileLink(file.id, requestData, source).then((data) => {
            this.setState({
                appUrl: data.appUrl,
                favIconUrl: data.favIconUrl,
                fileData: data,
                loading: true,
            }, () => {
                this.resizeIframe();
                this.submitAppForm();
            });
        }).catch(err => {
            this.setState({
                loading: false,
                showError: true, 
                errorMessage: err.message
            });
        });
        this.setState({
            loading: true,
            cancelToken: source,
        });
    }
    submitAppForm(){
        this.formRef.current.submit();
    }
    onExpand(){
        this.setState({
            expanded: !this.state.expanded
        })
    }
    getLastUpdatedDate(){
        const { fileData } = this.state;
        if(fileData === null || fileData.updated_at.length <= 0){
            return null;
        }
        return moment(fileData.updated_at).format("MM/DD/YYYY h:mm A");
    }
    onHideFileDetails(){
        const { responsive } = this.state;
        if(!responsive){
            return;
        }
        this.onExpand();
    }
    render() {
        const { classes } = this.props;
        const { appUrl, expanded, fileData, responsive, loading, showError, errorMessage, favIconUrl } = this.state;
        let expiresAt = Cookie.read("expires_at");
        if(!expiresAt || expiresAt === "null"){
            expiresAt = 0;
        }
        return (
                <Dialog
                    classes={{
                        root: classes.modalRoot,
                        paper: classes.modal
                    }}
                    open={this.props.open}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="officeFileModal-slide-title"
                    aria-describedby="officeFileModal-slide-description"
                    onClose={() => this.onClose()}
                >
                    <DialogContent
                        id="officeFileModal-slide-description"
                        className={classes.modalBody}
                        ref={this.modelBodyRef}
                    >
                        {
                            appUrl !== null ?
                                <div className={classes.mainContainer+" "+(expanded? classes.expandedMainContainer : "")}>
                                    <div className={classes.middleContainer}>
                                        <div className={classes.topContainer}>
                                            <Button color="custom" onClick={() => this.onClose()} className={classes.backButton} justIcon>
                                                <ArrowBack />
                                            </Button>
                                            <div className={classes.basicFileDetails}>
                                                { 
                                                    helper.getFileIcon(fileData, classes)
                                                }
                                                {fileData.name}
                                            </div>
                                            <Button color="custom" onClick={() => this.onExpand()} className={classes.expandButton} justIcon>
                                                {
                                                    responsive ?
                                                        <MaterialIcon path={mdiDotsVertical} className="MuiSvgIcon-root" />
                                                    :
                                                        expanded ?
                                                            <MaterialIcon path={mdiArrowCollapse} className="MuiSvgIcon-root" />
                                                        :
                                                        <MaterialIcon path={mdiArrowExpand} className="MuiSvgIcon-root" />
                                                }
                                            </Button>
                                        </div>
                                        <form className={classes.officeForm} ref={this.formRef} id="office_form" name="office_form" target="office_frame" action={appUrl} method="post">
                                            <input name="access_token" value={Cookie.read("oauth_token")+":"+Cookie.read("oauth_secret")} type="hidden" />
                                            <input name="access_token_ttl" value={expiresAt} type="hidden" />
                                            <input name="return_url" value={encodeURIComponent(window.location.href)} type="hidden" />
                                        </form>
                                        <div className={classes.officeIframeContainer} id="frameholder">
                                            <iframe ref={this.iframeRef} id="office_frame" name="office_frame" title="Office Frame" sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation allow-popups-to-escape-sandbox allow-downloads" allowFullScreen>

                                            </iframe>
                                        </div>
                                    </div>
                                    {
                                        responsive && expanded === false ?
                                            <div className={classes.overlayContainer} onClick={() => this.onHideFileDetails()}>
                                                
                                            </div>
                                        :
                                        null
                                    }
                                    
                                    <div className={classes.rightContainer}>
                                        <div className={classes.fileDetails}>
                                            <div className={classes.detailsTitle}>Details</div>
                                            <ul>
                                                <li>
                                                    <div>Name</div>
                                                    <div>{fileData.name}</div>
                                                </li>
                                                <li>
                                                    <div>Size</div>
                                                    <div>{fileData.size}</div>
                                                </li>
                                                <li>
                                                    <div>Last Updated</div>
                                                    <div>{this.getLastUpdatedDate()}</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            :
                                loading ?
                                    <LoaderComponent color="custom" className={classes.loading} align="center" />
                                :
                                    null
                        }
                        {
                            showError ?
                                <p>{errorMessage}</p>
                            :
                                null
                        }
                        <Helmet
                            title={fileData ? fileData.name+" - Sendlinx" : process.env.REACT_APP_TITLE}
                        >
                            {
                                favIconUrl !== null ?
                                    <link rel="shortcut icon" href={favIconUrl} />
                                :
                                null
                            }
                        </Helmet>
                    </DialogContent>      
                </Dialog>
        );
    }
};

OfficeFileModal.defaultProps = {
    open: false,
    sharedFileId: null
};
OfficeFileModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
    file: PropTypes.object,
    action: PropTypes.string,
    sharedFileId: PropTypes.string
};
export default withStyles(officeFileModalStyle)(OfficeFileModal);

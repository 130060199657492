/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  grayColor,
  whiteColor,
  mlAuto,
  hexToRgb
} from "../../material-kit-pro-react.jsx";

import tooltip from "../../material-kit-pro-react/tooltipsStyle.jsx";

const headerLinksStyle = theme => ({
    list: {
        [theme.breakpoints.up("md")]: {
            WebkitBoxAlign: "center",
            MsFlexAlign: "center",
            alignItems: "center",
            WebkitBoxOrient: "horizontal",
            WebkitBoxDirection: "normal",
            MsFlexDirection: "row",
            flexDirection: "row"
        },
        [theme.breakpoints.down("sm")]: {
            display: "block",
            marginTop: "40px"
        },
        marginTop: "0px",
        display: "flex",
        paddingLeft: "0",
        marginBottom: "0",
        listStyle: "none",
        padding: "0"
    },
    listItem: {
        float: "left",
        color: "inherit",
        position: "relative",
        display: "block",
        width: "auto",
        margin: "0",
        padding: "0",
        borderRight: "2px solid"+theme.customize.borderColor,
        "&:last-child": {
            borderRight: "none"
        },
        [theme.breakpoints.down("sm")]: {
        "& ul": {
            maxHeight: "400px",
            overflow: "scroll"
        },
        width: "100%",
        border: "none",
        "&:not(:last-child)": {
            "&:after": {
                width: "calc(100% - 30px)",
                content: '""',
                display: "block",
                height: "1px",
                marginLeft: "15px",
                backgroundColor: grayColor[14]
            }
        }
        }
    },
    listItemText: {
        padding: "0 !important"
    },
    navLink: {
        color: "inherit",
        position: "relative",
        padding: "0.9375rem",
        fontWeight: "400",
        fontSize: "12px",
        textTransform: "uppercase",
        lineHeight: "20px",
        textDecoration: "none",
        margin: "0px",
        display: "inline-flex",
        alignItems: "center",
        "&:hover,&:focus": {
            color: "inherit"
        },
        "& .fab,& .far,& .fal,& .fas,& .material-icons": {
            position: "relative",
            top: "2px",
            marginTop: "-4px",
            marginRight: "4px",
            marginBottom: "0px",
            fontSize: "1.25rem"
        },
        [theme.breakpoints.down("sm")]: {
            width: "calc(100% - 30px)",
            marginLeft: "15px",
            marginBottom: "8px",
            marginTop: "8px",
            textAlign: "left",
            "& > span:first-child": {
                justifyContent: "flex-start"
            }
        },
        "& svg": {
            marginRight: "3px",
            width: "20px",
            height: "20px"
        }
    },
    navLinkJustIcon: {
        "& .fab,& .far,& .fal,& .fas,& .material-icons": {
            marginRight: "0px"
        },
        "& svg": {
            marginRight: "0px"
        }
    },
    navButton: {
        position: "relative",
        fontWeight: "400",
        fontSize: "12px",
        textTransform: "uppercase",
        lineHeight: "20px",
        textDecoration: "none",
        margin: "0px",
        display: "inline-flex",
        [theme.breakpoints.down("sm")]: {
        width: "calc(100% - 30px)",
        marginLeft: "15px",
        marginBottom: "5px",
        marginTop: "5px",
        textAlign: "left",
        "& > span:first-child": {
            justifyContent: "flex-start"
        }
        },
        "& $icons": {
            marginRight: "3px"
        }
    },
    notificationNavLink: {
        color: "inherit",
        padding: "0.9375rem",
        fontWeight: "400",
        fontSize: "12px",
        textTransform: "uppercase",
        lineHeight: "20px",
        textDecoration: "none",
        margin: "0px",
        display: "inline-flex"
    },
    registerNavLink: {
        position: "relative",
        fontWeight: "400",
        fontSize: "12px",
        textTransform: "uppercase",
        lineHeight: "20px",
        textDecoration: "none",
        margin: "0px",
        display: "inline-flex"
    },
    navLinkActive: {
        "&, &:hover, &:focus,&:active ": {
            color: "inherit",
            backgroundColor: "rgba(" + hexToRgb(whiteColor) + ", 0.1)"
        }
    },
    icons: {
        width: "20px",
        height: "20px",
        marginRight: "14px"
    },
    dropdownIcons: {
        width: "24px",
        height: "24px",
        marginRight: "14px",
        opacity: "0.5",
        marginTop: "-4px",
        top: "1px",
        verticalAlign: "middle",
        fontSize: "24px",
        position: "relative"
    },
    socialIcons: {
        position: "relative",
        fontSize: "1.25rem",
        maxWidth: "24px"
    },
    dropdownLink: {
        margin: "0px",
        fontWeight: "bold",
        fontSize: "0.875em",
        padding: "0 12px",
        lineHeight: "1rem",
        textTransform: "capitalize",
        color: theme.customize.linksColor,
        display: 'block',
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            justifyContent: "start",
            lineHeight: "2.375rem",
        },
        "&,&:hover,&:focus": {
            color: theme.customize.linksColor,
            textDecoration: 'none',
        }
    },
    ...tooltip,
    marginRight5: {
        marginRight: "5px"
    },
    collapse: {
        [theme.breakpoints.up("md")]: {
            display: "flex !important",
            MsFlexPreferredSize: "auto",
            flexBasis: "auto"
        },
        WebkitBoxFlex: "1",
        MsFlexPositive: "1",
        flexGrow: "1",
        WebkitBoxAlign: "center",
        MsFlexAlign: "center",
        alignItems: "center"
    },
    mlAuto,
    desktopMenu: {
        backgroundColor: "transparent",
        color: theme.customize.linksColor,
        border: "none",
        borderRadius: "5px",
    }
});

export default headerLinksStyle;

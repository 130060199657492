import React from 'react';
import { Helmet } from 'react-helmet';
import { Link as RouterLink } from "react-router-dom";
import { createBrowserHistory } from 'history';
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import Auth from "../../../assets/js/utils/Auth";
// import Config from "../../../../Config";
import Cookie from "../../../assets/js/utils/Cookie";
import { helper } from "../../../assets/js/utils/Element";
import PropTypes from "prop-types";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Sidebar from "../../components/User/Sidebar";
import Button from "../../components/CustomButtons/Button";
import ReactTable from "react-table";
import LoaderComponent from "../../components/Loader";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteFileModal from "../../components/User/DeleteFileModal";
import UploadFileModal from "../../components/User/UploadFileModal";
import ProcessingModal from "../../components/User/ProcessingModal";
import NewFolderModal from "../../components/User/NewFolderModal";
import CopyFilesModal from "../../components/User/CopyFilesModal";
import MoveFilesModal from "../../components/User/MoveFilesModal";
import RenameFileModal from "../../components/User/RenameFileModal";
import ShareModal from "../../components/User/ShareModal";
import NewLinkModal from "../../components/User/NewLinkModal";
import SearchInput from "../../components/User/SearchInput";
import FileVersionModal from "../../components/User/FileVersionModal";
import NewFileModal from "../../components/User/NewFileModal";
import ImagePreviewModal from "../../components/User/imageModal";
import ListIcon from '@material-ui/icons/FormatListBulleted';
import GridIcon from '@material-ui/icons/ViewComfy';
import MaterialIcon from "@mdi/react";
import { mdiArrowExpandUp, mdiArrowCollapseDown, mdiTrashCanOutline, mdiStar, mdiStarOutline, mdiLinkVariant } from '@mdi/js';
import newlinkicon from "../../../assets/img/New_Link.png";
import newlinkiconLight from "../../../assets/img/New_Link-light.png";
import relatedLinksIcon from "../../../assets/img/related_links.png";
import relatedLinksIconLight from "../../../assets/img/related_links-light.png";
import foldericon from "../../../assets/img/foldericon.png";
import sharedFolderIcon from "../../../assets/img/sharedFolderIcon.png";
import ChevronRight from "@material-ui/icons/ChevronRight";
import newFolderIcon from "../../../assets/img/new_folder.png";
import newFolderIconLight from "../../../assets/img/new_folder-light.png";
import newOfficeDoc from "../../../assets/img/new_office_doc.png";
import newOfficeDocLight from "../../../assets/img/new_office_doc-light.png";

import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import DragNDrop from "../../components/Drag/Drop";
import OfficeFileModal from "../../components/User/OfficeFileModal";
import officeIcon from "../../../assets/img/office.svg";
import PdfFileModal from "../../components/User/PdfFileModal";
import NoPreviewModal from "../../components/User/NoPreviewModal";
import Menu from '@material-ui/core/Menu';
import { MenuItem as MuiMenuItem } from '@material-ui/core';
import ConfirmationModal from "../../components/User/ConfirmationModal";
import DetailsSection from "../../components/User/DetailsSection";
import withStyles from "@material-ui/core/styles/withStyles";
import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";
import Config from '../../../../Config';

const history = createBrowserHistory();

const UserHome = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;
        let path = helper.getParam("dir");
        if(path === null || path === "null"){
            path = "/";
        }
        const backPath = this.getBackPath(path);
        this.editDocRef = React.createRef();
        this.editDocTokenRef = React.createRef();
        this.editDocSecretRef = React.createRef();

        this.state = {
            limit: 200,
            totalItemCount: 0,
            response: null,
            cancelToken: null,
            page: 1,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            checked: [],
            path: decodeURIComponent(path.trim()),
            backPath: backPath,
            deleteFileModal: false,
            uploadFileModal: false,
            favourites: [],
            shareFileModal: false,
            sharingFiles: false,
            downloadModal: false,
            downloadingFiles: false,
            downloadUrl: "",
            downloadFile: false,
            file: null,
            newFolderModal: false,
            creatingFolder: false,
            copyFilesModal: false,
            copyingFilesModal: false,
            copyingFilesError: null,
            moveFilesModal: false,
            movingFilesModal: false,
            renameFileModal: false,
            renamingFileModal: false,
            firstTimeLoginModal: false,
            files: [],
            errorMessage: null,
            advShareModal: false,
            sharedWith: null,
            currentFile: null,
            downloadLink: null,
            linkName: false,
            searchFormData: this.defaultSearchFormData(),
            newUser: null,
            shareFileId: '',
            sharedWithUsers: [],
            checkedAll: false,
            versionsModal: false,
            newLinkUploaded: false,
            officeFileModal: false,
            officeFileAction: "view",
            docMenuEl: null,
            newFileModal: false,
            newFileExtension: null,
            creatingFile: false,
            newFileError: false,
            newFileErrorMessage: null,
            imagePreviewModal: false,
            layoutType: 'list',
            pdfFileModal: false,
            detailsSection: false,
            saving: false,
            linkHash: null
        };

        this.loadingFolder = false;
        this.onShareModal = this.onShareModal.bind(this);
        this.onShareModalSuccess = this.onShareModalSuccess.bind(this);
        this.onAdvShareModal = this.onAdvShareModal.bind(this);
    }
    componentDidMount(){
        const { authorized, user } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
            return;
        }
        if(authorized){
            if(user.login === 0){
                localStorage.setItem("show_first_view_shared", (user.login === 0));
            }
            if(user.level_id === 5){
                let redirectUrl = "/user/shared";
                this.history.push(redirectUrl);
            }

            const location = this.history.location;
            let searchFormData = null;
            if(location.state){
                searchFormData = location.state;
            }
            if(searchFormData !== null){
                this.onSearchInputSuccess(searchFormData.searchFormData);
            }else{
                this.loadFiles();
            }
        }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    defaultSearchFormData(){
        return {
            search: '',
            fileName: '',
            searchIn: '',
            attachmentContains: '',
            from: '',
            to: '',
            subject: '',
            destination: '',
            startDate: '',
            endDate: '',
            expiryStartDate: '',
            expiryEndDate: '',
        }
    }
    clearSearch(){
        this.setState({
            searchFormData: this.defaultSearchFormData()
        }, () => {
            this.loadFiles()
        });
    }
    onSearchInputSuccess(searchData){
        const location = this.history.location;
        const pathname = location.pathname;

        if(pathname === '/user/home' && searchData.searchIn === 'link-manager'){
            this.history.push({
                pathname: '/user/links',
                search: '',
                state: { searchFormData: searchData }
            });
            return;
        }

        let backPath = '';
        if(searchData.searchIn === 'web'){
            backPath = '/Email/Web';
        }else if(searchData.searchIn === 'docs'){
            backPath = '/My Docs';
        }else if(searchData.searchIn === 'email'){
            backPath = '/';
        }

        this.setState({
            searchFormData: {
                search: searchData.search,
                fileName: searchData.fileName,
                searchIn: searchData.searchIn,
                attachmentContains: searchData.attachmentContains,
                from: searchData.from,
                to: searchData.to,
                subject: searchData.subject,
                destination: searchData.destination,
                startDate: searchData.startDate,
                endDate: searchData.endDate,
                expiryStartDate:  searchData.expiryStartDate,
                expiryEndDate:  searchData.expiryEndDate,
            }
        }, () => {
            this.loadFiles(false, null, backPath);
        });
    }
    loadFiles(viewMore = false, newPath = null, backPath = ""){
        const source = axios.CancelToken.source();
        let { page, path, favourites, searchFormData, shareFileId } = this.state;

        if(viewMore){
            page += 1;
        }else{
            page = 1;
        }
        let requestData = {
            page: page,
            limit: 200
        };

        if(path === "/" && newPath !== null){
            path = "";
        }
        requestData['path'] = path;
        if(newPath !== null){
            path += "/"+newPath;
            requestData['path'] = path;
        }
        if(backPath !== ""){
            requestData['path'] = path = backPath;
        }

        // search for request data
        if(searchFormData.search){
            requestData['name'] = searchFormData.search;
        }
        if(searchFormData.startDate){
            requestData['startDate'] = searchFormData.startDate;
        }
        if(searchFormData.endDate){
            requestData['endDate'] = searchFormData.endDate;
        }

        let fileIdParam = "";
        let fileId = '';
        if(helper.getParam("fileId") !== '' && helper.getParam("fileId") !== 'null'){
            fileId = helper.getParam("fileId");
            fileIdParam = "&fileId="+fileId;
        }
        if(shareFileId !== ''){
            fileIdParam = "&fileId="+shareFileId;
            fileId = shareFileId;
        }
        if(path === "/My Docs" || path === "/"){
            fileIdParam = "";
            fileId = '';
        }
        this.setState({
            shareFileId: fileId
        })
        if(fileId !== ''){
            requestData['shareFileId'] = fileId;
        }

        backPath = this.getBackPath(path);
        history.push("/user/home?dir="+encodeURIComponent(path)+fileIdParam);
        Api.getFiles(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            data.response.map(file => {
                if(file.favourite){
                    favourites.push(file.id);
                }
                return null;
            });
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages,
                favourites: favourites,
                sharedWithUsers: ( data.sharedWith ? data.sharedWith : [])
            });
            this.loadingFolder = false;
        }).catch(err => {
            console.log(err);
        });
        let newState = {
            loading: (!viewMore), 
            loadingMore: viewMore,
            showError: false, 
            cancelToken: source,
            page: page,
            path: path,
            backPath: backPath,
            checked: viewMore ? this.state.checked : [],
            uploadFileModal: false,
            newFolderModal: false,
            creatingFolder: false,
            copyFilesModal: false,
            copyingFilesModal: false,
            moveFilesModal: false,
            movingFilesModal: false,
            renameFileModal: false,
            renamingFileModal: false,
            files: [],
            errorMessage: null,
            checkedAll: false
        };
        if(!viewMore){
            newState['totalPages'] = 0;
        }
        this.setState(newState);
        this.loadingFolder = true;
    }
    getBackPath(path){
        if(typeof(path) !== "string" || path.trim().length <= 0){
            return "";
        }
        if(path.length <= 1 || path.trim() === "/"){
            return "";
        }
        if(path.substr(0, 1) !== "/"){
            path += "/";
        }
        path = decodeURIComponent(path.trim()); //Decode path
        path = path.replace(/^\/+|\/+$/g, ''); //Remove trailing slash
        const pathArray = path.split("/");
        pathArray.pop();
        let backPath = pathArray.join("/");
        if(backPath.length <= 0){
            backPath = "/";
        }
        return backPath;
    }
    handleToggle(file, onContextMenu = false) {
        if(this.loadingFolder){
            return;
        }
        // if(file.default){
        //     return;
        // }
        
        let newChecked = [];
        if(onContextMenu || this.state.detailsSection){
            newChecked = [file.id];
        }else{
            const { checked } = this.state;
            const currentIndex = checked.indexOf(file.id);
            newChecked = [...checked];
        
            if (currentIndex === -1) {
                newChecked.push(file.id);
            } else {
                newChecked.splice(currentIndex, 1);
            }
        }

        const downloadFile = (newChecked.length === 1 && file.type === "file");
        this.setState({
            checked: newChecked,
            downloadFile: downloadFile,
            file: (downloadFile ? this.getCheckedFile(newChecked) : null),
            detailsSection: newChecked.length > 0 && this.state.detailsSection ? true : false
        });
    }
    onToggleAllFiles(e){
        if(this.loadingFolder){
            return;
        }

        const checkedFiles = [];
        if(e.target.checked){
            const { response } = this.state;

            response.map((file, key) => {
                checkedFiles.push(file.id);
                return null;
            });
        }
        
        this.setState({
            checkedAll: !this.state.checkedAll,
            checked: checkedFiles
        });
    }
    openFileModal(file){
        if(this.loadingFolder){
            return;
        }
            
        let newChecked = [file.id];
        const downloadFile = (newChecked.length === 1 && file.type === "file");
        this.setState({
            checked: newChecked,
            downloadFile: downloadFile,
            file: (downloadFile ? this.getCheckedFile(newChecked) : null)
        },() => {
            if(helper.hasThumbnail(file)){
                this.onImagePreviewModal(true);
            }else if(helper.isOfficeDocument(file)){
                this.onOfficeFileModal('view');
            }else if(helper.isPdfDocument(file)){
                this.onPdfFileModal(true);
            }else{
                this.onNoPreviewModal(true)
            }
        });
    }
    getTableData(){
        const { classes } = this.props;
        const { response, checked, backPath } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        if(backPath.length > 0){
            let fileArray = {
                id: 0,
                check: "",
                orignalName: "",
                name: (
                    <Link href="/user/home" onClick={(e) => {e.preventDefault(); this.loadFiles(false, null, backPath);}}>
                        <Button justIcon color="transparent" className={classes.fileIcon}>
                            <img src={foldericon} alt={"folder"} />
                        </Button>
                        {"..."}
                    </Link>
                ),
                orignalSize: "",
                size: "",
                date: "",
                owner: "",
                folderPath: "",
                recipients: "",
            };
            tableData.push(fileArray);
        }
        response.map(file => {
            if(file.type === "folder" && file.path === "/My Docs"){
                return null;
            }
            let fileArray = {
                id: file.id,
                default: file.default,
                type: file.type,
                downloadLink: file.downloadLink,
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={checked.includes(file.id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(file)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        disabled={(file.default === 1)}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                orignalName: file.name,
                name: (
                    <ContextMenuTrigger id="file_dropdown_menu" collect={() => {return file}}>
                        {this.getFileName(file)}
                    </ContextMenuTrigger>
                ),
                orignalSize: file.orignalSize,
                size: file.size,
                date: file.modified_date,
                owner: file.owner,
                lastModifiedBy: file.last_modified_by,
                recipients: (
                    <div className={classes.recipients}>{this.getRecipients(file)}</div>
                ),
                folderPath: file.folder_path,
                relatedLinks: this.getRelatedLink(file)
            };
            tableData.push(fileArray);
            return null;
        });
        return tableData;
    }
    getFileName(file){
        const modificationAllowed = this.isModificationAllowed();
        
        const { classes } = this.props;
        const { favourites } = this.state;
        let shareFileId = '';
        if(file.hasOwnProperty("sharedWith") && file.sharedWith.length > 0){
            shareFileId = file.id;
        }
        return (
            <div title={file.name} onDoubleClick={(e) => this.openFileModal(file)}>
                <Button justIcon color="transparent" className={classes.fileIcon} onClick={() => this.favourite(file)}>
                    {
                        favourites.includes(file.id) ?
                            <MaterialIcon path={mdiStar} className="MuiSvgIcon-root star_filled" />
                        :
                            <MaterialIcon path={mdiStarOutline} className="MuiSvgIcon-root star_outlined" />
                    }
                </Button>
                {
                    file.type === "folder" ?
                        <Link href={"/user/home"} onClick={(e) => this.loadFolder(e, file, shareFileId)}>
                            <Button justIcon color="transparent" className={classes.fileIcon}>
                                {
                                    file.hasOwnProperty("sharedWith") && file.sharedWith.length > 0 ?
                                        <img src={sharedFolderIcon} alt={"folder"} />
                                    :
                                        <img src={foldericon} alt={"folder"} />
                                }
                            </Button>
                            { file.name }
                        </Link>
                    :
                    <>    
                        { 
                            helper.getFileIcon(file, classes, modificationAllowed)
                        }
                        { file.name }
                    </>
                }
            </div>
        )
    }
    getFileNameGrid(file){
        const modificationAllowed = this.isModificationAllowed();
        
        const { classes } = this.props;
        const { checked } = this.state;

        let shareFileId = '';
        if(file.hasOwnProperty("sharedWith") && file.sharedWith.length > 0){
            shareFileId = file.id;
        }
        return (
            <div title={file.name} className={checked.includes(file.id) ? 'checked' : ''}  onClick={(e) => this.handleToggle(file)} onDoubleClick={(e) => this.openFileModal(file)}>
                {
                    file.type === "folder" ?
                        <>
                            <div className='thumbnail-icon'>
                                <Button justIcon color="transparent" className={classes.fileIcon}>
                                    {
                                        file.hasOwnProperty("sharedWith") && file.sharedWith.length > 0 ?
                                            <img src={sharedFolderIcon} alt={"folder"} />
                                        :
                                            <img src={foldericon} alt={"folder"} />
                                    }
                                </Button>
                            </div>
                            <Link href={"/user/home"} onClick={(e) => this.loadFolder(e, file, shareFileId)}>
                                <div className='filename'>{file.name}</div>
                            </Link>
                        </>
                    :
                        <>
                            <div className='thumbnail-icon'>
                                { 
                                    helper.getFileIcon(file, classes, modificationAllowed, true)
                                }
                            </div>
                            <div className='filename'>{file.name}</div>
                        </>
                }
            </div>
        )
    }
    getRecipients(file){
        if(file.transfer_type === "share"){
            return <div>Anyone (links only)</div>;
        }
        if(file.recipients.length <= 2){
            return <div>{file.recipients.join(",")}</div>;
        }
        const firstTwoRecipients = file.recipients.slice(0, 2);
        let recipientsList = [];
        file.recipients.map((email, key) => {
            recipientsList.push(
                <li key={key}>{email}</li>
            );
            return null;
        });
        return (
            <>
                <div>
                    {firstTwoRecipients.join(",")}
                </div>
                <ul>
                    {recipientsList}
                </ul>
            </>
        );
    }
    getRelatedLink(file){
        if(file.relatedLinks.length <= 0){
            return null;
        }
        const url = "/user/links?page=1&links="+file.relatedLinks.join(",");
        return (
            <RouterLink to={url}>
                <MaterialIcon path={mdiLinkVariant} className="MuiSvgIcon-root" />
            </RouterLink>
        );
    }
    loadFolder(e, parent, shareFileId = null){
        e.preventDefault();
        this.setState({
            path: parent.path,
            shareFileId: shareFileId
        }, () => {
            this.loadFiles(false);
        });
    }
    getTrProps(state, rowInfo){
        if (rowInfo && rowInfo.row) {
            // const { checked } = this.state;
            return {
                onClick: (e) => this.handleToggle(rowInfo.original),
                // style: {
                //     background: checked.includes(rowInfo.original.id) ? 'rgba(0, 0, 0, 0.7)' : 'transparent',
                // }
            }
        }else{
            return {}
        }
    }
    getTrGroupProps(state, rowInfo){
        const { backPath } = this.state;
        if(backPath.length <= 0){
            return {};
        }
        if (rowInfo && rowInfo.row) {
            return {
                className: (rowInfo.original.id === 0 ? 'frozen_row' : '')
            }
        }else{
            return {}
        }
    }
    deleteSelected(){
        const { checked } = this.state;

        if(checked.length <= 0){
            return;
        }
        this.setState({deleteFileModal: true});
    }
    onModalClose(deleteFileModal = true){
        let checked = this.state.checked;
        if(deleteFileModal === false){
            checked = [];
        }
        this.setState({
            deleteFileModal: deleteFileModal,
            checked: checked,
            deleteAll: false,
            checkedAll: false,
        });
    }
    onModalSuccess(){
        const { shareFileId } = this.state;
        const { checked, response } = this.state;
        const source = axios.CancelToken.source();
        const requestData = {
            files: checked,
        };
        if(shareFileId !== ''){
            requestData['sharedFileId'] = shareFileId;
        }
        Api.deleteFiles(requestData, source).then(data => {
            let newResponse = [];
            response.map(file => {
                if(!checked.includes(file.id)){
                    newResponse.push(file);
                }
                return null;
            });
            this.setState({
                response: newResponse,
                checked: [],
                deleteFileModal: false,
                deleteAll: false,
                checkedAll: false,
                saving: false,
                errorMessage: null
            });
        }).catch(err => {
            this.setState({
                saving: false,
                errorMessage: err.message
            });
        });
        this.setState({
            saving: true
        });
    }
    onUploadModal(uploadFileModal = true){
        if(!uploadFileModal){
            this.loadFiles(false);
            return;
        }
        this.setState({
            uploadFileModal: uploadFileModal,
        });
    }
    onUploadModalSuccess(){
        this.loadFiles(false);
    }
    favourite(file){
        const { favourites } = this.state;
        const currentIndex = favourites.indexOf(file.id);
        const newFavourites = [...favourites];
        if (currentIndex === -1) {
            newFavourites.push(file.id);
        } else {
            newFavourites.splice(currentIndex, 1);
        }

        const source = axios.CancelToken.source();
        const requestData = {
            file_id: file.id
        }
        Api.favouriteFile(requestData, source).then(data => {
            
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            favourites: newFavourites
        }, () => {
            this.handleToggle(file);
        });
    }
    onLinkName(status = false){
        const { checked } = this.state;
        if(checked.length <= 0){
            return;
        }

        this.setState({
            linkName: status,
            shareFileModal: status,
        });
    }
    onShareModal(status = false, linkTitle = null, linkPassword = null){
        const { checked, path } = this.state;
        if(checked.length <= 0){
            return;
        }
        
        const source = axios.CancelToken.source();
        const requestData = {
            files: checked,
            path: path
        };
        if(typeof(path) === "string" && path.indexOf("/My Docs") !== -1){
            requestData['uploadType'] = "my_docs";
        }
        if(linkTitle !== null){
            requestData['linkTitle'] = linkTitle;
        }
        if(linkPassword !== null){
            requestData['linkPassword'] = linkPassword;
        }
        
        Api.shareFiles(requestData, source).then(data => {
            if(data.status === 'uploaded'){
                this.setState({
                    sharingFiles: false,
                    newLinkUploaded: true
                })

                return;
            }
            
            let downloadLink = "/downloads/"+data.hash;
            this.setState({sharingFiles: false, downloadLink: downloadLink, linkHash: data.hash});
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({cancelToken: null, errorMessage: err.message, sharingFiles: false});
            }
        });
        this.setState({
            shareFileModal: status,
            sharingFiles: status,
            cancelToken: source,
            linkName: false
        });
    }
    onAdvShareModal(status = false){
        const { checked } = this.state;
        if(checked.length <= 0){
            return;
        }
        
        this.setState({
            advShareModal: status,
        });
        if(!status){
            this.loadFiles();
        }
    }
    onShareModalSuccess(){
        this.setState({
            shareFileModal: false,
            linkName: false,
            errorMessage: null,
            newLinkUploaded: false
        });
        this.loadFiles();

        // const { downloadUrl } = this.state;
        // if(downloadUrl.length > 0){
        //     window.location.href = downloadUrl;
        // }
        // this.history.push("/user/links");
    }
    onDownloadModal(status = false){
        const { checked, path, downloadFile, file } = this.state;
        if(checked.length <= 0){
            return;
        }

        if(downloadFile && checked.length === 1 && file){
            window.location.href = file.downloadLink;
            this.trackFileDownload();
            return;
        }

        let source = null;
        if(status){
            source = axios.CancelToken.source();
            const requestData = {
                files: checked,
                path: path
            };
            Api.downloadFiles(requestData, source).then(data => {
                this.setState({downloadingFiles: false, downloadUrl: data.downloadUrl});
            }).catch(err => {
                if(err && err.message !== "Request Cancelled"){
                    this.setState({cancelToken: null, errorMessage: err.message, downloadingFiles: false});
                }
            });
        }

        this.setState({
            downloadModal: status,
            downloadingFiles: status,
            cancelToken: source
        });
    }
    trackFileDownload(){
        const { shareFileId, checked } = this.state;
        if(checked.length <= 0){
            return;
        }
        const source = axios.CancelToken.source();
        const requestData = {
            id: checked,
            file_id: shareFileId,
        };
        Api.trackFileDownload(requestData, source).then(data => {
            //Silent
        }).catch(err => {
            //Silent
        });
    }
    onDownloadModalSuccess(){
        this.setState({
            downloadModal: false,
            errorMessage: null
        });
        const { downloadUrl } = this.state;
        if(downloadUrl.length > 0){
            window.location.href = downloadUrl;
            this.trackFileDownload();
        }
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    getBreadCrumbs(){
        let { path } = this.state;
        let breadcrumbs = [{
            value: "/",
            label: "Home"
        }];
        if(path === "/"){
            return breadcrumbs;
        }
        if(path.substr(0,1) === "/"){
            path = path.substr(1);
        }
        const pathArray = path.split("/");
        let newPath = "";
        pathArray.map(path => {
            newPath += "/"+path;
            breadcrumbs.push({
                value: newPath,
                label: path
            });
            return null;
        });
        return breadcrumbs;
    }
    onNewFolderModal(status = false){
        this.setState({
            newFolderModal: status,
        });
    }
    onNewFolderModalSuccess(folderName){
        const { shareFileId } = this.state;
        const source = axios.CancelToken.source();
        this.setState({
            creatingFolder: true,
            cancelToken: source,
        });

        const requestData = {
            folderName: folderName,
            path: this.state.path
        }
        if(shareFileId !== ''){
            requestData['sharedFileId'] = shareFileId;
            requestData['sharedSection'] = false;
        }
        Api.addNewFolder(requestData, source).then(data => {
            this.loadFiles();
        }).catch(err => {
            console.log(err);
        });
    }
    getColumns(){
        const { classes } = this.props;
        const { path, shareFileId, searchFormData } = this.state;
        
        let header = "";
        if(path.indexOf("/My Docs") !== -1){
            header = (
                <Checkbox
                    tabIndex={-1}
                    checked={this.state.checkedAll}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    onChange={(e) => this.onToggleAllFiles(e)}
                    classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                    }}
                />
            )
        }

        let columns = [
            {
                Header: header,
                accessor: "check",
                sortable: false,
                filterable: false,
                headerClassName: "hd_check",
                className: "hd_check td_check",
            },
            {
                Header: "Name",
                accessor: "orignalName",
                headerClassName: "hd_name",
                className: "hd_name td_name td_hide",
            },
            {
                Header: "Name",
                accessor: "name",
                headerClassName: "hd_name hd_hide",
                className: "hd_name td_name",
            },
            {
                Header: "Size",
                accessor: "orignalSize",
                headerClassName: "hd_size",
                className: "hd_size td_size td_hide",
            },
            {
                Header: "Size",
                accessor: "size",
                headerClassName: "hd_size hd_hide",
                className: "hd_size td_size",
            }
        ];
        if(path !== "/" && path !== "/Email" && path !== "/Email/Outlook" && path !== "/My Docs"){
            columns.push({
                Header: "Recipient(s)",
                accessor: "recipients",
                headerClassName: "td_recipients",
                className: "hd_recipients td_recipients",
            });
        }
        columns.push({
            Header: "Creator",
            accessor: "owner",
            headerClassName: "hd_owner",
            className: "hd_owner td_owner",
        });
        if(shareFileId !== ''){
            columns.push({
                Header: "Last Modified By",
                accessor: "lastModifiedBy",
                headerClassName: "hd_owner",
                className: "hd_owner td_owner",
            });
        }
        columns.push({
            Header: "Uploaded",
            accessor: "date",
            headerClassName: "hd_creation_date",
            className: "hd_creation_date td_creation_date",
            sortMethod: (a, b) => {
                var a1 = new Date(a).getTime();
                var b1 = new Date(b).getTime();
              if(a1<b1)
                return 1;
              else if(a1>b1)
                return -1;
              else
                return 0;
            }
        });
        if(searchFormData.search || searchFormData.startDate || searchFormData.endDate){
            columns.push({
                Header: "Folder Path",
                accessor: "folderPath",
                headerClassName: "hd_path",
                className: "hd_path td_path",
            });
        }
        columns.push({
            Header: "Related Links",
            accessor: "relatedLinks",
            headerClassName: "hd_related_links",
            className: "hd_related_links td_creation_date",
        });
        return columns;
    }
    onContextMenuItemClick(type, data){
        const checkedFile = this.getCheckedFile();
        switch(type){
            case 'share':
                this.onShareModal(true);
                break;
            case 'delete':
                this.deleteSelected();
                break;
            case 'download':
                this.onDownloadModal(true);
                break;
            case 'copy':
                this.onCopyModal(true);
                break;
            case 'move':
                this.onMoveModal(true);
                break;
            case 'rename':
                this.onRenameModal(true);
                break;
            case 'advShare':
            case 'permission':
                this.onAdvShareModal(true);
                break;
            case 'replace':
                this.onManageVersions(true);
                break;
            case 'view':
                this.onOfficeFileModal('view');
                break;
            case 'edit':
                if(checkedFile.extension === "wopitest" || checkedFile.extension === "wopitestx"){
                    this.onOfficeFileModal('edit');
                }else{
                    this.editOfficeDoc(checkedFile);
                }
                break;
            case 'imagePreview':
                this.onImagePreviewModal(true);
                break;
            case 'pdfPreview':
                this.onPdfFileModal(true);
                break;
            case 'detail':
                this.onDetailPreviewSection(true);
                break;
            default:
                break;
        }
    }
    onDetailPreviewSection(status = false){
        let state = {};
        state['detailsSection'] = status;
        if(status === false){
            state['checked'] = [];
            state['downloadFile'] = false;
            state['file'] = null;
        }
        this.setState(state);
    }
    onPdfFileModal(status = false){
        let state = {};
        state['pdfFileModal'] = status;
        if(status === false){
            state['checked'] = [];
            state['downloadFile'] = false;
            state['file'] = null;
        }
        this.setState(state)
    }
    onNoPreviewModal(status = false){
        let state = {};
        state['noPreviewModal'] = status;
        if(status === false){
            state['checked'] = [];
            state['downloadFile'] = false;
            state['file'] = null;
        }
        this.setState(state)
    }
    onImagePreviewModal(status = false){
        let state = {};
        state['imagePreviewModal'] = status;
        if(status === false){
            state['checked'] = [];
            state['downloadFile'] = false;
            state['file'] = null;
        }
        this.setState(state)
    }
    editOfficeDoc(file, newFile = false){
        let expiresAt = Cookie.read("expires_at");
        if(!expiresAt || expiresAt === "null"){
            expiresAt = 0;
        }
        let url = window.location.origin+"/document/edit/"+file.id;
        if(newFile){
            url = window.location.origin+"/document/editnew/"+file.id;
        }
        // this.editDocTokenRef.current.value = Cookie.read("oauth_token")+":"+Cookie.read("oauth_secret");
        this.editDocRef.current.setAttribute("action", url);
        this.editDocRef.current.submit();
    }
    onOfficeFileModal(action, status = true){
        let state = {};
        state['officeFileModal'] = status;
        state['officeFileAction'] = action;
        if(status === false){
            state['checked'] = [];
            state['downloadFile'] = false;
            state['file'] = null;
        }
        this.setState(state)
    }
    onManageVersions(versionsModal = false){
        this.setState({versionsModal: versionsModal}, () => {
            if(!versionsModal){
                this.loadFiles();
            }
        });
    }
    onContextMenuShow(e){
        const { checked } = this.state;
        if(checked.length > 1){
            return;
        }
        const file = e.detail.data;
        this.setState({
            //checked: [file.id],
            sharedWith: (file ? file.sharedWith : null),
            currentFile: (file ? file : null),
        }, () => {
            this.handleToggle(file, true);
        });
    }
    getCheckedFiles(){
        const { response, checked } = this.state;
        if(checked.length <= 0){
            return [];
        }
        let files = [];
        response.map((file) => {
            if(checked.indexOf(file.id) !== -1){
                files.push(file);
            }
            return null;
        });
        return files;
    }
    onCopyModal(modal = true){
        this.setState({
            copyFilesModal: modal,
            errorMessage: null
        });
    }
    onCopyModalSuccess(newPath){
        const { shareFileId } = this.state;
        const source = axios.CancelToken.source();
        this.setState({
            copyingFilesModal: true,
            cancelToken: source,
        });
        const { checked } = this.state;
        const requestData = {
            path: newPath,
            files: checked
        };
        if(shareFileId !== ''){
            requestData['sharedFileId'] = shareFileId;
            requestData['sharedSection'] = false;
        }
        Api.copyFiles(requestData, source).then(data => {
            this.loadFiles();
        }).catch(err => {
            if(err.error === true){
                this.setState({
                    errorMessage: err.message,
                    copyingFilesModal: false,
                    cancelToken: null
                });
            }
            console.log(err);
        });
    }
    onMoveModal(modal = true){
        this.setState({
            moveFilesModal: modal,
            errorMessage: null
        });
    }
    onMoveModalSuccess(newPath){
        const { shareFileId } = this.state;
        const source = axios.CancelToken.source();
        this.setState({
            movingFilesModal: true,
            cancelToken: source,
        });
        const { checked } = this.state;
        const requestData = {
            path: newPath,
            files: checked
        };
        if(shareFileId !== ''){
            requestData['sharedFileId'] = shareFileId;
            requestData['sharedSection'] = false;
        }
        Api.moveFiles(requestData, source).then(data => {
            this.loadFiles();
        }).catch(err => {
            if(err.error === true){
                this.setState({
                    errorMessage: err.message,
                    movingFilesModal: false,
                    cancelToken: null
                });
            }
            console.log(err);
        });
    }
    onRenameModal(modal = true){
        this.setState({
            renameFileModal: modal,
            errorMessage: null
        });
    }
    onRenameModalSuccess(newFileName){
        const { shareFileId } = this.state;
        const checkedFile = this.getCheckedFile();
        if(checkedFile === null){
            return;
        }
        const source = axios.CancelToken.source();
        this.setState({
            renamingFileModal: true,
            cancelToken: source,
        });
        const requestData = {
            name: newFileName,
        };
        if(shareFileId !== ''){
            requestData['sharedFileId'] = shareFileId;
        }
        Api.renameFile(checkedFile.id, requestData, source).then(data => {
            this.loadFiles();
        }).catch(err => {
            if(err && err.duplicate === true){
                this.setState({cancelToken: null, errorMessage: err.message, renamingFileModal: false});
            }
            console.log(err);
        });
    }
    getCheckedFile(checked = null){
        const { response } = this.state;
        if(checked === null){
            checked = this.state.checked;
        }
        if(checked.length <= 0){
            return null;
        }
        let currentFile = null;
        response.map((file) => {
            if(currentFile !== null){
                return null;
            }
            if(checked.indexOf(file.id) !== -1){
                currentFile = file;
            }
            return null;
        });
        return currentFile;
    }
    onDrop(filesArray){
        this.setState({
            files: filesArray,
            uploadFileModal: true
        });
    }
    isModificationAllowed(){
        const { path } = this.state;
        
        if(path.indexOf("/My Docs") !== -1){
            return true;
        }
        if(path === "/" || path === "/Email" || path.indexOf("/Email/Web") === 0 || path.indexOf("/Email/Outlook") === 0 || path.indexOf("/My Docs") === 0 || path.indexOf("/Mobile") === 0){
            return false;
        }
        return true;
    }
    onRelatedLinks(){
        const { checked } = this.state;
        if(checked.length <= 0){
            return;
        }

        const url = "/user/links?page=1&files="+checked.join(",");
        this.history.push(url);
    }
    onNewDocMenuSuccess(extension){
        this.setState({
            docMenuEl: null,
            newFileModal: true,
            newFileExtension: extension
        });
    }
    onNewFileModal(status = false){
        this.setState({
            docMenuEl: null,
            newFileModal: status,
            newFileExtension: null
        });
    }
    onNewFileModalSuccess(fileName){
        const { shareFileId } = this.state;
        const { newFileExtension } = this.state;
        const source = axios.CancelToken.source();
        this.setState({
            creatingFile: true,
            cancelToken: source,
        });

        const requestData = {
            fileName: (fileName+"."+newFileExtension),
            path: this.state.path
        }
        if(shareFileId !== ''){
            requestData['sharedFileId'] = shareFileId;
            requestData['sharedSection'] = false;
        }
        Api.addNewFile(requestData, source).then(data => {
            this.editOfficeDoc(data, true);
            this.setState({
                docMenuEl: null,
                newFileModal: false,
                newFileExtension: null,
                creatingFile: false,
                newFileError: false,
                newFileErrorMessage: null
            }, () => {
                this.loadFiles();
            });
        }).catch(err => {
            console.log(err);
        });
    }
    renderNewDocMenu(){
        const { docMenuEl } = this.state;
        const { classes } = this.props;
        return (
            <Menu
                id="new-doc-menu"
                anchorEl={docMenuEl}
                keepMounted
                open={Boolean(docMenuEl)}
                onClose={() => this.onNewFileModal(false)}
            >
                <MuiMenuItem onClick={() => this.onNewDocMenuSuccess("docx")}>
                    {helper.getFileIcon({extension: "docx"}, classes)}
                    Word Document
                </MuiMenuItem>
                <MuiMenuItem onClick={() => this.onNewDocMenuSuccess("xlsx")}>
                    {helper.getFileIcon({extension: "xlsx"}, classes)}
                    Excel Workbook
                </MuiMenuItem>
                <MuiMenuItem onClick={() => this.onNewDocMenuSuccess("pptx")}>
                    {helper.getFileIcon({extension: "pptx"}, classes)}
                    Powerpoint Presentation
                </MuiMenuItem>
            </Menu>
        )
    }
    onNewOfficeDoc(event){
        this.setState({
            docMenuEl: event.currentTarget
        });
    }
    getGridTableData(){
        const { classes } = this.props;
        const { response } = this.state;

        if(response === null){
            return [];
        }
        let tableData = [];
        
        response.map((file, key) => {
            let fileData =  (
                <div className={classes.gridDataItem} key={key}>
                    <ContextMenuTrigger id="file_dropdown_menu" collect={() => {return file}}>
                        {this.getFileNameGrid(file)}
                    </ContextMenuTrigger>
                </div>
            );

            tableData.push(fileData);
            return null;
        });

        return tableData;
    }
    onChangeLayout(type){
        this.setState({
            layoutType: type
        })
    }
    getHeading(){
        const { path } = this.state;
        const heading = "Email Transfers";
        if(path === "/"){
            return heading;
        }
        if(path.indexOf("/My Docs") !== -1){
            return "My Docs";
        }
        if(path.indexOf("/Email/Web") !== -1){
            return "Web Transfers";
        }
        if(path.indexOf("/Email/Outlook") !== -1){
            return "Outlook Transfers";
        }
        return heading;
    }
    getFilesContent(files){
        const { classes } = this.props;
        const { loading, loadingMore, page, totalPages, path, searchFormData, response, totalItemCount, backPath, 
            layoutType, docMenuEl, checked } = this.state; 
        const breadcrumbs = this.getBreadCrumbs();
        const tableData = this.getTableData();
        const modificationAllowed = this.isModificationAllowed();
        const { user } = this.store.getState();

        let content = (
            <div className={classes.content}>
                <div className={classes.controls}>
                    <GridItem>
                        <GridContainer className={classes.alignItems}>
                            <GridItem xs={12} sm={6} md={8}>
                                <h4>{this.getHeading()}</h4>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4}>                                
                                <SearchInput searchFormData={searchFormData} onSuccess={(searchData) => this.onSearchInputSuccess(searchData)} clearSearch={() => this.clearSearch()} history={this.history} store={this.store} />
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <div className={classes.controlButtons+" "+(user.theme === 'standard' ? '': 'light-theme-buttons')}>
                        {
                            modificationAllowed ?
                            <>
                                <Button color="transparent" onClick={() => this.onUploadModal(true)} title="Upload">
                                    <MaterialIcon path={mdiArrowExpandUp} className="MuiSvgIcon-root" />
                                    Upload
                                </Button>
                                <span className={classes.separator}></span>
                                <Button color="transparent" onClick={() => this.onNewFolderModal(true)} title="New Folder">
                                    <img src={user.theme === 'standard' ? newFolderIcon : newFolderIconLight} alt={"New Folder"} /> New Folder
                                </Button>
                                <span className={classes.separator}></span>
                                {
                                    user.extensions.ms_office ?
                                        <>
                                            <Button color="transparent" aria-controls="new-doc-menu" aria-haspopup="true" onClick={(event) => this.onNewOfficeDoc(event)} title="New Office Doc">
                                                <img src={user.theme === 'standard' ? newOfficeDoc : newOfficeDocLight} alt={"New Office Doc"} /> New Office Doc
                                            </Button>
                                            <span className={classes.separator}></span>
                                            {
                                                Boolean(docMenuEl) && user.account_type === 5 ?
                                                    this.renderConfirmationModal()
                                                :
                                                    this.renderNewDocMenu()
                                            }
                                        </>
                                    :
                                    <></>
                                }
                            </>
                            :
                            <></>
                        }
                        {
                            Config.isLimitedAccess() === false ?
                                <>
                                    {
                                        path.trim() !== "/" && (path.indexOf("/Email") === -1 || path.substr(1).split("/").length >= 3) ?
                                            <>
                                                <Button color="transparent" disabled={checked.length <= 0 ? true : false} onClick={() => this.onLinkName(true)} title="New Link">
                                                    <img src={user.theme === 'standard' ? newlinkicon : newlinkiconLight} alt={"link"} /> New Link
                                                </Button>
                                                <span className={classes.separator}></span>
                                            </>
                                        :
                                        null
                                    }
                                    <Button color="transparent" disabled={checked.length <= 0 ? true : false} onClick={() => this.onRelatedLinks()} title="Related Links">
                                        <img src={user.theme === 'standard' ? relatedLinksIcon : relatedLinksIconLight} alt={"link"} /> Related Links
                                    </Button>
                                    <span className={classes.separator}></span>
                                </>
                            :
                            null
                        }
                        <Button color="transparent" disabled={checked.length <= 0 ? true : false} onClick={() => this.deleteSelected()} title="Delete">
                            <MaterialIcon path={mdiTrashCanOutline} className="MuiSvgIcon-root" />
                            Delete
                        </Button>
                        <span className={classes.separator}></span>
                        <Button color="transparent" disabled={checked.length <= 0 ? true : false} onClick={() => this.onDownloadModal(true)} title="Download">
                            <MaterialIcon path={mdiArrowCollapseDown} className="MuiSvgIcon-root" />
                            Download
                        </Button>
                    </div>
                </div>
                <div className={classes.breadcrumbs}>
                    <div>
                        <img src={foldericon} alt={"folder"} />
                        {
                            breadcrumbs.map((breadCrumb, key) => {
                                return (
                                    <div key={key}>
                                        {
                                            key !== 0 ?
                                                <span><ChevronRight /></span>
                                            :
                                            <></>
                                        }
                                        <Link href="/user/home" onClick={(e) => {e.preventDefault(); this.loadFiles(false, null, breadCrumb.value);}}>
                                            {breadCrumb.label}
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        !loading && response !== null ?
                            <div className={classes.itemCount}>
                                {
                                    totalItemCount === 1 ?
                                        totalItemCount+" item"
                                    :
                                        totalItemCount+" items"
                                }
                            </div>
                        :
                        <></>
                    }
                </div>
                {
                    loading === false && modificationAllowed ?
                        <div className={classes.filesLayoutType}>
                            <Button color="transparent" justIcon className={classes.fileIcon} onClick={() => this.onChangeLayout('list')}>
                                <ListIcon />
                            </Button>
                            <Button color="transparent" justIcon className={classes.fileIcon} onClick={() => this.onChangeLayout('grid')}>
                                <GridIcon />
                            </Button>
                        </div>
                    :
                        <></>
                }
                <div className={classes.filesContainer}>
                    <div className={classes.files}>
                        {
                            loading ?
                                <LoaderComponent color="custom" align="center" />
                            :
                            null
                        }
                        {
                            layoutType === 'grid' ?
                                <>
                                    {
                                        loading === false && modificationAllowed ? 
                                            <div className={classes.gridTableData}>
                                                { this.getGridTableData() }
                                            </div>
                                        :
                                            <></>
                                    }
                                </>
                            :
                                <ReactTable
                                    columns={this.getColumns()}
                                    data={tableData}
                                    pageSizeOptions={[500]}
                                    defaultPageSize={50000000000}
                                    showPaginationTop={false}
                                    minRows={0}
                                    showPaginationBottom={false}
                                    className={"-striped -highlight "+ (loading ? classes.loading : "")+" "+classes.filesTable+" "+(backPath.length > 0 ? classes.stickyRow : "")}
                                    getTrProps={(state, rowInfo) => this.getTrProps(state, rowInfo)}
                                    getTrGroupProps={(state, rowInfo) => this.getTrGroupProps(state, rowInfo)}
                                    getNoDataProps={() => this.getNoDataProps()}
                                />
                        }
                        <div className={classes.loadMore}>
                            {
                                tableData.length > 0 && loadingMore === false && page < totalPages ?
                                    <Button color="custom" id="contentViewMore" round onClick={(e) => this.loadFiles(true)}>
                                        <RefreshIcon className={classes.icons} />View More
                                    </Button>
                                    
                                :
                                    loadingMore === true ?
                                        <LoaderComponent color="custom" align="center" />
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
        
        if(path.indexOf("/My Docs") === 0 || path.indexOf("My Docs") === 0){
            return (
                <DragNDrop onDrop={(files) => this.onDrop(files)}>
                    { content }
                </DragNDrop>
            );
        }else{
            return (
                content 
            );
        }
    }
    canEditDocument(){
        const modificationAllowed = this.isModificationAllowed();
        if(!modificationAllowed){
            return false;
        }
        const checkedFile = this.getCheckedFile();
        return helper.canEditDocument(checkedFile);
    }
    onUpgradeModalClose(){
        this.setState({
            uploadFileModal: false,
            newFolderModal: false,
            creatingFolder: false,
            copyFilesModal: false,
            copyingFilesModal: false,
            moveFilesModal: false,
            movingFilesModal: false,
            renameFileModal: false,
            renamingFileModal: false,
            docMenuEl: null
        });
    }
    onUpgradeModalSuccess(){
        this.history.push("/user/plans");
    }
    renderConfirmationModal(open = true){
        return (
            <ConfirmationModal 
                open={open} 
                onClose={() => this.onUpgradeModalClose(false)} 
                onSuccess={() => this.onUpgradeModalSuccess()}
                confirmationMessage="Please upgrade to paid plan in order to use this feature."
                cancelButtonText={"Cancel"}
                successButtonText={"Upgrade Now"}
            />
        )
    }
    render() {
        const { classes } = this.props;
        const { deleteFileModal, uploadFileModal, path, shareFileModal,
            downloadModal, downloadingFiles, newFolderModal, creatingFolder, copyFilesModal, copyingFilesModal,
            moveFilesModal, movingFilesModal, renameFileModal, renamingFileModal, files, errorMessage, checked, sharingFiles,
            advShareModal, sharedWith, currentFile, downloadLink, linkName, sharedWithUsers, shareFileId,
            versionsModal, newLinkUploaded, officeFileModal, officeFileAction, newFileModal, newFileExtension, creatingFile,
            newFileError, newFileErrorMessage, imagePreviewModal, pdfFileModal, noPreviewModal, detailsSection, saving } = this.state;
        const modificationAllowed = this.isModificationAllowed();
        const { authorized, user } = this.store.getState();
        if(!authorized || user.level_id === 5){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        
        const checkedFiles = this.getCheckedFiles();
        const checkedFile = this.getCheckedFile();
        const canEdit = this.canEditDocument();
        
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    <div className={classes.sidebar}>
                        <Sidebar store={this.store} />
                    </div>
                    {
                        this.getFilesContent(files)
                    }
                    <DeleteFileModal
                        deleteNote={true}
                        confirm={true}
                        deletedFiles={checkedFiles}
                        open={deleteFileModal}
                        onClose={() => this.onModalClose(false)}
                        onSuccess={() => this.onModalSuccess()}
                        loading={saving}
                        errorMessage={errorMessage}
                    />
                    {
                        uploadFileModal && user.account_type === 5 ?
                            this.renderConfirmationModal()
                        :
                            uploadFileModal ?
                                <UploadFileModal 
                                    open={uploadFileModal} 
                                    store={this.store} 
                                    path={path} 
                                    files={files} 
                                    onClose={() => this.onUploadModal(false)} 
                                    onSuccess={() => this.onUploadModalSuccess()} 
                                    sharedWith={sharedWithUsers}
                                    sharedFileId={shareFileId} 
                                    groupNotification={( shareFileId ? "optional" : '')} 
                                />
                            :
                                <></>
                    }
                    {
                        advShareModal &&  path.indexOf("/My Docs") !== -1 ?
                            <ShareModal
                                open={advShareModal}
                                onClose={() => this.onAdvShareModal(false)}
                                onSuccess={() => this.onAdvShareModal(false)}
                                files={checkedFiles}
                                store={this.store} 
                                checked={checked}
                                sharedWith={sharedWith}
                                onPermissionsUpdate={this.onPermissionsUpdate}
                            />
                        :
                        <></>
                    }
                    {
                        shareFileModal ?
                            <NewLinkModal 
                                open={shareFileModal} 
                                saving={sharingFiles} 
                                onClose={() => this.onLinkName(false)} 
                                onSuccess={() => this.onShareModalSuccess()}
                                onLinkName={(name, linkPassword) => this.onShareModal(true, name, linkPassword)}
                                loadingMessage="Please wait while we prepare your download link. It may take few minutes depending on size and number of file(s)."
                                successMessage={ newLinkUploaded === true ? 'Your download link is processing. Please check "Status" in the Link Manager in a few moments. Once "Ready", you may copy the link, and share anywhere.' : 'Your link is now ready to be shared.' }
                                errorMessage={errorMessage}
                                downloadLink={downloadLink}
                                linkName={linkName}
                                store={this.store}
                                linkHash={this.state.linkHash}
                            />
                        :
                        <></>
                    }
                    {
                        downloadModal ?
                            <ProcessingModal 
                                open={downloadModal} 
                                saving={downloadingFiles} 
                                onClose={() => this.onDownloadModal(false)} 
                                onSuccess={() => this.onDownloadModalSuccess()}
                                loadingMessage="Please wait while we prepare your download link. It may take few minutes depending on size and number of file(s)."
                                successMessage="Your file(s) has been processed and your download link is ready."
                                errorMessage={errorMessage}
                                store={this.store} 
                            />
                        :
                        <></>
                    }
                    {
                        newFolderModal && user.account_type === 5 ?
                            this.renderConfirmationModal()
                        :
                        newFolderModal ?
                            <NewFolderModal
                                open={newFolderModal}
                                saving={creatingFolder}
                                store={this.store} path={path}
                                onClose={() => this.onNewFolderModal(false)}
                                onSuccess={(folderName) => this.onNewFolderModalSuccess(folderName)}
                                confirmationMessage="Create new folder"
                            />
                        :
                        <></>
                    }
                    {
                        copyFilesModal ?
                            <CopyFilesModal 
                                open={copyFilesModal} 
                                saving={copyingFilesModal} 
                                onClose={() => this.onCopyModal(false)} 
                                onSuccess={(newPath) => this.onCopyModalSuccess(newPath)}
                                path={path}
                                files={checkedFiles}
                                loadingMessage="Please wait while we copy your files. It may take few minutes depending on size of the files."
                                successMessage="Copy your files to new path."
                                errorMessage={errorMessage}
                            />
                        :
                        <></>
                    }
                    {
                        moveFilesModal ?
                            <MoveFilesModal 
                                open={moveFilesModal} 
                                saving={movingFilesModal} 
                                onClose={() => this.onMoveModal(false)} 
                                onSuccess={(newPath) => this.onMoveModalSuccess(newPath)}
                                path={path}
                                files={checkedFiles}
                                loadingMessage="Please wait while we move your files. It may take few minutes depending on size of the files."
                                successMessage="Move your files to new path."
                                errorMessage={errorMessage}
                            />
                        :
                        <></>
                    }
                    {
                        renameFileModal && checkedFile !== null ?
                            <RenameFileModal 
                                open={renameFileModal} 
                                saving={renamingFileModal} 
                                onClose={() => this.onRenameModal(false)} 
                                onSuccess={(newPath) => this.onRenameModalSuccess(newPath)}
                                file={checkedFile}
                                errorMessage={errorMessage}
                            />
                        :
                        <></>
                    }
                    {
                        versionsModal ?
                            <FileVersionModal 
                                store={this.store}
                                open={versionsModal} 
                                saving={renamingFileModal} 
                                onClose={() => this.onManageVersions(false)} 
                                file={checkedFile}
                                errorMessage={errorMessage}
                                sharedWith={sharedWithUsers}
                                sharedFileId={shareFileId} 
                                groupNotification={( shareFileId ? "optional" : '')} 
                            />
                        :
                        <></>

                    }
                    {
                        newFileModal && user.account_type === 5 ?
                            this.renderConfirmationModal()
                        :
                        newFileModal ?
                            <NewFileModal
                                open={newFileModal}
                                saving={creatingFile}
                                store={this.store} path={path}
                                extension={newFileExtension}
                                onClose={() => this.onNewFileModal(false)}
                                onSuccess={(fileName) => this.onNewFileModalSuccess(fileName)}
                                confirmationMessage="Create new file"
                                error={newFileError}
                                errorMessage={newFileErrorMessage}
                            />
                        :
                        <></>
                    }
                </div>
                {
                    officeFileModal && user.account_type === 5 ?
                        this.renderConfirmationModal()
                    :
                    officeFileModal ?
                        <OfficeFileModal 
                            store={this.store}
                            open={officeFileModal} 
                            action={officeFileAction}
                            file={checkedFile}
                            onClose={() => this.onOfficeFileModal(null, false)}
                        />
                    :
                    null
                }
                {
                    imagePreviewModal ?
                        <ImagePreviewModal
                            open={imagePreviewModal}
                            onClose={() => this.onImagePreviewModal(false)}
                            file={checkedFile}
                            onDownload={() => this.onDownloadModal(true)}
                        />
                    :
                    <></>
                }
                {
                    pdfFileModal ?
                        <PdfFileModal 
                            store={this.store}
                            open={pdfFileModal} 
                            file={checkedFile}
                            onClose={() => this.onPdfFileModal(false)}
                        />
                    :
                    null
                }
                {
                    noPreviewModal ?
                        <NoPreviewModal 
                            store={this.store}
                            open={noPreviewModal} 
                            onDownload={() => this.onDownloadModal(true)}
                            onClose={() => this.onNoPreviewModal(false)}
                        />
                    :
                    null
                }
                {
                    detailsSection && checkedFile ?
                        <DetailsSection
                            open={detailsSection}
                            onClose={() => this.onDetailPreviewSection(false)}
                            file={checkedFile}
                            onDownload={() => this.onDownloadModal(true)}
                            modificationAllowed={modificationAllowed}
                        />
                    :
                        <></>
                }
                {
                    path.indexOf("/My Docs") !== -1 ?
                        <ContextMenu id="file_dropdown_menu" onShow={(e) => this.onContextMenuShow(e)}>
                            {
                                modificationAllowed && helper.hasThumbnail(checkedFile) ?
                                    <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('imagePreview', data, collect)}>
                                        Preview
                                    </MenuItem>
                                :   
                                    <></>
                            }
                            {
                                modificationAllowed && helper.isPdfDocument(checkedFile) ?
                                    <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('pdfPreview', data, collect)}>
                                        Preview
                                    </MenuItem>
                                :   
                                    <></>
                            }
                            {
                                Config.isWopiAllowed() ?
                                    helper.isOfficeDocument(checkedFile) && user.extensions.ms_office ?
                                        <>
                                            <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('view', data, collect)}>
                                                <Button color="transparent" justIcon className={classes.fileIcon}>
                                                    <img src={officeIcon} alt={"folder"} />
                                                </Button>
                                                Preview
                                            </MenuItem>
                                            {
                                                canEdit ?
                                                    <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('edit', data, collect)}>
                                                        <Button color="transparent" justIcon className={classes.fileIcon}>
                                                            <img src={officeIcon} alt={"folder"} />
                                                        </Button>
                                                        Open in Microsoft Office for the web
                                                    </MenuItem>
                                                :
                                                    null
                                            }
                                        </>
                                    :
                                        null
                                :
                                    null
                            }
                            {
                                path.indexOf("/My Docs") !== -1 ?
                                    <>
                                        {
                                            sharedWith !== null && sharedWith.length > 0 ?
                                                <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('permission', data, collect)}>
                                                    Permissions
                                                </MenuItem>
                                            :
                                            currentFile !== null && currentFile.type === "folder" ?
                                                <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('advShare', data, collect)}>
                                                    Share
                                                </MenuItem>
                                            :
                                            <></>
                                        }
                                    </>
                                :
                                <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('share', data, collect)}>
                                    Share
                                </MenuItem>
                            }
                            <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('delete', data, collect)}>
                                Delete
                            </MenuItem>
                            <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('download', data, collect)}>
                                Download
                            </MenuItem>
                            <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('copy', data, collect)}>
                                Copy
                            </MenuItem>
                            <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('move', data, collect)}>
                                Move
                            </MenuItem>
                            <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('rename', data, collect)}>
                                Rename
                            </MenuItem>
                            <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('replace', data, collect)}>
                                Previous Version(s)
                            </MenuItem>
                            {
                                modificationAllowed ?
                                    <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('detail', data, collect)}>
                                        View Details
                                    </MenuItem>
                                :
                                    <></>
                            }
                        </ContextMenu>
                    :
                        <></>
                }
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                    {
                        officeFileModal === false ?
                            <link rel="shortcut icon" href="favicon.ico" />
                        :
                        null
                    }
                </Helmet>
                <form ref={this.editDocRef} target='_blank' method='GET' style={{display: 'none'}}>
                    {/* <input ref={this.editDocTokenRef} name="oauth-token" type="hidden" /> */}
                </form>
            </div>
        )
    }
}

UserHome.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userHomePageStyle)(UserHome);
import {
    grayColor
} from "../material-kit-pro-react.jsx";
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
  
const moveFilesModalStyle = theme => ({
    ...modalStyle(theme),
    textCenter: {
        textAlign: "center",
        marginBottom: "10px"
    },
    justifyContentCenter: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "center !important",
        alignSelf: "center !important"
    },
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "400px !important"
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingBottom: "0px",
        paddingTop: "0px",
        "& .sd_loader": {
            width: "300px !important",
            height: "300px !important",
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
            "& > div": {
                width: "250px !important",
                height: "250px !important",
                [theme.breakpoints.down("xs")]: {
                    width: "150px !important",
                    height: "150px !important",
                },
            },
            "& svg": {
                "& circle": {
                    strokeWidth: "1px"
                }
            }
        }
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        marginTop: theme.spacing(2),
        justifyContent: "space-between !important",
        MsFlexPack: "space-between !important",
        WebkitBoxPack: "space-between !important",
    },
    filesList: {
        listStyleType: "none",
        paddingLeft: "0px",
        "& li": {
            fontSize: "12px",
            fontWeight: "bold"
        }
    },
    treeView: {
        height: "200px",
        overflow: "auto",
        backgroundColor: grayColor[16],
        padding: "2px",
        "& .MuiTreeItem-label": {
            paddingTop: "4px",
            paddingBottom: "4px"
        },
        "& .MuiTreeItem-group": {
            marginLeft: "10px"
        }
    },
    nodeLabel: {
        display: "flex",
        alignItems: "center",
        "& img": {
            maxWidth: "18px",
            marginRight: "8px"
        }
    }
});
  
export default moveFilesModalStyle;
  
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import NewLinkModal from "../User/NewLinkModal";

import axios from "axios";
import Cookie from "../../../assets/js/utils/Cookie";
import Api from "../../../assets/js/utils/Api";
import { helper } from '../../../assets/js/utils/Element';
import CustomFileUpload from "../CustomFileUpload/CustomFileUpload.jsx";
import CustomInput from "../CustomInput/CustomInput.jsx";
import Danger from "../../components/Typography/Danger";
import GridItem from "../../components/Grid/GridItem";
import fileicon from "../../../assets/img/fileicon.png";
import foldericon from "../../../assets/img/foldericon.png";
import successGif from '../../../assets/img/success.gif';
import MaterialIcon from "@mdi/react";
import { mdiArrowExpandUp, mdiFileCheckOutline, mdiCheck, mdiUndoVariant } from '@mdi/js';
import DateTimePicker from "../CustomInput/DateTimePicker";
import InputAdornment from "@material-ui/core/InputAdornment";
import CalenderIcon from "@material-ui/icons/DateRange";
import ReactTable from "react-table";
import GroupMembersModal from "./GroupMembersModal";
import AddIcon from "@material-ui/icons/Add";
import DragNDrop from "../../components/Drag/Drop";
import DuplicateFileModal from  "../../components/User/DuplicateFileModal";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import uploadFileModalStyle from "../../../assets/jss/user/uploadFileModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const UploadFileModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.editor = React.createRef();
        let yourEmail = Cookie.read("sendlinx_your_email");
        if(!yourEmail || yourEmail === "null"){
            yourEmail = "";
        }
        const { authorized, user } = this.store.getState();
        if(authorized){
            yourEmail = user.email;
        }

        let notifySender = false;
        if(this.props.ownerNotification === 'mandatory'){
            notifySender = true;
        }
        let groupNotifySender = false;
        let usersChecked = [];
        if(this.props.groupNotification === 'mandatory'){
            groupNotifySender = true;
            if(this.props.sharedWith.length > 0){
                usersChecked = this.getSharedWithArray(this.props.sharedWith)
            }
        }

        this.state = {
            emailTo: "",
            recipients: [],
            yourEmail: yourEmail,
            message: "",
            transferType: "link",
            showTransfer: false,
            files: this.props.files,
            scrollTo: 0,
            zipPassword: "",
            expire: "",
            validation: {
                emailTo: "",
                yourEmail: ""                
            },
            uploading: false,
            response: null,
            success: false,
            cancelToken: null,
            totalSize: this.getTotalSize(this.props.files),
            totalSent: 0,
            singleSent: 0,
            snackbarOpen: false,
            totalFiles: 0,
            showError: false,
            errorMessage: "",
            chunkSize: (1048576 * 15), //15 MB by default
            path: this.props.path,
            zipUpload: false,
            zipName: "SL_"+this.getDateString()+".zip",
            resumable: false,
            resumableKey: 0,
            startTime: null,
            notifySender: notifySender,
            groupNotifySender: groupNotifySender,
            duplicatesFound: false,
            duplicates: null,
            duplicateAction: null,
            selectivePendingAction: null,
            selectiveUploadedAction: null,
            keepPendingFiles: [],
            keepUploadedFiles: [],
            sharedWith: this.props.sharedWith,
            usersChecked: usersChecked,
            groupMembersModal: false,
            duplicateFileModal: false,
            viewPassword: false,
            maxFileUploadSize: 107374182400
        };

        this.partNumber = 0;
        this.chunksSent = 0;
        this.onFilesSelect = this.onFilesSelect.bind(this);
        this.sendFiles = this.sendFiles.bind(this);
        this.cancelUpload = this.cancelUpload.bind(this);
        this.sendAnother = this.sendAnother.bind(this);
        this.onUploadProgress = this.onUploadProgress.bind(this);
        this.onFileDelete = this.onFileDelete.bind(this);
        this.resumeUpload = this.resumeUpload.bind(this);
        this.beforeUnload = this.beforeUnload.bind(this);
    }
    componentWillUnmount(){
        window.onbeforeunload = null;
    }
    getSharedWithArray(sharedWith){
        let usersChecked = [];
        sharedWith.map((user) => {
            usersChecked.push(user.user_id);

            return null;
        });

        return usersChecked;
    }
    beforeUnload(){
        try{
            const { response } = this.state;
            const requestData = {
                id: response.hash
            };
            const source = axios.CancelToken.source();
            Api.browserExit(source, requestData).then(data => {
                
            }).catch(err => {
                
            });
        }catch(e){ }
        return "Are you sure you want to cancel this upload?"
    }
    getDateString(){
        const date = new Date();
        const dateString = (date.getMonth() + 1)+"_"+date.getDate()+"_"+date.getFullYear()+"_"+date.getHours()+"_"+date.getMinutes()+"_"+date.getSeconds();
        return dateString;
    }
    onFilesSelect(files){
        let state = {files: files};
        let fileSize = 0;
        files.map((item) => {
            if(item.type === "folder"){
                item.files.map((subFile) => {
                    if(subFile.size > fileSize){
                        fileSize = subFile.size;
                    }
                    return null;
                });
            }else if(item.file.size > fileSize){
                fileSize = item.file.size;
            }
            return null;
        });
        const bigFileSizeInMbs = fileSize/(1024*1024);
        const bigFileSizeInGbs = fileSize/(1024*1024*1024);
        let chunkSize = this.state.chunkSize;
        if(bigFileSizeInGbs >= 1 && bigFileSizeInGbs < 3){
            chunkSize = (parseInt(bigFileSizeInMbs/50))*1024*1024;
        }else if(bigFileSizeInGbs >= 3 && bigFileSizeInGbs < 10){
            chunkSize = (parseInt(bigFileSizeInMbs/100))*1024*1024;
        }else if(bigFileSizeInGbs >= 10 && bigFileSizeInGbs < 15){
            chunkSize = (parseInt(bigFileSizeInMbs/120))*1024*1024;
        }else if(bigFileSizeInGbs >= 15){
            chunkSize = (parseInt(bigFileSizeInMbs/150))*1024*1024;
        }
        state['chunkSize'] = chunkSize;
        // if(files.length > 1){
        //     state['zipUpload'] = true;
        // }
        this.setState(state);
    }
    onScroll(e){
        if(e.target && e.target.scrollTop <= 0){
            this.setState({
                showTransfer: false,
                scrollTo: 0
            });
        }
    }
    sendFiles(){
        Cookie.write("sendlinx_your_email", this.state.yourEmail);
        const { zipUpload, zipName, zipPassword, notifySender } = this.state;
        const requestData = {
            recipients: [],
            from: this.state.yourEmail,
            message: this.state.message,
            transferType: this.state.transferType,
            path: this.state.path,
            expire: this.state.expire,
            uploadType: "direct_upload",
            checkDuplicates: 1,
            zipUpload: (zipUpload ? 1 : 0),
            zipName: zipName,
            zipPassword: zipPassword,
            notifySender: (notifySender ? 1 : 0)
        };

        const { sharedFileId } = this.props;
        if(sharedFileId !== null){
            requestData['sharedFileId'] = sharedFileId;
        }

        const path = helper.getParam("dir");
        if(typeof(path) === "string" && path.indexOf("/Web/My Docs") !== -1){
            requestData['uploadType'] = "my_docs";
        }
        window.onbeforeunload = this.beforeUnload;
        
        if(this.props.isShared === true){
            requestData['isShared'] = true;
        }
        const { duplicateAction, files, keepPendingFiles, duplicates, keepUploadedFiles } = this.state;
        let filteredFiles = files;
        if(duplicateAction !== null){
            requestData['duplicateAction'] = duplicateAction;
            let duplicatesArray = [];
            duplicates.map((file) => {
                duplicatesArray.push(file.pending.name);
                return null;
            });

            // replace skip selective
            if(duplicateAction === "skip"){
                filteredFiles = [];
                files.map((file) => {
                    if(file.type === "folder"){
                        if(duplicatesArray.includes(file.name)){
                            return null;
                        }
                    }else{
                        if(duplicatesArray.includes(file.file.name)){
                            return null;
                        }
                    }
                    filteredFiles.push(file);
                    return null;
                });
            }else if(duplicateAction === "selective"){
                filteredFiles = [];
                files.map((file) => {
                    if(file.type === "folder"){
                        if(keepPendingFiles.includes(file.name) && keepUploadedFiles.includes(file.name) && duplicatesArray.includes(file.name)){
                            file['duplicate'] = 1;
                            filteredFiles.push(file);
                            return null;
                        }
                        if(keepPendingFiles.includes(file.name) || !duplicatesArray.includes(file.name)){
                            filteredFiles.push(file);
                        }
                    }else{
                        if(keepPendingFiles.includes(file.file.name) && keepUploadedFiles.includes(file.file.name) && duplicatesArray.includes(file.file.name)){
                            file['duplicate'] = 1;
                            filteredFiles.push(file);
                            return null;
                        }
                        if(keepPendingFiles.includes(file.file.name) || !duplicatesArray.includes(file.file.name)){
                            filteredFiles.push(file);
                        }
                    }
                    return null;
                });
            }
        }
        if(filteredFiles.length <= 0){
            this.props.onClose();
            return;
        }

        const source = axios.CancelToken.source();
        this.setState({
            uploading: true,
            recipients: [],
            emailTo: "",
            totalFiles: this.getFilesCount(filteredFiles),
            cancelToken: source,
            showError: false,
            totalSize: this.getTotalSize(filteredFiles),
            startTime: new Date(),
            duplicatesFound: false,
            files: filteredFiles,
            userFiles: files
        });

        const that = this;
        Api.sendFiles(requestData, filteredFiles, source, true).then(data => {
            that.setState({
                response: data,
            });
            that.uploadFiles(data.files);
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                if(err.hasOwnProperty("duplicates") && err.duplicates.length > 0){
                    this.setState({
                        showError: true,
                        duplicatesFound: true,
                        duplicates: err.duplicates,
                        errorMessage: err.message,
                        duplicateAction: null,
                        selectivePendingAction: null,
                        selectiveUploadedAction: null,
                        keepPendingFiles: [],
                        keepUploadedFiles: [],
                        files: this.state.userFiles
                    });
                    return;
                }
                this.setState({
                    showError: true,
                    errorMessage: err.message,
                    files: this.state.userFiles
                });
            }
        });
    }
    cancelUpload(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
        
        this.setState({
            uploading: false,
            cancelToken: null,
            totalSent: 0,
            singleSent: 0,
            showError: false,
            errorMessage: ""
        });

        const { response } = this.state;
        if(response === null){
            return;
        }

        const requestData = {
            id: response.id
        }

        Api.cancelFiles(requestData).then(data => {
            //Silent
        }).catch(err => {
            //Silent
        });
    }

    resumeUpload(){
        this.setState({
            singleSent: 0,
        });
        this.partNumber = this.partNumber - 1;
        if(this.partNumber < 0){
            this.partNumber = 0;
        }
        const { response, resumableKey } = this.state;
        const fileData = this.getNextFile(response.files, resumableKey);
        const source = axios.CancelToken.source();
        this.uploadMultiPart(response.files, fileData, resumableKey, source);
        this.partNumber = this.partNumber + 1;
    }
    sendAnother(){
        this.setState({
            success: false,
            files: [],
            singleSent: 0,
            totalSent: 0,
            duplicatesFound: false,
            duplicates: null,
            duplicateAction: null,
            selectivePendingAction: null,
            selectiveUploadedAction: null,
            keepPendingFiles: [],
            keepUploadedFiles: []
        });
    }
    getTotalSize(files = null){
        if(files === null){
            files = this.state.files;
        }
        let totalSize = 0;
        files.map(item => {
            if(item.type === "folder"){
                item.files.map(subItem => {
                    let file = subItem;
                    if(item.hasOwnProperty("checkRelativePath")){
                        file = subItem.file;
                    }
                    totalSize += file.size;
                    return null;
                });
            }else{
                totalSize += item.file.size;
                return false;
            }
            return null;
        });
        return totalSize;
    }
    getFilesCount(files = null){
        let totalFiles = 0;
        if(files === null){
            files = this.state.files;
        }
        files.map(item => {
            if(item.type === "folder"){
                totalFiles += item.files.length;
            }else{
                totalFiles++;
            }
            return null;
        });
        return totalFiles;
    }
    getRemainingSize(){
        let totalSize = 0;
        let maxSize = 2147483648;
        const { authorized, user } = this.store.getState();
        if(authorized){
            maxSize = user.quota - user.usage;
        }
        this.state.files.map(item => {
            if(item.type === "folder"){
                item.files.map(subItem => {
                    let file = subItem;
                    if(item.hasOwnProperty("checkRelativePath")){
                        file = subItem.file;
                    }
                    totalSize += file.size;
                    return null;
                });
            }else{
                totalSize += item.file.size;
                return false;
            }
            return null;
        });
        return maxSize - totalSize;
    }
    isMultiPartSupported(file){
        if(file === null){
            return false;
        }
        const chunks = this.getTotalChunks(file);
        if(chunks < 2){
            return false;
        }
        const slice = file.slice || file.mozSlice || file.webkitSlice;
        if(typeof(slice) === "function"){
            return true;
        }
        return false;
    }
    getPartContent(file){
        let { chunkSize } = this.state;
        const slice = file.slice || file.mozSlice || file.webkitSlice;
        let end = this.chunksSent + chunkSize;
        if ((file.size - end) < 0) {
            end = file.size;
        }
        
        const blob = slice.call(file, this.chunksSent, end);
        // this.chunksSent = this.chunksSent + chunkSize;
        return blob;
    }
    getTotalChunks(file){
        const { chunkSize } = this.state;
        var chunks = Math.ceil(file.size/chunkSize,chunkSize);
        return chunks;
    }
    uploadFiles(files, key = 0){
        const fileData = this.getNextFile(files, key);
        const that = this;
        if(fileData === null){
            window.onbeforeunload = null;
            if(this.state.files.length > 0){
                const source = axios.CancelToken.source();
                that.setState({
                    cancelToken: source
                });
                const { zipUpload, response, zipName, zipPassword, notifySender, path, groupNotifySender, usersChecked } = this.state;
                const { sharedFileId } = this.props;
                const requestData = {
                    zipUpload: (zipUpload ? 1 : 0)
                };
                if(zipName !== null){
                    requestData['zipName'] = zipName;
                }
                if(zipPassword !== null){
                    requestData['zipPassword'] = zipPassword;
                }
                if(notifySender){
                    requestData['notifySender'] = (notifySender ? 1 : 0);
                }
                if(sharedFileId !== null || sharedFileId !== ''){
                    requestData['sharedFileId'] = sharedFileId;
                }
                if(groupNotifySender && usersChecked.length > 0){
                    requestData['groupNotifySender'] = usersChecked;
                }
                if(path){
                    requestData['path'] = path;
                }

                Api.processMedia(response.hash, source, true, requestData).then(data => {
                    this.setState({
                        uploading: false,
                        success: true
                    });
                }).catch(err => {
                    this.setState({
                        uploading: false,
                        success: true
                    });
                });
            }else{
                this.setState({
                    uploading: false,
                    success: true
                });
            }
            return;
        }

        if(this.isMultiPartSupported(fileData.localFile)){
            try{
                this.partNumber = 0;
                this.chunksSent = 0;
                const source = axios.CancelToken.source();
                this.uploadMultiPart(files, fileData, key, source);
                this.partNumber = this.partNumber + 1;
            }catch(e){
                console.log(e);
            }
        }else{
            const source = axios.CancelToken.source();
            that.setState({
                cancelToken: source,
                showError: false,
                errorMessage: ""
            });
            Api.createMedia(fileData.localFile, fileData.uploadedFile, source, this.onUploadProgress, true).then(data => {
                const totalSent = that.state.totalSent + fileData.localFile.size;
                that.setState({
                    totalSent: totalSent,
                    singleSent: 0,
                });
                that.uploadFiles(files, ++key);
            }).catch(err => {
                if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                    this.setState({
                        showError: true,
                        errorMessage: err.message
                    });
                }
            });
        }
    }
    uploadMultiPart(files, fileData, key, source){
        const blob = this.getPartContent(fileData.localFile);
        const requestData = {
            partNumber: (this.partNumber + 1),
            chunkSize: blob.size,
            id: fileData.uploadedFile.id
        };
        this.setState({
            cancelToken: source,
            showError: false,
            errorMessage: "",
            resumableKey: key,
            resumable: false,
            uploading: true
        });
        
        const that = this;
        var chunks = this.getTotalChunks(fileData.localFile);
        const { chunkSize } = this.state;
        Api.getPartUrl(fileData.localFile, blob, requestData, source, this.onUploadProgress, true).then(data => {
            this.chunksSent = this.chunksSent + chunkSize;
            if(this.partNumber < chunks){
                source = axios.CancelToken.source();
                that.setState({
                    totalSent: that.state.totalSent + blob.size,
                    singleSent: 0,
                    cancelToken: source,
                    showError: false,
                    errorMessage: ""
                });
                this.uploadMultiPart(files, fileData, key, source);
                this.partNumber = this.partNumber + 1;
            }else{
                const source = axios.CancelToken.source();
                that.setState({
                    totalSent: that.state.totalSent + blob.size,
                    singleSent: 0,
                    cancelToken: source,
                    showError: false,
                    errorMessage: ""
                });
                that.finalizeMultiPart(files, fileData, key, source);
            }
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                let state = {
                    showError: true,
                    errorMessage: err.message
                };
                if(err.message === "Network Error"){
                    state['resumable'] = true;
                }
                this.setState(state);
            }
        });
    }
    finalizeMultiPart(files, fileData, key, source){
        const requestData = {
            chunks: this.getTotalChunks(fileData.localFile),
            id: fileData.uploadedFile.id
        };
        Api.finalizeMultiPart(requestData, source, true).then(data => {
            this.uploadFiles(files, ++key);
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    showError: true,
                    errorMessage: err.message
                });
            }
        });
    }
    getNextFile(files, key){
        if(!files.hasOwnProperty(key)){
            return null;
        }

        const uploadedFile = files[key];
        let localFile = null;

        this.state.files.map(item => {
            if(item.type === "folder"){
                item.files.map(subItem => {
                    let file = subItem;
                    if(item.hasOwnProperty("checkRelativePath")){
                        file = subItem.file;
                    }
                    let filePath = file.webkitRelativePath;
                    if(item.hasOwnProperty("checkRelativePath")){
                        filePath = subItem.webkitRelativePath;
                    }
                    if(filePath === uploadedFile.path+"/"+uploadedFile.localName){
                        localFile = file;
                        return false;
                    }
                    return null;
                });
            }else if(item.file.name === uploadedFile.localName){
                localFile = item.file;
                return false;
            }
            return null;
        });
        const fileObject = {
            localFile: localFile,
            uploadedFile: uploadedFile
        }
        return fileObject;
    }
    onFileDelete(key){
        let filesArray = this.state.files;
        filesArray.splice(key, 1);
        this.onFilesSelect(filesArray);
        this.setState({
            files: filesArray
        });
    }
    getDownloadLink(){
        const { response } = this.state;
        const location = window.location;
        let downloadLink = location.protocol+"//"+location.host+"/downloads";
        if(response !== null){
            downloadLink += "/"+response.hash;
        }
        return downloadLink;
    }
    onUploadProgress(e){
        this.setState({
            singleSent: e.loaded
        });
    }
    handleCheckbox(e, name){
        const { groupNotifySender } = this.state;
        let state = {};
        state[name] = e.target.checked;
        if(name === 'groupNotifySender' && groupNotifySender === false){
            state['groupMembersModal'] = true;
        }else if(name === 'groupNotifySender' && groupNotifySender === true){
            state['usersChecked'] = []
        }

        this.setState(state);
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        this.setState(state);
    }
    handleDateChange(date, name) {
        try{
            let parsedDate = new Date(date);
            if(parsedDate === "Invalid Date"){
                return;
            }
            this.setState({
                [name]: parsedDate.toISOString()
           });
        }catch(e){ console.log(e); }        
    }
    renderFile(item, key){        
        let  folderSize = 0;
        if(item.type === "folder"){
            item.files.map(subItem => {
                let file = subItem;
                if(item.hasOwnProperty("checkRelativePath")){
                    file = subItem.file;
                }
                return folderSize += file.size;
            });
        }
        if(item.type === "file"){
            return <li key={key}>
                <div className="file">
                    <div className="file-header">
                        <div className="file-title">
                            <span className="file-icon icon"><img src={fileicon} alt={"file"} /></span>
                            <span className="file-name">{item.file.name}</span>
                            <span className="file-detail">{helper.getFormatedSize(item.file.size)}</span>
                        </div>
                        <div className="file-actions">
                            <div className="filelist-action filelist-action-delete">
                                <svg  onClick={() => this.onFileDelete(key)} xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 2560 1920">
                                    <path d="M754 1868c-28-29-209-234-403-456L0 1010v-48c0-41 5-55 37-93C135 754 769 33 787 17c19-16 65-17 789-15 767 3 769 3 810 25 51 27 120 96 147 147l22 41v1490l-22 41c-27 51-96 120-147 147-41 22-43 22-811 25l-770 2-51-52zm1531-180c56-25 55-11 55-727 0-490-3-667-12-686-25-56-9-55-745-55H905L585 585C409 786 265 955 265 960s144 174 320 375l320 365h677c504 0 684-3 703-12z"/>
                                    <path d="M1204 1356c-28-28-34-41-34-77 0-41 4-46 137-181l137-138-137-138c-133-135-137-140-137-181 0-36 6-49 34-77s41-34 77-34c41 0 46 4 181 137l138 137 138-137c135-133 140-137 181-137 36 0 49 6 77 34s34 41 34 77c0 41-4 46-137 181l-137 138 137 138c133 135 137 140 137 181 0 36-6 49-34 77s-41 34-77 34c-41 0-46-4-181-137l-138-137-138 137c-135 133-140 137-181 137-36 0-49-6-77-34z"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </li>;
        }
        return <li key={key}>
            <div className="file">
                <div className="file-header">
                    <div className="file-title">
                        <span className="folder-icon icon"><img src={foldericon} alt={"folder"} /></span>
                        <span className="file-name">{item.name} ({item.files.length} items)</span>
                        <span className="file-detail">{helper.getFormatedSize(folderSize)}</span>
                    </div>
                    <div className="fileactions">
                        <div className="filelist-action filelist-action-delete">
                            <svg  onClick={() => this.onFileDelete(key)} xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 2560 1920">
                                <path d="M754 1868c-28-29-209-234-403-456L0 1010v-48c0-41 5-55 37-93C135 754 769 33 787 17c19-16 65-17 789-15 767 3 769 3 810 25 51 27 120 96 147 147l22 41v1490l-22 41c-27 51-96 120-147 147-41 22-43 22-811 25l-770 2-51-52zm1531-180c56-25 55-11 55-727 0-490-3-667-12-686-25-56-9-55-745-55H905L585 585C409 786 265 955 265 960s144 174 320 375l320 365h677c504 0 684-3 703-12z"/>
                                <path d="M1204 1356c-28-28-34-41-34-77 0-41 4-46 137-181l137-138-137-138c-133-135-137-140-137-181 0-36 6-49 34-77s41-34 77-34c41 0 46 4 181 137l138 137 138-137c135-133 140-137 181-137 36 0 49 6 77 34s34 41 34 77c0 41-4 46-137 181l-137 138 137 138c133 135 137 140 137 181 0 36-6 49-34 77s-41 34-77 34c-41 0-46-4-181-137l-138-137-138 137c-135 133-140 137-181 137-36 0-49-6-77-34z"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </li>;
    }
    handleToggle(user) {
        const { usersChecked } = this.state;
        const currentIndex = usersChecked.indexOf(user.id);
        const newChecked = [...usersChecked];
    
        if (currentIndex === -1) {
            newChecked.push(user.id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            usersChecked: newChecked,
        });
    }
    getTrProps(state, rowInfo){
        if (rowInfo && rowInfo.row) {
            return {
                onClick: (e) => this.handleToggle(rowInfo.original),
            }
        }else{
            return {}
        }
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    getColumns(){
        let columns = [
            {
                Header: "",
                accessor: "check",
                sortable: false,
                filterable: false,
                headerClassName: "hd_check",
                className: "hd_check td_check",
            },
            {
                Header: "First Name",
                accessor: "firstName",
                headerClassName: "hd_name",
                className: "hd_name td_name",
            },
            {
                Header: "Last Name",
                accessor: "lastname",
                headerClassName: "hd_name",
                className: "hd_name td_name",
            },
            {
                Header: "Email Address",
                accessor: "email",
                headerClassName: "hd_email",
                className: "hd_email td_email",
            }
        ];

        return columns;
    }
    getTableData(){
        const { classes } = this.props;
        const { sharedWith, usersChecked } = this.state;

        if(sharedWith === null){
            return [];
        }
        let tableData = [];

        sharedWith.map(user => {
            if(!usersChecked.includes(user.user_id)){
                return null;
            }
            let userArray = {
                id: user.user_id,
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={usersChecked.includes(user.user_id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(user)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                firstName: user.first_name,
                lastName: user.last_name,
                email: user.email,
            };
            
            tableData.push(userArray);
            return null;
        });
        return tableData;
    }
    onViewPassword(){
        this.setState({
            viewPassword: !this.state.viewPassword
        })
    }
    renderForm(){
        const { files, zipName, zipUpload, zipPassword, expire, notifySender, groupNotifySender, usersChecked, viewPassword, maxFileUploadSize } = this.state;
        const { classes, sharedFileId, ownerNotification, groupNotification } = this.props;
        const { user } = this.store.getState();

        const filesCount = this.getFilesCount();
        const remainingSize = this.getRemainingSize();
        return(
            <div className={classes.uploadContainer}>
                <div className={classes.fileSendingForm}>
                    <div className={classes.formFiles+" uploader-files"}>
                        <CustomFileUpload
                            id="outlined-fileUpload"
                            multiple={true}
                            onChange={(files) => this.onFilesSelect(files)}
                            files={files}
                            store={this.store}
                        />
                    </div>
                    <div className={classes.transferFooter+" "+classes.fileSendingFormFooter}>
                        <div>
                            <FormControlLabel
                                classes={{label: classes.label}}
                                control={
                                    <Checkbox
                                        tabIndex={-1}
                                        onClick={(e) => this.handleCheckbox(e, "zipUpload")}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{checked: classes.checked, root: classes.checkRoot}}
                                        checked={zipUpload}
                                        // disabled={(filesCount <= 1)}
                                    />
                                }
                                label={(zipUpload ? "Do you wish to Zip the file?" : "Zip File")}
                            />
                        </div>
                        {
                            sharedFileId !== null ?
                                <>
                                    <div>
                                        {
                                            ownerNotification === 'optional' || ownerNotification === 'mandatory' ?
                                                <FormControlLabel
                                                    classes={{label: classes.label}}
                                                    control={
                                                        <Checkbox
                                                            tabIndex={-1}
                                                            onClick={(e) => this.handleCheckbox(e, "notifySender")}
                                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                            classes={{checked: classes.checked, root: classes.checkRoot}}
                                                            checked={notifySender}
                                                            disabled={(ownerNotification === 'mandatory' ? true : false)}
                                                        />
                                                    }
                                                    label="Notify owner"
                                                />
                                            :
                                                <></>
                                        }
                                        {
                                            groupNotification === 'optional' || groupNotification === 'mandatory' ?
                                                <FormControlLabel
                                                    classes={{label: classes.label}}
                                                    control={
                                                        <Checkbox
                                                            tabIndex={-1}
                                                            onClick={(e) => this.handleCheckbox(e, "groupNotifySender")}
                                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                            classes={{checked: classes.checked, root: classes.checkRoot}}
                                                            checked={groupNotifySender}
                                                            disabled={(groupNotification === 'mandatory' ? true : false)}
                                                        />
                                                    }
                                                    label="Notify Group Members"
                                                />
                                            :
                                                <></>
                                        }
                                    </div>
                                    <div>
                                        {
                                            groupNotification === 'optional' && groupNotifySender === true ?
                                                <Button color="white" className={classes.addBtn} onClick={() => this.onGroupMembersModal(true)}>
                                                    <AddIcon />
                                                    Add Members
                                                </Button>
                                            :
                                                <></>
                                        }
                                        {
                                            groupNotification === 'optional' && usersChecked.length > 0 ?
                                                <ReactTable
                                                    columns={this.getColumns()}
                                                    data={this.getTableData()}
                                                    pageSizeOptions={[500]}
                                                    defaultPageSize={50000000000}
                                                    showPaginationTop={false}
                                                    minRows={0}
                                                    showPaginationBottom={false}
                                                    className={"-striped -highlight "+classes.filesTable}
                                                    getTrProps={(state, rowInfo) => this.getTrProps(state, rowInfo)}
                                                    getNoDataProps={() => this.getNoDataProps()}
                                                />
                                            :
                                                <></>
                                        }
                                    </div>
                                </>
                            :
                            <></>
                        }
                        {
                            zipUpload ?
                                <>
                                    <div className={classes.zipName+" "+classes.inlineFields}>
                                        <CustomInput
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            id="input-zipName"                                        
                                            inputProps={{
                                                onChange: (e) => this.handleChange(e,'zipName'),
                                                name: "zipName",
                                                type: "text",
                                                value: zipName,
                                                placeholder:"Zip Name"
                                            }}
                                        />
                                        <DateTimePicker
                                            labelText={null}
                                            id="input-expire"
                                            value={expire}
                                            onChange={(date) => this.handleDateChange(date, 'expire')}
                                            inputProps={{
                                                placeholder:"Expiry Date/Time",
                                                startAdornment: (
                                                    <InputAdornment position="start" className={classes.inputAdornment} >
                                                        <CalenderIcon className={classes.inputAdornmentIcon}/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            disablePast={true}
                                        />
                                    </div>
                                    <div className={classes.zipName+" "+classes.zipPassword}>
                                        <CustomInput
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            id="input-zipPassword"                                        
                                            inputProps={{
                                                onChange: (e) => this.handleChange(e,'zipPassword'),
                                                name: "zipPassword",
                                                type: viewPassword ? "text" : "password",
                                                value: zipPassword,
                                                placeholder:"Zip Password",
                                                endAdornment: (
                                                    <InputAdornment 
                                                        className={classes.inputAdornment+' viewPasswordIcon'} 
                                                        onClick={() => this.onViewPassword() }
                                                        position='end'
                                                    >
                                                        {
                                                            zipPassword ?
                                                                viewPassword ?
                                                                    <VisibilityOffIcon className={classes.inputAdornmentIcon}/>
                                                                :
                                                                    <VisibilityIcon className={classes.inputAdornmentIcon}/>
                                                            :
                                                                <></>
                                                        }
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                </>
                            :
                            <></>
                        }
                        <div className={classes.separator}></div>
                        <Button color="custom" onClick={this.sendFiles} className={(files.length === 0 || (user.quota - user.usage) < this.getTotalSize() || (this.getTotalSize() > maxFileUploadSize) ? 'disable-btn': classes.sendButton)} type="button" disabled={(files.length === 0 || (user.quota - user.usage) < this.getTotalSize())}>
                            <MaterialIcon path={mdiArrowExpandUp} className="MuiSvgIcon-root" />
                            Upload
                        </Button>
                    </div>
                    {
                        (user.quota - user.usage) < this.getTotalSize() ?
                            <div className={classes.storageExceedMessage}>
                                <p>Your upload exceeds the maximum allowed quota of {helper.getFormatedSize(user.quota)}. Your current remaining storage is {helper.getFormatedSize(user.quota - user.usage)}.</p>
                            </div>
                        :
                            <></>
                    }
                    {
                        this.getTotalSize() > maxFileUploadSize ?
                            <div className={classes.storageExceedMessage}>
                                <p>Your file exceeds to {helper.getFormatedSize(maxFileUploadSize)}. Max limit per file size is {helper.getFormatedSize(maxFileUploadSize)}.</p>
                            </div>
                        :
                            <></>
                    }
                    {
                        files.length > 0 ?
                            <div className={classes.uploaderList+" uploader-list"}>
                                <span className="uploader-add-files-count">{filesCount} {filesCount > 1 ? "files" : "file"} added<span>{helper.getFormatedSize(remainingSize)} remaining</span></span>
                                <ul className={classes.filesList}>
                                    {
                                        files.map((item, key) => (
                                            this.renderFile(item, key)
                                        ))
                                    }
                                </ul>
                            </div>
                        :
                            <></>
                    }
                </div>
            </div>
        )
    }
    formatEstimatedTime(expectedTime){
        if(expectedTime <= 2){
            return "Few Seconds";
        }
        if(expectedTime < 60){
            expectedTime = Math.trunc(expectedTime);
            return expectedTime+" Seconds";
        }
        let minutes = expectedTime/60;
        if(minutes < 60){
            const seconds = Math.round(expectedTime%60);
            minutes = Math.trunc(minutes);
            return (minutes+" Minutes"+(seconds ? (" "+seconds+" Seconds") : ""));
        }
        expectedTime = expectedTime/60;
        let hours = expectedTime/60;
        minutes = Math.round(expectedTime%60);
        hours = Math.trunc(hours);
        return (hours+" Hours"+(minutes ? (" "+minutes+" Minutes") : ""));
    }
    getEstimatedTimeLeft(){
        const { startTime, totalSize, totalSent, singleSent } = this.state;
        const currentTime = new Date();
        const timeElapsed = (currentTime - startTime)/1000;
        const totalUploaded = totalSent + singleSent;
        if(totalUploaded <= 0){
            return null;
        }
        let uploadRate = (totalUploaded/timeElapsed);
        const expectedTime = (totalSize - totalUploaded)/uploadRate;
        const timeEstimate = this.formatEstimatedTime(expectedTime);
        let unit = "Byte";
        if(uploadRate >= 1024){
            uploadRate = uploadRate/1024;
            unit = "KB";
        }
        if(uploadRate >= 1024){
            uploadRate = uploadRate/1024;
            unit = "MB";
        }
        uploadRate = Math.round(uploadRate);
        return timeEstimate+" ("+uploadRate+" "+unit+"/sec)";
    }
    renderUploading(){
        const { transferType, recipients, totalSize, totalSent, singleSent, totalFiles, showError, 
            errorMessage, resumable  } = this.state;
        const { classes } = this.props;
        
        const totalUploaded = totalSent + singleSent;
        let progress = 0;
        let progressOffset = 502.655;
        if(totalSize > 0){
            progress = (totalUploaded/totalSize)*100;
            if(progress > 0){
                progressOffset = ((100 - progress)/100)*progressOffset;
            }
        }
        const roundedValue = Math.round(progress);
        const estimateTimeLeft = this.getEstimatedTimeLeft();
        return (
            <div className={classes.uploadContainer}>
                <div className={classes.uploadingProgress}>
                    <div className={classes.uploadingContent}>
                        <div className="transfer_loader">
                            <svg height="170" width="170" shapeRendering="geometricPrecision" viewBox="0 0 170 170">
                                <circle className="loader__background" r="80" cx="85" cy="85" fill="transparent"style={{
                                    strokeDasharray: 502.655,
                                    strokeDashoffset: 0,
                                    strokeWidth: 10,
                                    stroke: "rgb(232, 235, 237)"
                                }}></circle>
                                <circle className="loader_foreground" r="80" cx="85" cy="85" fill="transparent" style={{
                                    strokeDasharray: 502.655,
                                    strokeDashoffset: progressOffset,
                                    strokeWidth: 10,
                                    stroke: "rgb(64, 159, 255)"
                                }} ></circle>
                            </svg>
                            <span>{`${roundedValue}%`}</span>
                        </div>
                        {
                            showError ?
                                <GridItem>
                                    <Danger>{errorMessage}</Danger>
                                </GridItem>
                            :
                            <>
                            <h2>Transferring</h2>
                            {
                                transferType === "email" ?
                                    <p className="orangeColor">Sending {totalFiles} files to {recipients.length} recipient(s)</p>
                                :
                                    <p className="orangeColor">Uploading {totalFiles} files</p>
                            }
                            
                            <p>{helper.getFormatedSize(totalUploaded)} of {helper.getFormatedSize(totalSize)} uploaded</p>
                            {
                                estimateTimeLeft !== null ?
                                    <p>Estimated Time Left: {estimateTimeLeft}</p>
                                :
                                <p>Estimated Time Left: Calculating</p>
                            }
                            </>
                        }
                        
                    </div>
                    <div className={classes.transferFooter+" "+classes.uploadingProgressFooter}>
                        {
                            resumable ?
                                <Button color="custom" className="transfer-button" type="button" round onClick={this.resumeUpload} >Resume</Button>
                            :
                                <Button color="custom" className="transfer-button" type="button" round onClick={this.cancelUpload} >Cancel</Button>
                        }
                        
                    </div>
                </div>
            </div>
        );
    }
    renderSuccess(){
        const { classes } = this.props;
        const { response, expire, zipPassword } = this.state;

        if(expire.length > 0){
            const downloadLink = "/downloads/"+response.hash;
            return (
                <NewLinkModal 
                    open={true} 
                    saving={false} 
                    onClose={() => this.props.onClose()} 
                    onSuccess={() => this.props.onClose()}
                    onLinkName={(name, linkPassword) => this.props.onClose()}
                    loadingMessage="Please wait while we prepare your download link. It may take few minutes depending on size and number of file(s)."
                    successMessage={'Your link is now ready to be shared.' }
                    errorMessage={null}
                    downloadLink={downloadLink}
                    linkName={response.title}
                    store={this.store}
                    linkHash={response.hash}
                    expire={expire}
                    linkPassword={zipPassword}
                />
            );
        }
        
        return (
            <div className={classes.uploadContainer}>
                <div className={classes.uploaderComplete}>
                    <div className={classes.uploaderCompleteContent}>
                        <div className="success-image">
                            <img src={successGif} alt="success"></img>
                        </div>
                        <h2>Upload Complete!</h2>
                    </div>
                    <div className={classes.transferFooter+" "+classes.uploadingSuccessFooter}>
                        <Button color="white" className="transfer-button" type="button" round onClick={this.sendAnother} >Upload another?</Button>
                        <Button color="custom" className="transfer-button" type="button" round onClick={() => this.props.onClose()} >Finish</Button>
                    </div>
                </div>
            </div>
        );
    }
    handleSelectiveAction(value, name){
        let { duplicates, selectivePendingAction, selectiveUploadedAction } = this.state;
        let state = {};
        state[name] = value;
        if(value === "overwrite"){
            state['keepPendingFiles'] = [];
            if(selectivePendingAction === value){
                state[name] = null;
            }else{
                duplicates.map((file) => {
                    state['keepPendingFiles'].push(file.pending.name);
                    return null;
                });
            }
        }else{
            state['keepUploadedFiles'] = [];
            if(selectiveUploadedAction === value){
                state[name] = null;
            }else{
                duplicates.map((file) => {
                    state['keepUploadedFiles'].push(file.uploaded.name);
                    return null;
                });
            }
        }
        this.setState(state);
    }
    handleFileAction(type, file){
        const { keepPendingFiles, keepUploadedFiles, duplicates } = this.state;
        const pendingIndex = keepPendingFiles.indexOf(file.pending.name);
        const uploadedIndex = keepUploadedFiles.indexOf(file.uploaded.name);
        let state = {};
        if(type === "pending"){
            if (pendingIndex === -1) {
                keepPendingFiles.push(file.pending.name);
            } else {
                keepPendingFiles.splice(pendingIndex, 1);
                state['selectivePendingAction'] = null;
            }
            if(keepPendingFiles.length === duplicates.length){
                state['selectivePendingAction'] = "overwrite";
            }
        }else{
            if (uploadedIndex === -1) {
                keepUploadedFiles.push(file.uploaded.name);
            } else {
                keepUploadedFiles.splice(uploadedIndex, 1);
                state['selectiveUploadedAction'] = null;
            }
            if(keepUploadedFiles.length === duplicates.length){
                state['selectiveUploadedAction'] = "skip";
            }
        }
        this.setState({
            ...state,
            keepPendingFiles: keepPendingFiles,
            keepUploadedFiles: keepUploadedFiles
        });
    }
    getFormatedDateTime(date){
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        let hours = date.getHours();
        const mins = date.getMinutes();
        var a = ( hours >= 12 ) ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        const dateTime = year+"-"+month+"-"+day+" "+hours+":"+mins+" "+a;
        return dateTime;
    }
    onSelectionCancel(){
        this.setState({
            duplicateAction: null,
        });
    }
    onSelectionConfirm(){
        const { keepPendingFiles, keepUploadedFiles, duplicates } = this.state;
        let isValid = true;
        duplicates.map((file) => {
            if(!keepPendingFiles.includes(file.pending.name) && !keepUploadedFiles.includes(file.uploaded.name)){
                isValid = false;
            }
            return null;
        });

        if(!isValid){
            return;
        }

        this.sendFiles();
    }
    renderDuplicates(){
        const { duplicates, path, files, duplicateAction, selectivePendingAction, keepPendingFiles, keepUploadedFiles, selectiveUploadedAction } = this.state;
        const { classes } = this.props;
        let pathArray = [];
        let folderName = null;
        if(typeof(path) === "string" && path.trim().length){
            pathArray = path.split("/");
            folderName = pathArray[pathArray.length - 1];
        }
        const date = new Date();
        const dateTime = this.getFormatedDateTime(date);

        if(duplicates !== null && duplicateAction === "selective"){
            return (
                <div className={classes.duplicateSelection}>
                    <p>File(s) with that name already exist(s). Please select which one(s) you would like to keep.</p>
                    <p>If you select both versions, the copied file will have number added to its name.</p>
                    <div className={classes.selectiveCheckboxes}>
                        <FormControlLabel
                            classes={{label: classes.label}}
                            control={
                                <Checkbox
                                    tabIndex={-1}
                                    onClick={(e) => this.handleSelectiveAction("overwrite", "selectivePendingAction")}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{checked: classes.checked, root: classes.checkRoot}}
                                    checked={selectivePendingAction === "overwrite"}
                                />
                            }
                            label={"File(s) pending upload"}
                        />
                        <FormControlLabel
                            classes={{label: classes.label}}
                            control={
                                <Checkbox
                                    tabIndex={-1}
                                    onClick={(e) => this.handleSelectiveAction("skip", "selectiveUploadedAction")}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{checked: classes.checked, root: classes.checkRoot}}
                                    checked={selectiveUploadedAction === "skip"}
                                />
                            }
                            label={"File(s) already in folder ("+folderName+")"}
                        />
                    </div>
                    <ul>
                        {
                            duplicates.map((file, key) => {
                                return (
                                    <li key={key}>
                                        <div className={classes.duplicateFileName}>{file.pending.name}</div>
                                        <div className={classes.duplicateFile}>
                                            <FormControlLabel
                                                classes={{label: classes.label}}
                                                control={
                                                    <Checkbox
                                                        tabIndex={-1}
                                                        onClick={(e) => this.handleFileAction("pending", file)}
                                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{checked: classes.checked, root: classes.checkRoot}}
                                                        checked={keepPendingFiles.includes(file.pending.name)}
                                                    />
                                                }
                                                label={(
                                                    <div>
                                                        {dateTime}
                                                        <div>{helper.getFormatedSize(file.pending.size)}</div>
                                                    </div>
                                                )}
                                            />
                                            <FormControlLabel
                                                classes={{label: classes.label}}
                                                control={
                                                    <Checkbox
                                                        tabIndex={-1}
                                                        onClick={(e) => this.handleFileAction("uploaded", file)}
                                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{checked: classes.checked, root: classes.checkRoot}}
                                                        checked={keepUploadedFiles.includes(file.uploaded.name)}
                                                    />
                                                }
                                                label={(
                                                    <div>
                                                        {this.getFormatedDateTime(new Date(file.uploaded.updated_at))}
                                                        <div>{helper.getFormatedSize(file.uploaded.size)}</div>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <div className={classes.selectionButtons}>
                        <Button color="custom" onClick={() => this.onSelectionConfirm()}>Continue</Button>
                        <Button color="white" onClick={() => this.onSelectionCancel()}>Cancel</Button>
                    </div>
                </div>
            )
        }
        return (
            <div className={classes.duplicates}>
                <h4>
                    Uploading {files.length} files
                    {
                        folderName !== null ?
                            " to "+folderName
                        :
                        <></>
                    }
                </h4>
                <p>The destination has {files.length} file(s) or folder(s) with the same names</p>
                <ul>
                    <li className="active" onClick={() => this.onDuplicateFileAction("replace")}>
                        <MaterialIcon path={mdiCheck} className={classes.checkIcon+" MuiSvgIcon-root"} />
                        Replace the files in the destination
                    </li>
                    <li onClick={() => this.onDuplicateFileAction("skip")}>
                        <MaterialIcon path={mdiUndoVariant} className={classes.skipIcon+" MuiSvgIcon-root"} />
                        Skip these files
                    </li>
                    <li onClick={() => this.onDuplicateFileAction("selective")}>
                        <MaterialIcon path={mdiFileCheckOutline} className="MuiSvgIcon-root" />
                        Let me decide for each file
                    </li>
                </ul>
            </div>
        )
    }
    onDuplicateFileAction(action){
        if(action === "replace" || action === "skip"){
            this.setState({duplicateAction: action, duplicatesFound: false}, () => {
                this.sendFiles();
            });
            return;
        }

        this.setState({
            duplicateAction: action,
        });
    }
    onGroupMembersModal(status = false){
        this.setState({
            groupMembersModal: status,
        });
    }
    onGroupMembersModalSuccess(usersChecked){
        this.setState({
            usersChecked: usersChecked,
            groupMembersModal: false,
        })
    }
    hasFile(fileName, filesArray){
        let hasFile = false;
        if(filesArray.length > 0){
            filesArray.map((item, key) => {
                if(item.type === 'file' && item.file.name === fileName){
                    hasFile = true;
                }
                return null;
            })
        }

        return hasFile;
    }
    hasFolder(folderName, filesArray){
        let hasFolder = false;
        if(filesArray.length > 0){
            filesArray.map((item, key) => {
                if(item.type === 'folder' && item.name === folderName){
                    hasFolder = true;
                }
                return null;
            })
        }

        return hasFolder;
    }
    onDrop(filesArray){
        let { files } = this.state;
        let duplicateFileModal = false;

        filesArray.map(file => {
            let hasFile = false;
            let hasFolder = false;

            if(file.type === 'file'){
                hasFile = this.hasFile(file.file.name, files);
            }else if(file.type === 'folder'){
                hasFolder = this.hasFolder(file.name, files);
            }
            if(hasFile === true || hasFolder === true){
                duplicateFileModal = true;
                
                return null;
            }
            files.push(file);

            return null;
        });

        this.setState({
            files: files,
            duplicateFileModal: duplicateFileModal
        });
    }
    onDuplicateFileModal(status = false){
        this.setState({
            duplicateFileModal: status,
        })
    }
    render() {
        const { uploading, success, duplicatesFound, duplicates, duplicateAction, sharedWith, groupMembersModal, usersChecked,
        duplicateFileModal } = this.state;
        const { classes } = this.props;

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal+" "+ ((duplicatesFound && duplicates !== null && duplicateAction === "selective") ? classes.modalDuplicates : "")
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="uploadFile-slide-title"
                aria-describedby="uploadFile-slide-description"
            >
                <DialogTitle
                    id="uploadFile-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        {" "}
                        {
                            uploading === false ?
                                <Close className={classes.modalClose} />
                            :
                                <></>
                        }
                    </Button>
                    <h5 className={classes.modalTitle}>
                        {
                            duplicatesFound && duplicates !== null ?
                                duplicateAction === "selective" ?
                                    duplicates.length+" Total File Conflict(s)"
                                :
                                "Replace or Skip Files"
                            :
                                "Upload Files"
                        }
                    </h5>
                </DialogTitle>
                <DialogContent
                    id="uploadFile-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            duplicatesFound && duplicates !== null ?
                                this.renderDuplicates()
                            :
                            uploading ?
                                this.renderUploading()
                            :
                            success ?
                                this.renderSuccess()
                            :
                            <DragNDrop onDrop={(files) => this.onDrop(files)}>
                                { this.renderForm() }
                            </DragNDrop>
                        }
                </DialogContent>
                {
                    duplicateFileModal === true ? 
                        <DuplicateFileModal 
                            open={duplicateFileModal} 
                            onClose={() => this.onDuplicateFileModal(false)} 
                            successMessage={"The File/folder has already been added to the list to be uploaded!"}
                        />
                    :
                        <></>
                }
                {
                    groupMembersModal ? 
                        <GroupMembersModal
                            open={groupMembersModal} 
                            store={this.store} 
                            onClose={() => this.onGroupMembersModal(false)} 
                            onSuccess={(usersChecked) => this.onGroupMembersModalSuccess(usersChecked)}
                            sharedWith={sharedWith}
                            usersChecked={usersChecked}
                        />
                    :
                        <></>
                }      
            </Dialog>
        );
    }
};

UploadFileModal.defaultProps = {
    open: false,
    path: "",
    files: [],
    sharedFileId: null,
    ownerNotification: '',
    groupNotification: '',
    sharedWith: [],
    isShared: false
};
UploadFileModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
    path: PropTypes.string,
    files: PropTypes.array,
    ownerNotification: PropTypes.string,
    groupNotification: PropTypes.string,
    sharedWith: PropTypes.array,
    isShared: PropTypes.bool
};
export default withStyles(uploadFileModalStyle)(UploadFileModal);

import React from 'react';
import { Helmet } from 'react-helmet';
import Api from "../../../assets/js/utils/Api";
import axios from "axios";
import PasswordMatch from "../../../assets/js/utils/PasswordMatch";
import PropTypes from "prop-types";
import Auth from "../../../assets/js/utils/Auth";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Sidebar from "../../components/User/AccountSidebar";
import Button from "../../components/CustomButtons/Button";
import GridItem from "../../components/Grid/GridItem";
import LoaderComponent from '../../components/Loader'
import CustomInput from "../../components/CustomInput/CustomInput.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";

const Password = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;

        const { user } = this.store.getState();
        let values = Api.prepareMemberObject(user);
        this.state = {
            values: {
                ...values,
                password: ''
            },
            passwordConfirm: '',
            editInfo: false,
            savingInfo: false,
            showError: false,
            errorMessage: "",
            showPasswordErrors: false,
            requireLowerletter: false,
            requireUpperletter: false,
            requireNumber: false,
            requireSymbol: false,
            requireLength: false,
            validation: {
                password: '',
                passwordConfirm: '',
                isValid: false
            },
        }

        this.editUserInfo = this.editUserInfo.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.saveUserInfo = this.saveUserInfo.bind(this);
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
        }        
    }
    editUserInfo(editInfo = true){
        let state = {
            editInfo: editInfo
        }
        if(editInfo === false){
            state['values'] = {
                ...this.state.values,
                password: ''
            }
            state['passwordConfirm'] = '';
        }
        this.setState(state);
    }
    saveUserInfo(){
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }
        this.setState({
            editInfo: false, 
            savingInfo: true,
            showError: false
        });
        this.updatePassword();
    }
    validateForm(){
        const { values } = this.state;
        let validation = {
            password: "success",
            passwordConfirm: "success",
            isValid: true
        };
        
        const that = this;
        const check = PasswordMatch.check(values.password,function(requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength){
            that.setState({
                showPasswordErrors: true,
                requireLowerletter: requireLowerletter,
                requireUpperletter: requireUpperletter,
                requireNumber: requireNumber,
                requireSymbol: requireSymbol,
                requireLength: requireLength
            });
        });
        if(values.password.length <= 0 || check === false){
            validation.password = "error";
            validation.isValid = false;
        }
        if(this.state.passwordConfirm.length <= 0 || values.password !== this.state.passwordConfirm){
            validation.passwordConfirm = "error";
            validation.isValid = false;
        }

        this.setState({validation: validation});
        return validation.isValid;
    }
    updatePassword(){
        const { user } = this.store.getState();
        let values = Object.assign({}, this.state.values);

        const source = axios.CancelToken.source();
        Api.updatePassword(user.id, values, source).then((data) => {
            this.setState({
                savingInfo: false, 
            });
            let newUser = Api.prepareMemberObject(data.user);
            this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser}});
        }).catch(err => {
            this.setState({
                savingInfo: false, 
                showError: true, 
                errorMessage: err.message
            });
        });
    }
    handleValueChange(event, name) {
        this.setState({
            values: {
                ...this.state.values, 
                [name]: event.target.value
            }
        });
    }
    handleChange(event, name) {
        this.setState({
            [name]: event.target.value
        });
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<div key={key} className={"form-error-message passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </div>);
                key++;
            }
            return errorMessages;
        }
        return <div className={"form-error-message passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </div>;
    }
    saveButtonDisabled(){
        if(this.state.values.password !== '' && this.state.passwordConfirm !== ''){
            return false;
        }

        return true;
    }
    render() {
        const { classes } = this.props;
        const { showError, editInfo, savingInfo, validation, values, passwordConfirm, showPasswordErrors, requireLength, requireLowerletter, requireNumber, requireSymbol, requireUpperletter } = this.state;
        const { authorized } = this.store.getState();

        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        return (
            <div className={classes.main}>
                <div className={classes.container+" "+classes.profileContainer}>
                    <div className={classes.sidebar}>
                        <Sidebar store={this.store} />
                    </div>
                    <div className={classes.content}>
                        <GridItem className={classes.main} xs={12} sm={12} md={8} lg={6}>
                            <h3 className={classes.title}>Password</h3>
                            <div>
                                {
                                    editInfo === false && savingInfo === false ?
                                        <div className={"editButton"}>
                                            <Button color="custom" onClick={() => this.editUserInfo()}>Edit</Button>
                                        </div>
                                    :
                                    savingInfo ? <LoaderComponent color="custom" align="left" /> : <></>
                                }                    
                                {
                                    editInfo === true ?
                                        <div className={"saveButtons"}>
                                            <Button color="white" onClick={() => this.editUserInfo(false)}>
                                                Cancel
                                            </Button>
                                            <Button color="custom" disabled={this.saveButtonDisabled()}  onClick={() => this.saveUserInfo()}>
                                                Save
                                            </Button>
                                        </div>
                                    :
                                        <></>
                                }
                            </div>
                            <div className={"userInfo "+(editInfo === false?classes.viewInfo:'')}>
                                {
                                    showError ?
                                        this.renderErrorMessages()
                                    :
                                    <></>
                                }
                                <CustomInput
                                    success={validation.password === "success"}
                                    error={validation.password === "error"}
                                    id="outlined-password"
                                    labelText="Password"                                    
                                    inputProps={{
                                        value: values.password,
                                        onChange: (e) => this.handleValueChange(e, 'password'),
                                        name: "password",
                                        type: "password",
                                        disabled: !editInfo,
                                    }}                                    
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                                {
                                    showPasswordErrors ?
                                        <GridItem>
                                            <div>
                                                <div className={(requireLowerletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-lowerletter"}>
                                                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">{requireLowerletter? '\u2713' : '\u2716' }</span>
                                                    <span className="checkPasswordText-lowerletter">Password must contain a lower case letter</span>
                                                </div>
                                                <div className={(requireUpperletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-upperletter"}>
                                                    <span aria-hidden="true" className="validation-error-symbol check-upperletter">{requireUpperletter? '\u2713' : '\u2716' }</span>
                                                    <span className="checkPasswordText-upperletter">Password must contain an upper case letter</span>
                                                </div>
                                                <div className={(requireNumber?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-numbers"}>
                                                    <span aria-hidden="true" className="validation-error-symbol check-symbols">{requireNumber? '\u2713' : '\u2716' }</span>
                                                    <span className="checkPasswordText-symbols">Password must contain a number</span>
                                                </div>
                                                <div className={(requireSymbol?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-symbols"}>
                                                    <span aria-hidden="true" className="validation-error-symbol check-numbers">{requireSymbol? '\u2713' : '\u2716' }</span>
                                                    <span className="checkPasswordText-numbers">Password must contain a special character</span>
                                                </div>
                                                <div className={(requireLength?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-length"}>
                                                    <span aria-hidden="true" className="validation-error-symbol check-length">{requireLength? '\u2713' : '\u2716' }</span>
                                                    <span className="checkPasswordText-length">Password must contain at least 8 characters</span>
                                                </div>
                                            </div>
                                        </GridItem>
                                    :
                                    <></>
                                }
                                <CustomInput
                                    success={validation.passwordConfirm === "success"}
                                    error={validation.passwordConfirm === "error"}
                                    id="outlined-passwordConfirm"
                                    labelText="Confirm Password"                                    
                                    inputProps={{
                                        value: passwordConfirm,
                                        onChange: (e) => this.handleChange(e, 'passwordConfirm'),
                                        name: "passwordConfirm",
                                        type: "password",
                                        disabled: !editInfo
                                    }}                                    
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </div>
                        </GridItem>
                    </div>
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

Password.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userHomePageStyle)(Password);

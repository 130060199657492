import { whiteColor, grayColor, infoColor, successColor } from "../material-kit-pro-react.jsx";

import homePageStyle from "../material-kit-pro-react/views/homePageStyle.jsx";
import customCheckboxRadioSwitchStyle from "../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
const fileVersionModalStyle = theme => ({
    ...customCheckboxRadioSwitchStyle,
    ...homePageStyle(theme),
    modalRoot: {
        overflow: "auto",
        display: "block"
    },
    modal: {
        [theme.breakpoints.up("sm")]: {
            maxWidth: "550px",
            margin: "auto"
        },
        borderRadius: "6px",
        overflow: "visible",
        maxHeight: "unset",
        width: "60%",
        marginTop: "130px !important",
        backgroundColor: "rgba(0, 0, 0, 0.70)",
        boxShadow: "0px 11px 15px -7px rgba(255, 255, 255, 0.2), "+
                "0px 24px 38px 3px rgba(255, 255, 255, 0.14), "+
                "0px 9px 46px 8px rgba(255, 255, 255, 0.12)",
        [theme.breakpoints.down("sm")]: {
            marginTop: "50px !important",
            margin: theme.spacing(3)
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: "30px !important",
            margin: theme.spacing(2),
            width: "400px",
            maxWidth: "100%"
        }
    },
    modalHeader: {
        borderBottom: "none",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        minHeight: "0px"
    },
    modalTitle: {
        margin: "0",
        lineHeight: "1.5",
        textAlign: "center",
        color: whiteColor
    },
    modalCloseButton: {
        "&, &:hover": {
            color: whiteColor
        },
        "&:hover": {
            opacity: "1"
        },
        cursor: "pointer",
        padding: "1rem",
        margin: "-1rem -1rem -1rem auto",
        backgroundColor: "transparent",
        border: "0",
        WebkitAppearance: "none",
        float: "right",
        fontSize: "1.5rem",
        fontWeight: "500",
        lineHeight: "1",
        textShadow: "0 1px 0 " + whiteColor,
        opacity: ".5",
    },
    modalClose: {
        width: "16px",
        height: "16px"
    },
    modalBody: {
        paddingTop: "0px",
        paddingRight: "24px",
        paddingBottom: "16px",
        paddingLeft: "24px",
        position: "relative",
        overflow: "visible",
        textAlign: "center"
    },
    modalFooter: {
        padding: "15px",
        paddingTop: "0",
        margin: "0 auto"
    },
    modalFooterCenter: {
        marginLeft: "auto",
        marginRight: "auto"
    },
    instructionNoticeModal: {
        marginBottom: "25px"
    },
    imageNoticeModal: {
        maxWidth: "150px"
    },
    modalLarge: {
        [theme.breakpoints.up("md")]: {
            maxWidth: "800px"
        }
    },
    modalSmall: {
        [theme.breakpoints.up("sm")]: {
            width: "300px",
            margin: "auto"
        },
        margin: "0 auto"
    },
    modalSmallBody: {
        marginTop: "20px"
    },
    modalSmallFooterFirstButton: {
        margin: "0",
        paddingLeft: "16px",
        paddingRight: "16px",
        width: "auto"
    },
    modalSmallFooterSecondButton: {
        marginBottom: "0",
        marginLeft: "5px"
    },
    modalLogin: {
        maxWidth: "360px",
        overflowY: "visible",
        width: "100%",
        "& $modalCloseButton": {
            color: whiteColor,
            top: "-10px",
            right: "10px",
            textShadow: "none",
            position: "relative"
        },
        "& $modalHeader": {
            borderBottom: "none",
            paddingTop: "24px",
            paddingRight: "24px",
            paddingBottom: "0",
            paddingLeft: "24px"
        },
        "& $modalBody": {
            paddingBottom: "0",
            paddingTop: "0"
        },
        "& $modalFooter": {
            paddingBottom: "0",
            paddingTop: "0"
        }
    },
    modalLoginCard: {
        marginBottom: "0",
        margin: "0",
        "& $modalHeader": {
            paddingTop: "0"
        }
    },
    modalSignup: {
        maxWidth: "900px",
        width: "100%",
        "& $modalHeader": {
            paddingTop: "0"
        },
        "& $modalTitle": {
            textAlign: "center",
            width: "100%",
            marginTop: "0.625rem"
        },
        "& $modalBody": {
            paddingBottom: "0",
            paddingTop: "0"
        }
    },
    modalSignupCard: {
        padding: "40px 0",
        margin: "0"
    },
    modalCloseButtonCustom: {
        top: "16px !important",
        right: "16px !important",
        position: "absolute !important"
    },
    filesList: {
        listStyle: "none",
        margin: "0px",
        padding: "0px",
        color: whiteColor,
        textAlign: "left",
        fontSize: "14px",
        "& li": {
            padding: "5px 0px",
            marginTop: "5px",
            borderTop: "1px solid "+grayColor[4],
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
    },
    uncheckedIcon: {
        ...customCheckboxRadioSwitchStyle.uncheckedIcon,
        borderColor: whiteColor,
    },
    checkedIcon: {
        ...customCheckboxRadioSwitchStyle.checkedIcon,
        borderColor: "#2171C6",
        background: "#2171C6",
        fill: whiteColor
    },
    zipName: {
        "& input": {
            color: whiteColor
        },
        "& .MuiInput-underline": {
            border: "1px solid "+grayColor[2],
            paddingLeft: "10px",
            borderRadius: "3px",
            "&:before": {
                display: "none !important"
            },
            "&:after": {
                bottom: "-1px",
                borderBottomWidth: "1px"
            }
        }
    },
    zipPassword: {
        "& .MuiFormControl-root": {
            paddingTop: "0px"
        }
    },
    removeButtonBorder: {
        "& button": {
            border: "0px !important"
        }
    },
    inlineFields: {
        "& .MuiFormControl-root": {
            width: "calc(50% - 10px)",
            "&:first-child": {
                marginRight: "5px",
            },
            "&:first-child + div": {
                marginLeft: "5px"
            },
            "& .MuiTextField-root": {
                width: "100%"
            }
        }
    },
    inputAdornmentIcon: {
        color: whiteColor+" !important"
    },
    checkIcon: {
        color: successColor[0]
    },
    skipIcon: {
        color: infoColor[2]
    },
    description: {
        color: whiteColor,
        textAlign: "center",
        padding: "10px",
        marginBottom: "0px"
    },
    inputFile: {
        display: "none !important"
    },
    fileHeader: {
        marginBottom: "5px"
    },
    fileVersion: {
        marginRight: "10px"
    },
    fileDate: {
        marginRight: "10px",
        fontSize: "12px"
    },
    fileName: {
        color: grayColor[12]
    },
    fileOwner: {
        color: grayColor[12]
    },
    fileOptionsMenu: {
        "& .MuiSvgIcon-root": {
            marginRight: "5px",
            color: grayColor[0]
        }
    },
    files: {
        textAlign: "left"
    },
    infinityIcon: {
        maxWidth: '30px',
        width: '100%',
        marginRight: "15px"
    }
});

export default fileVersionModalStyle;

import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import Api from "../../../assets/js/utils/Api";
import axios from "axios";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import LoaderComponent from "../Loader";
import CustomInput from "../CustomInput/CustomInput";
import NavPills from "../NavPills/NavPills.jsx";
import ReactTable from "react-table";
import RefreshIcon from "@material-ui/icons/Refresh";
import MaterialIcon from "@mdi/react";
import { mdiTrashCanOutline } from '@mdi/js';
import ConfirmationModal from "./ConfirmationModal";
import moment from 'moment';
import Danger from "../Typography/Danger";

import storageModalStyle from "../../../assets/jss/user/storageModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";
const bytesPerGB = (1024*1024*1024);

const ManageStorageModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        const { user } = this.store.getState();
        let quota = (user.quota/bytesPerGB);

        this.state = {
            quota: quota,
            storage: "",
            rate: 0.04,
            loading: false,
            cancelToken: null,
            limit: 200,
            totalItemCount: 0,
            response: null,
            page: 1,
            loadingMore: false,
            totalPages: 1,
            unsubscribeModal: false,
            addon: null,
            deletedAddons: [],
            errorMessage: null
        };
    }
    componentDidMount(){
        this.getAddons();
    }
    getAddons(viewMore = false){
        let page = this.state.page;
        const source = axios.CancelToken.source();
        let requestData = {
            page: page,
            limit: 200
        };
        Api.getAddons(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages,
            });
            this.loadingFolder = false;
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            showError: false, 
            cancelToken: source,
            page: page,
        });
    }
    handleChange(event, name) {
        let storage = event.target.value;
        if(storage.length <= 0){
            storage = "";
        }
        if(storage.length > 0 && isNaN(parseInt(storage))){
            storage = "";
        }
        if(parseInt(storage) >= 10240){
            storage = "10240"
        }else if(parseInt(storage) <= 0){
            storage = "";
        }
        this.setState({
            [name]: storage
        });
    }
    onPayNow(){
        const { plan } = this.props;
        const { deletedAddons } = this.state;
        let storage = this.state.storage;
        if(storage.length <= 0){
            storage = "0";
        }
        storage = parseInt(storage);
        const source = axios.CancelToken.source();
        this.setState({
            loading: true,
            cancelToken: source,
            errorMessage: null
        });
        const deletedAddonIds = [];
        deletedAddons.map((addon) => {
            deletedAddonIds.push(addon.id);
            return null;
        });
        const requestData = {
            storage: (storage),
            accountType: plan,
            type: (deletedAddons.length > 0 ? "manage" : "increase"),
            deletedAddonIds: deletedAddonIds
        };
        Api.subscribeAddon(requestData, source).then(data => {
            window.location.href = data.checkout_url;
        }).catch(err => {
            this.setState({
                loading: false,
                errorMessage: err.message
            });
        });
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    getTrGroupProps(state, rowInfo){
        return {}
    }
    getTrProps(state, rowInfo){
        if (rowInfo && rowInfo.row) {
            // const { checked } = this.state;
            return {
                // onClick: (e) => this.handleToggle(rowInfo.original),
                // style: {
                //     background: checked.includes(rowInfo.original.id) ? 'rgba(0, 0, 0, 0.7)' : 'transparent',
                // }
            }
        }else{
            return {}
        }
    }
    getColumns(){        
        let columns = [
            {
                Header: "Storage",
                accessor: "storage",
                headerClassName: "hd_storage",
                className: "hd_storage td_storage",
            },
            {
                Header: "Price",
                accessor: "price",
                headerClassName: "hd_price",
                className: "hd_price td_price",
            },
            {
                Header: "Date Subscribed",
                accessor: "date",
                headerClassName: "hd_date",
                className: "hd_date td_date",
            },
            {
                Header: "Action",
                accessor: "actions",
                headerClassName: "hd_actions",
                className: "hd_actions td_actions",
                sortable: false,
                filterable: false,
            },
        ];
        return columns;
    }
    getTableData(){
        const { response } = this.state;
        if(response === null){
            return [];
        }
        let data = [];
        response.map(addon => {
            data.push({
                ...addon,
                storage: (addon.storage/(1024*1024*1024))+" GB",
                price: "$"+(addon.amount/100),
                date: moment(addon.payment_date).format("MM/DD/YYYY h:mm A"),
                actions: (
                    <Button color="danger" size="sm" justIcon title="Unsubscribe"  onClick={() => this.onUnsubscribeModal(true, addon)}>
                        <MaterialIcon path={mdiTrashCanOutline} className="MuiSvgIcon-root" />
                    </Button>
                )
            });
            return null;
        });
        return data;
    }
    onUpdateAddons(){
        const { deletedAddons } = this.state;
        let deletedStorage = 0;
        deletedAddons.map((addon) => {
            deletedStorage += addon.storage;
            return null;
        });
        if(deletedStorage <= 0){
            return;
        }
        this.setState({
            storage: ""
        }, () => {
            this.onPayNow();
        });
    }
    renderAddons(){
        const { classes } = this.props;
        const { loading, loadingMore, totalPages, page, deletedAddons, errorMessage } = this.state;
        const tableData = this.getTableData();
        return (
            <div>
                <ReactTable
                    columns={this.getColumns()}
                    data={tableData}
                    pageSizeOptions={[500]}
                    defaultPageSize={50000000000}
                    showPaginationTop={false}
                    minRows={0}
                    showPaginationBottom={false}
                    className={"-striped -highlight "+ (loading ? classes.loading : "")+" "+classes.filesTable}
                    getTrProps={(state, rowInfo) => this.getTrProps(state, rowInfo)}
                    getTrGroupProps={(state, rowInfo) => this.getTrGroupProps(state, rowInfo)}
                    getNoDataProps={() => this.getNoDataProps()}
                />
                <div className={classes.loadMore}>
                    {
                        tableData.length > 0 && loadingMore === false && page < totalPages ?
                            <Button color="custom" id="contentViewMore" round onClick={(e) => this.loadFiles(true)}>
                                <RefreshIcon className={classes.icons} />View More
                            </Button>
                            
                        :
                            loadingMore === true ?
                                <LoaderComponent color="custom" align="center" />
                            :
                            <></>
                    }
                </div>
                {
                    errorMessage !== null ?
                        <Danger className={classes.modalFooter +" " +classes.justifyContentCenter}>{errorMessage}</Danger>
                    :
                    null
                }
                <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                    
                    {
                        loading ?
                            <LoaderComponent color="custom" align="center" />
                        :
                        <>
                            <Button color="white" onClick={() => this.props.onClose()} round>
                                Close
                            </Button>
                            <Button color="custom" onClick={() => this.onUpdateAddons()} disabled={(deletedAddons.length === 0)} round>
                                Update Addons
                            </Button>
                        </>
                    }
                </DialogActions>
            </div>
        );
    }
    onUnsubscribeModal(status = false, addon = null){
        this.setState({
            unsubscribeModal: status,
            addon: addon
        });
    }
    onUnsubscribeModalSuccess(){
        const { response, deletedAddons, addon } = this.state;
        const addons = [];
        response.map(addonObj => {
            if(addonObj.id !== addon.id){
                addons.push(addonObj);
            }
            return null;
        });
        deletedAddons.push(addon);
        this.setState({
            unsubscribeModal: false,
            addon: null,
            deletedAddons: deletedAddons,
            response: addons
        });
    }
    render() {
        const { classes } = this.props;
        const { rate, loading, unsubscribeModal } = this.state;
        let storage = this.state.storage;
        if(storage.length <= 0){
            storage = "0";
        }
        const cost = (parseInt(storage)) * rate;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="manageStorageModal-modal-slide-title"
                aria-describedby="manageStorageModal-modal-slide-description"
            >
                <DialogTitle
                    id="manageStorageModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        <h4 className={classes.modalTitle+" "+classes.textCenter}>Manage Storage</h4> 
                </DialogTitle>
                <DialogContent
                    id="manageStorageModal-modal-slide-description"
                    className={classes.modalBody}
                    >
                        <NavPills
                            color="info"
                            tabs={[
                                {
                                    tabButton: "Manage Addons",
                                    tabContent: this.renderAddons()
                                },
                                {
                                    tabButton: "Increase Storage",
                                    tabContent: (
                                        <>
                                            <CustomInput 
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                labelText="Storage Space (GB)"
                                                inputProps={{
                                                    name: "storage",
                                                    id: "input-storage",
                                                    className: classes.numberInput,
                                                    type: "number",
                                                    value: this.state.storage,
                                                    onChange: (e) => this.handleChange(e, "storage"),
                                                    inputProps: {
                                                        step: "1",
                                                        min: "1",
                                                    }
                                                }}
                                            />
                                            <p>Estimated Cost: ${cost.toFixed(2)}</p>
                                            <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                                                {
                                                    loading ?
                                                        <LoaderComponent color="custom" align="center" />
                                                    :
                                                    <>
                                                        <Button color="white" onClick={() => this.props.onClose()} round>
                                                            Cancel
                                                        </Button>
                                                        <Button color="custom" onClick={() => this.onPayNow()} disabled={cost <= 0} round>
                                                            Pay Now
                                                        </Button>
                                                    </>
                                                }
                                            </DialogActions>
                                        </>
                                    )
                                },
                            ]}
                        />
                </DialogContent>
                {
                    unsubscribeModal ?
                        <ConfirmationModal
                            open={unsubscribeModal} 
                            onClose={() => this.onUnsubscribeModal()} 
                            onSuccess={() => this.onUnsubscribeModalSuccess()}
                            confirmationMessage="Are you sure you want to unsubscribe to this addon?"
                        />
                    :
                    null
                }
            </Dialog>
        )
    }
}

ManageStorageModal.defaultProps = {
    open: false,
    loadingMessage: "",
    successMessage: "",
    saving: false,
    errorMessage: null
}
ManageStorageModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    loadingMessage: PropTypes.string,
    saving: PropTypes.bool,
    errorMessage: PropTypes.string
}
export default withStyles(storageModalStyle)(ManageStorageModal);
import React from 'react'
import Header from "./Header/Header.jsx";
import HeaderLinks from "./Header/HeaderLinks.jsx";

class NavbarComponent extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        this.appState = this.store.getState();
        
        let color = this.props.color;
        if(this.props.isTransparentHeader === true){
            color = "transparent";
        }
        this.state = {
            open: false,
            color: color,
            isResponsive: this.props.isResponsive
        };

        this.updateDrawer = this.updateDrawer.bind(this);
    }
    componentWillReceiveProps(props){
        let color = props.color;
        if(props.isTransparentHeader === true){
            color = "transparent";
        }
        this.setState({
            color: color,
            isResponsive: props.isResponsive
        });
    }
    updateDrawer(open){
        this.setState({open: open})
    }
    
    render(){
        const { color, isResponsive } = this.state;
        const { authorized } = this.store.getState();
        return (
            <>
                <Header
                    brand={process.env.REACT_APP_TITLE}
                    isResponsive= {isResponsive}
                    links={
                        <HeaderLinks 
                            dropdownHoverColor="info" 
                            store={this.store} 
                            onClose={this.updateDrawer} 
                            desktop={true}
                            authorized={authorized}
                        />
                    }
                    mobileLinks={
                        <HeaderLinks 
                            dropdownHoverColor="info"
                            desktop={false}
                            store={this.store} 
                            onClose={this.updateDrawer}
                            authorized={authorized}
                        />
                    }
                    fixed
                    changeColorOnScroll={{
                        height: 100,
                        color: "info"
                    }}
                    color={color}
                    bottomBarColor="info"
                    store={this.store}
                    history={this.history}
                    open={this.state.open}
                    updateDrawer={this.updateDrawer}
                />               
            </>
        );
    }
}

export default NavbarComponent;
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Api from "../../assets/js/utils/Api";
import AuthApi from '../../assets/js/utils/Auth';

const CheckTransfers = class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            files: [],
            cancelToken: null,
        }
        this.store = this.props.store;
    }
    componentDidMount(){
        const hasAccessToken = AuthApi.hasAccessToken();
        if(hasAccessToken){
            this.checkTransfers();
        }
    }
    checkTransfers(){
        const that = this;
        Api.checkCancelledFiles().then(data => {
            if(data.status){
                that.setState({open: true, files: data.files});
            }
        }).catch(err => {
            //Silent
        });
    }
    onClose(){
        this.setState({open: false});
    }
    render() {
        const { open, files } = this.state;
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                onClose={() => this.onClose()}
                autoHideDuration={3000}
                message={<span>Upload was interrupted for {files} and could not complete.</span>}
            />
        )
    }
}

export default CheckTransfers;

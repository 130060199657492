import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// import Config from '../../../../Config';
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Link from '@material-ui/core/Link';
// import SettingsIcon from "@material-ui/icons/Settings";
// import FaceIcon from "@material-ui/icons/FaceRounded";
// import Layers from "@material-ui/icons/Layers";
import headerLinksStyle from "../../../assets/jss/material-kit-pro-react/components/headerLinksStyle.jsx";

function HeaderLinks({ ...props }) {
    function getDesktopMenu(){
        const { store } = props;
        const { authorized, user } = store.getState();
        
        let menuItems = [];
        if(!authorized){
            menuItems.push(<Link href="https://sendlinx.com/plans/" className={classes.dropdownLink} onClick={() => props.onClose(false)}>
                Signup
            </Link>);
            menuItems.push(<RouterLink to={"/auth/login"} className={classes.dropdownLink} onClick={() => props.onClose(false)}>
                Login
            </RouterLink>);
        }else if(authorized && user.level_id === 5){
            menuItems.push(<RouterLink to={"/user/plans"} className={classes.dropdownLink} onClick={() => props.onClose(false)}>
                Upgrade Plan
            </RouterLink>);
        }
        return menuItems;
    }
    const { classes, desktop } = props;
    if(desktop){
        return (
            <List className={classes.list + " " + classes.mlAuto + " " + classes.desktopMenu}>
                {
                    getDesktopMenu().map((menuItem, key) => {
                        return (
                            <ListItem className={classes.listItem} key={key}>
                                {menuItem}
                            </ListItem>
                        )
                    })
                }
            </List>
        );
    }
    return (
        <div className={classes.collapse}>
            <List className={classes.list + " " + classes.mlAuto}>
                {
                    getDesktopMenu().map((menuItem, key) => {
                        return (
                            <ListItem className={classes.listItem} key={key}>
                                {menuItem}
                            </ListItem>
                        )
                    })
                }
            </List>
        </div>
    );
}

HeaderLinks.defaultProps = {
    hoverColor: "primary",
    onClose: function(){ }
};

HeaderLinks.propTypes = {
    onClose: PropTypes.func,
    dropdownHoverColor: PropTypes.oneOf([
        "dark",
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "rose"
    ])
};

export default withStyles(headerLinksStyle)(HeaderLinks);

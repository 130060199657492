const searchInputStyle = theme => ({
    searchInput: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .search-input": {
            paddingTop: "10px"
        },
        "& .advanceSearchIcon": {
            cursor: "pointer"
        },
        "& input": {
            color: theme.customize.color
        },
        "& svg": {
            fill: theme.customize.color+' !important',
            marginRight: "0px !important"
        },
        "& button": {
            "&:hover": {
                backgroundColor: "transparent"
            }
        },
        "& .MuiInputLabel-root": {
            display: "none"
        }
    },
    searchIcon: {
        marginRight: "0px !important",
        paddingRight: "0px",
        "& svg": {
            // width: "30px !important",
            // height: "30px"
        }
    }
});

export default searchInputStyle;

import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Link from "@material-ui/core/Link";
import { 
    PlayArrowOutlined, 
    Close 
} from '@material-ui/icons';
import Button from "../../components/CustomButtons/Button.jsx";
import axios from 'axios';
import Config from "../../../../Config.js";
import LoaderComponent from '../Loader';

import firstTimeLoginModalStyle from "../../../assets/jss/user/firstTimeLoginModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const FirstTimeLoginModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;

        this.state = {
            loading: false,
            additionalVideoInfo: null,
        }
    }
    componentDidMount(){
        this.getAdditionalVideosThumbanil();
    }
    getUserTitle(user){
        let title = "";
        if(user.hasOwnProperty("first_name")){
            title += user.first_name;
        }
        if(user.hasOwnProperty("last_name")){
            title += " "+user.last_name;
        }
        return title;
    }
    getYoutubeVideoUrl(videoUrl){
        let url = videoUrl.replace(/(?:https:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g, 'https://www.youtube.com/embed/$1');
        return url;
    }
    getYoutubeVideoId(url){
        var regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        var match = url.match(regExp);
        return (match && match[1].length === 11)? match[1] : false;
    }
    getAdditionalVideosThumbanil() {
        let that = this;
        setTimeout(function(){
            var v = document.getElementsByClassName("youtube-player");
            for (var n = 0; n < v.length; n++) {
                var p = document.createElement("div");
                var id = v[n].getAttribute("data-id");

                p.innerHTML = that.createThumbail(id);                
                v[n].appendChild(p);
            }
        }, 200);
    }
    createThumbail(id) {
        return (
            '<img className="youtube-thumbnail" src="https://i3.ytimg.com/vi/' +id +'/hqdefault.jpg" alt="Youtube Preview">'
        );
    }
    getAdditionalVideoDetail(url){
        let that = this;
        let youtubeVideoId = this.getYoutubeVideoId(url);
        let youtubeApiKey = Config.getYoutubeDataApiKey();
        this.setState({
            loading: true
        })
        return new Promise(function(resolve, reject){
            axios({
                url: "https://www.googleapis.com/youtube/v3/videos?id="+youtubeVideoId+"&key="+youtubeApiKey+"&part=snippet",
                method: "GET",
            }).then(res => {
                if(res.status === 200){
                    if(res.data.items.length > 0){
                        let videoData = res.data.items[0].snippet;

                        that.setState({
                            loading: false,
                            additionalVideoInfo: {
                                'url': url,
                                'title': videoData.title,
                                'description': videoData.description
                            }
                        })
                    }else{
                        that.setState({
                            loading: false,
                        })

                        that.getAdditionalVideosThumbanil();
                    }
                }
            }).catch((err) => {
                that.setState({
                    loading: false,
                })

                that.getAdditionalVideosThumbanil();
            });
        });
    }
    closeAdditionalVideo(){
        this.setState({
            additionalVideoInfo: null
        })

        this.getAdditionalVideosThumbanil();
    }
    render() {
        const { classes } = this.props;
        const { user } = this.store.getState();
        const { firstTimeloginData } = this.props;
        const { additionalVideoInfo, loading } = this.state;

        return (
            <>
            {
                firstTimeloginData !== null ?
                    <Dialog
                        classes={{
                            root: classes.modalRoot,
                            paper: classes.modal
                        }}
                        open={this.props.open}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="firstTimeLogin-slide-title"
                        aria-describedby="firstTimeLogin-slide-description"
                    >
                        <DialogTitle
                                id="processingModal-modal-slide-title"
                                disableTypography
                                className={classes.modalHeader}
                        >
                            {
                                additionalVideoInfo !== null ?
                                    <Link onClick={() => this.closeAdditionalVideo()} color="primary" className={classes.skipLink+" "+classes.backLink}>Back</Link>
                                :
                                    <></>
                            }
                            <Button
                                simple
                                className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                                key="close"
                                aria-label="Close"
                                onClick={() => this.props.onClose()}
                            >
                                <Close className={classes.modalClose} />
                            </Button>
                            <h4 className={classes.modalTitle+" "+classes.textCenter}>Welcome { this.getUserTitle(user) }!</h4> 
                        </DialogTitle>
                        <DialogContent
                            id="firstTimeLogin-slide-description"
                            className={classes.modalBody}
                        >
                            {
                                loading ?
                                    <LoaderComponent color="info" align="center" />
                                : additionalVideoInfo !== null ?
                                    <div className="sd-video-iframe">
                                        <iframe src={ this.getYoutubeVideoUrl(additionalVideoInfo.url) } title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                        <div className="sd-video-info">
                                            <p className="sd-video-title">{additionalVideoInfo.title}</p>
                                            <p className="sd-video-description">{additionalVideoInfo.description}</p>
                                        </div>
                                    </div>
                                :
                                    <>
                                        <div className="sd-video-iframe">
                                            <p className="video-title">{ firstTimeloginData.title }</p>
                                            <iframe src={ this.getYoutubeVideoUrl(firstTimeloginData.video_url) } title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                        </div>
                                        {
                                            firstTimeloginData.hasOwnProperty("additional_videos") && firstTimeloginData.additional_videos.length > 0 ?
                                                <div className="popup-content">
                                                    <div className={"popup-section-listings "+(classes.justifyContentCenter)}>
                                                        {
                                                            firstTimeloginData.additional_videos.map((additionalVideo, key) => (
                                                                <div className="popup-listing-content" key={key}>
                                                                    <div className="sd-post-image-bg">
                                                                        {/* <iframe src={ this.getYoutubeVideoUrl(additionalVideo.video_url) } title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> */}
                                                                        <div onClick={() => this.getAdditionalVideoDetail(additionalVideo.video_url)}>
                                                                            <div className="play-icon">
                                                                                <PlayArrowOutlined />
                                                                            </div>
                                                                            <div className="youtube-player" data-id={this.getYoutubeVideoId(additionalVideo.video_url)}></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="sd-post-margin-content">
                                                                        <h2 className="bdp-post-title">
                                                                            <Link underline={"none"} onClick={() => this.getAdditionalVideoDetail(additionalVideo.video_url)}>
                                                                                {additionalVideo.title}
                                                                            </Link>
                                                                        </h2>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div> 
                                            :
                                                <></>
                                        } 
                                    </>
                            }   
                        </DialogContent>
                        <DialogActions
                            className={classes.modalFooter + " " + classes.justifyContentCenter}
                        >
                            <Link onClick={() => this.props.onClose()} color="primary" className={classes.skipLink}>Close</Link>
                        </DialogActions>        
                    </Dialog>
                :
                    <></>
            }
            </>
        );
    }
};

FirstTimeLoginModal.defaultProps = {
  open: false,
};
FirstTimeLoginModal.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
};
export default withStyles(firstTimeLoginModalStyle)(FirstTimeLoginModal);

import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Link from "@material-ui/core/Link";
import { Close } from '@material-ui/icons';
import Button from "../../components/CustomButtons/Button.jsx";

import newUpdateModalStyle from "../../../assets/jss/user/newUpdateModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const NewUpdateModal = class extends React.Component {
    getYoutubeVideoUrl(videoUrl){
        let url = videoUrl.replace(/(?:https:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g, 'https://www.youtube.com/embed/$1');
        return url;
    }
    render() {
        const { classes, newUpdateData } = this.props;

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="firstTimeLogin-slide-title"
                aria-describedby="firstTimeLogin-slide-description"
            >
                <DialogTitle
                        id="processingModal-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle+" "+classes.textCenter}> </h4> 
                </DialogTitle>
                <DialogContent
                    id="firstTimeLogin-slide-description"
                    className={classes.modalBody}
                >
                    <div className="sd-video-iframe">
                        <iframe src={ this.getYoutubeVideoUrl(newUpdateData.video_url) } title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        <p className="video-title">{ newUpdateData.title }</p>
                    </div>
                </DialogContent>
                <DialogActions
                    className={classes.modalFooter + " " + classes.justifyContentCenter}
                >
                    <Link onClick={() => this.props.onClose()} color="primary" className={classes.skipLink}>Close</Link>
                </DialogActions>        
            </Dialog>
        );
    }
};

NewUpdateModal.defaultProps = {
  open: false,
};
NewUpdateModal.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
};
export default withStyles(newUpdateModalStyle)(NewUpdateModal);

import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import CustomTabs from "../CustomTabs/CustomTabs.jsx";
import { helper } from "../../../assets/js/utils/Element";
import foldericon from "../../../assets/img/foldericon.png";
import sharedFolderIcon from "../../../assets/img/sharedFolderIcon.png";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import detailSectionStyle from "../../../assets/jss/user/detailSectionStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const DetailsSection = class extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            activeTab: 0
        }
    }
    getDetailsContent(){
        const { classes, file, modificationAllowed } = this.props;
        return( 
            <>
                <div className='thumbnail-icon'>
                    {
                        file.type === "folder" ? 
                            <Button justIcon color="transparent" className={classes.fileIcon}>
                                {
                                    file.hasOwnProperty("sharedWith") && file.sharedWith.length > 0 ?
                                        <img src={sharedFolderIcon} alt={"folder"} />
                                    :
                                        <img src={foldericon} alt={"folder"} />
                                }
                            </Button>
                        :
                            helper.getFileIcon(file, classes, modificationAllowed, true)
                    }
                </div>
                <div className='file-detail'>
                    <h4>File details</h4>
                    <p className='file-detail-heading'>Type</p>
                    <p>{file.type}</p>
                    <p className='file-detail-heading'>Size</p>
                    <p>{file.size}</p>
                    <p className='file-detail-heading'>Storage used</p>
                    <p>{file.size}</p>
                    <p className='file-detail-heading'>Location</p>
                    <p>{file.folder_path}</p>
                    <p className='file-detail-heading'>Owner</p>
                    <p>{file.owner}</p>
                    <p className='file-detail-heading'>Modified</p>
                    <p>{file.modified_date}</p>
                    <p className='file-detail-heading'>Created</p>
                    <p>{file.date}</p>
                </div>
            </>
        )
    }
    onTabChange(tab){
        this.setState({
            activeTab: tab
        });
    }
    render() {
        const { classes, file } = this.props;
        const { activeTab } = this.state;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="deleteFile-slide-title"
                aria-describedby="deleteFile-slide-description"
                className={classes.dialog}
            >
                <div classes={classes.detailsSection}>
                    <DialogTitle
                        disableTypography
                        className={classes.detailsHeader}
                        >
                            <div className='fileName'>{file.name}</div>
                            <Button
                                simple
                                className={classes.detailsCloseButton}
                                key="close"
                                aria-label="Close"
                                onClick={() => this.props.onClose()}
                            >
                                {" "}
                                <Close className={classes.modalClose} />
                            </Button>
                    </DialogTitle>
                    <DialogContent
                        className={classes.detailBody}
                    >
                        <div className={classes.customTabs}>
                            <CustomTabs
                                tabs={[
                                    { 
                                        tabName: "Details", 
                                        tabContent: (
                                            this.getDetailsContent()
                                        )
                                    }
                                ]}
                                activeTab={activeTab}
                                headerColor={"primary"}
                                onChange={(tab) => this.onTabChange(tab)}
                            />
                        </div>
                    </DialogContent>
                </div>
            </Dialog>
        )
    }
}

DetailsSection.defaultProps = {
    open: false,
}
DetailsSection.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
}
export default withStyles(detailSectionStyle)(DetailsSection);
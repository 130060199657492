import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
  
const changeExpiryDateModalStyle = theme => ({
    ...modalStyle(theme),
    textCenter: {
        textAlign: "center"
    },
    justifyContentCenter: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "center !important",
        alignSelf: "center !important",
        marginTop: "0px"
    },
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "400px !important"
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingBottom: "0px",
        paddingTop: "0px",
        "& .sd_loader": {
            width: "300px !important",
            height: "300px !important",
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
            "& > div": {
                width: "250px !important",
                height: "250px !important",
                [theme.breakpoints.down("xs")]: {
                    width: "150px !important",
                    height: "150px !important",
                },
            },
            "& svg": {
                "& circle": {
                    strokeWidth: "1px"
                }
            }
        },
        "& .viewPasswordIcon": {
            cursor: "pointer",
            marginTop: "-4px"
        },
        "& .datetime-element": {
            position: "relative",
            "& .clear-icon": {
                position: "absolute",
                margin: "0px",
                padding: "0px",
                width: "24px",
                height: "24px",
                minWidth: "auto",
                right: "0px",
                bottom: "22px",
            }
        },
        "& .MuiFormHelperText-root": {
            display: "none"
        },
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        marginTop: theme.spacing(2),
        justifyContent: "space-between !important",
        MsFlexPack: "space-between !important",
        WebkitBoxPack: "space-between !important",
    },
    modalCloseButtonCustom: {
        position: "absolute",
        top: "0px",
        right: "0px",
        margin: "0px",
        padding: "6px",
        "& svg": {
            marginRight: "0px"
        }
    }
});
  
export default changeExpiryDateModalStyle;
  
import React from 'react';
import LoaderComponent from '../components/Loader'
import AuthApi from '../../assets/js/utils/Auth'
import { Redirect } from 'react-router-dom'
import { createBrowserHistory } from 'history'
const history = createBrowserHistory();

const Logout = class extends React.Component {
    constructor(props) {
        super(props);
        this._mounted = true;
        this.store = this.props.store;
        this.state = this.store.getState();    
    }
    componentDidMount() {
        if(this.state.authorized){
            this.logout();
        }
    }
    componentWillUnmount(){
        this._mounted = false;
    }
    hasMounted(){
        return this._mounted;
    }
    logout(){
        history.push('/');
        AuthApi.logout(this);
        this.store.dispatch({type: 'LOGOUT', state: {notifications: null}});    
    }
    render() {
        const { authorized } = this.state;
        if(!authorized){
            return <Redirect to='/' />
        }
        return (
            <LoaderComponent />
        )
    }
}

export default Logout

/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import {
    grayColor,
    whiteColor,
    dangerColor,
    editor
  } from "../../material-kit-pro-react.jsx";

import scrollbarStyle from 'react-custom-scroll/dist/customScroll.css';
import circularProgressbarStyle from 'react-circular-progressbar/dist/styles.css';
import customCheckboxRadioSwitchStyle from "../../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";

const homePageStyle = theme => ({
    ...customCheckboxRadioSwitchStyle,
    ...scrollbarStyle,
    ...circularProgressbarStyle,
    transferForm: {
        "& h3": {
            color: theme.customize.pageTitleColor,
            marginBottom: "20px",
            width: "100%",
            textShadow: theme.customize.textShadow,
            marginTop: "0px"
        },
        position: "relative",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        zIndex: "2",
        borderRadius: "10px",
        height: "auto",
        left: "0px",
        right: "0px",
        marginRight: "auto",
        marginLeft: "auto",
        top: "8em",
        maxWidth: "32em",
        width: "100%",
        marginBottom: "50px",
        overflow: "hidden",
        "&.transferFormSuccess": {
            backgroundColor: theme.customize.opacityColor,
            border: theme.customize.border,
        },
        [theme.breakpoints.down("md")]: {
            "& h3":{
                marginBottom: "30px",
                fontSize: "24px"
            }
        },
        [theme.breakpoints.down("sm")]: {
            "& h3":{
                marginBottom: "20px",
                fontSize: "20px"
            }
        },
        [theme.breakpoints.down("xs")]: {
            top: "5em",
            width: "95%",
            marginBottom: "20px",
        }
    },
    fileSendingForm: {
        top: "5em",
        borderRadius: "0px",
        backgroundColor: "transparent",
        "& >div": {
            width: "100%",
            overflow: "hidden",
            clear: "both"
        },
        [theme.breakpoints.down("sm")]: {
            left: "0px",
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            paddingRight: "10px",
            paddingLeft: "10px",
        }
    },
    formFiles: {
        borderBottom: "none",
        position: "relative",
        padding: "0",
        width: "100%",
    },
    formFields: {
        "& > div": {
            marginBottom: "10px",
            overflow: "hidden",
            clear: "both",
            "& .MuiInput-multiline": {
                "&:before, &:after": {
                    content: "none"
                }
            }
        },
        "& .formElement": {
            width: "100%",
            padding: "0",
            position: "relative",
            "& p":{
                color: theme.customize.color,
                textAlign: "left",
                fontSize: "14px",
                margin: "0px",
                textShadow: theme.customize.textShadow,
            }
        },
        "& input, textarea": {
            backgroundColor: whiteColor,
            padding: "10px",
            boxSizing: "border-box",
            borderRadius: "3px",
            width: "100%",
            verticalAlign: "top",
            display: "inline-block",
            border: "none",
            float: "right"
        },
        "& textarea": {
            minHeight: "200px"
        },
        "& label": {
            color: theme.customize.color,
            maxWidth: "150px",
            width: "100%",
            textAlign: "right",
            fontSize: "18px",
            marginTop: "7px",
            display: "inline-block",
            textShadow: theme.customize.textShadow,
        },
        "& .claer-icon": {
            position: "absolute",
            top: "5px",
            right: "15px",
            fill: dangerColor[0]
        },
        [theme.breakpoints.down("sm")]: {
            "& label":{
                maxWidth: "100%",
                textAlign: "left",
                display: "block",
                padding: "0",
                fontSize: "16px",
                marginTop: "0px"
            },
            "& textarea": {
                minHeight: "135px",
            },
            "& .formElement": {
                width: "100%",
                float: "none"
            }
        },
    },
    homepageFormFields: {
        "& > div": {
            marginBottom: "10px",
            overflow: "hidden",
            clear: "both",
            "& .MuiInput-multiline": {
                "&:before, &:after": {
                    content: "none"
                }
            }
        },
        "& .formElement": {
            width: "100%",
            padding: "0",
            position: "relative",
            "& p":{
                color: theme.customize.color,
                textAlign: "left",
                fontSize: "14px",
                margin: "0px",
                textShadow: theme.customize.textShadow,
            },
            "& input.Mui-disabled": {
                backgroundColor: grayColor[11]
            }
        },
        "& input, textarea": {
            backgroundColor: whiteColor,
            padding: "10px",
            boxSizing: "border-box",
            borderRadius: "3px",
            width: "100%",
            verticalAlign: "top",
            display: "inline-block",
            border: theme.customize.border,
            float: "right"
        },
        "& textarea": {
            minHeight: "200px"
        },
        "& label": {
            top: "-14px",
            left: "10px",
            zIndex: "1",
            cursor: "text",
            "&.MuiFormLabel-filled, &.Mui-focused": {
                display: "none"
            },
        },
        "& .claer-icon": {
            position: "absolute",
            top: "5px",
            right: "15px",
            fill: dangerColor[0]
        },
        "& .MuiInput-root": {
            "&:before": {
                content: "none"
            },
            "&:after": {
                content: "none"
            }
        },
        "& .MuiFormControl-root": {
            margin: '0px',
            padding: '0px',
            "& svg": {
                bottom: "8px",
                right: "5px",
            }
        },
        "& .dateElement": {
            "& .MuiInput-formControl" :{
                marginTop: "0px"
            },
        },
        "& .labelElement": {
            "& .MuiFormControlLabel-root": {
                width: '100%'
            },
            "& .MuiFormControlLabel-label": {
                color: whiteColor
            },
            "& .MuiCheckbox-root": {
                paddingTop: "0px",
                paddingBottom: "0px",
                paddingRight: "5px",
                paddingLeft: "12px",
            }
        },
        "& .formElemens-inline": {
            display: 'flex',
            justifyContent: 'space-between',
            "& .dateElement": {
                width: "90%",
                marginLeft: "10px"
            }
        },
        [theme.breakpoints.down("sm")]: {
            "& textarea": {
                minHeight: "135px",
            },
            "& .formElement": {
                width: "100%",
                float: "none"
            },
        },
        [theme.breakpoints.down("xs")]: {
            "& .formElemens-inline": {
                display: 'block',
                "& div.dateElement": {
                    width: "100%",
                    marginLeft: "0px",
                    marginTop: "10px",
                }
            },
        }
    },
    transferOptions: {
        display: "none",
        background: "#f7f9fa",
        borderTop: "1px solid "+grayColor[17],
        padding: "0 10px",
        "&.active": {
            display: "block"
        },
        "& .transfer-option": {
            borderBottom: "1px solid "+grayColor[17],
            cursor: "default",
            padding: "0.55em 0 0.45em",
            position: "relative",
            textAlign: "left",
            "&:last-of-type": {
                borderBottom: "none"
            }
        },
        "& .transfer-plus-features": {
            "& label": {
                color: "#484a4d",
                display: "block",
                fontSize: "0.75em",
                margin: "0",
                width: "10.1666666667em"
            },
            "& .transfer-option-placeholder": {
                color: "#17181a",
                cursor: "pointer",
                display: "inline",
                fontSize: "0.875em"
            },
            "& .transfer-option-disabled": {
                "& label,& .transfer-option-placeholder": {
                    color: "rgba(23,24,26,0.2)"
                }
            }
        }
    },
    transferFooter: {
        position: "relative",
        padding: "0",
        borderTop: "1px solid "+grayColor[17],
        height: "4.5em",
        "& Button": {
            "& svg":{
                cursor: "pointer",
                height: "1.5em",
                marginRight: "0.2em",
                width: "1.5em",
                fill: theme.customize.color,
                fontSize: "inherit",
                transform: "rotate(-45deg)",
                display: "inline-block",
                verticalAlign: 'top',
                marginTop: "-2px",
                "&:hover": {
                    fill: theme.customize.color,
                },
                "&.sendicon": {
                    transform: "none",
                    "&.disabled": {
                        opacity: "0.6"
                    }
                }
            },
            display: "block",
            fontSize: "1em",
            margin: "12px auto",
            padding: "10px 30px",
            textTransform: "capitalize"
        }
    },
    fileSendingFormFooter: {
        textAlign: "left",
        width: "100% !Important",
        height: "auto",
        backgroundColor: theme.customize.opacityColor,
        border: theme.customize.border,
        "&.has-files": {
            borderBottom: "none"
        },
        "& img": {
            width: "1.5em",
            marginTop: "-5px",
            marginRight: "0.2em",
        },
        "& Button": {
            borderTop: "0px solid "+grayColor[17],
            textAlign: "left",
            width: "100%",
            backgroundColor: "transparent !important",
            padding: "10px 8px",
            boxShadow: "none !Important",
            margin: "0px",
            color: theme.customize.color+" !important",
            "& svg": {
                fill: theme.customize.iconsColor,
            },
            "&$sendButton": {
                backgroundColor: theme.customize.iconsColor+" !important",
            }
        },
    },
    sendButton: {
        maxWidth: "120px",
        backgroundColor: theme.customize.iconsColor+" !important",
        borderRadius: "0px 0px 5px 5px",
        textAlign: "center !important",
        "& svg": {
            fill: theme.customize.color+" !important",
        }
    },
    homeFileSendingForm: {
        display: "flex",
        justifyContent: "space-between",
        borderTop: "1px solid "+grayColor[17],
        "& .labelElement": {
            padding: "10px 8px", 
            "& .MuiFormControlLabel-root": {
                marginLeft: "0px",
                marginRight: "0px",
                display: "flex",
            },
            "& .MuiCheckbox-root": {
                padding: "0px"
            },
            "& .MuiFormControlLabel-label": {
                color: theme.customize.color
            }
        },
        "& .lockElement": {
            "& button": {
                "& svg": {
                    transform: "rotate(0deg)",
                    marginTop: "-2px",
                    marginRight: "0px",
                    fill: theme.customize.iconsColor
                }
            }
        },
        "& Button": {
            borderTop: "none"
        }
    },
    zipPassword: {
        backgroundColor: theme.customize.opacityColor,
        border: theme.customize.border,
        borderTop: "none",
        borderBottom: "none",
        padding: "0px 8px",
        "& .MuiFormControl-root": {
            paddingTop: "0px"
        },
        "& .MuiInputLabel-root": {
            top: "0px",
            color: theme.customize.color+" !important",
        },
        "& .MuiInput-formControl": {
            marginTop: "16px",
        },
        "& .MuiInput-input": {
            color: theme.customize.color
        },
        "& .viewPasswordIcon": {
            cursor: "pointer",
            marginTop: "-4px",
            "& svg": {
                fill: theme.customize.iconsColor
            }
        },
    },
    disableFooter: {
        borderRadius: "0px 0px 5px 5px"
    },
    transferFormLink: {
        "& .add-files-button": {
            minHeight: "15.8em",
            paddingTop: "7.1875em",
        },
        "& .add-files-icon": {
            top: "7.125em"
        },
        "& .add-file-title": {
            top: "5.4545454545em"
        },
        "& .select-folder": {
            top: "10.1538461538em"
        },
        "& .uploader-list": {
            minHeight: "16.2em"
        },
        [theme.breakpoints.down("xs")]: {
            "& .add-files-button": {
                minHeight: "13.5em",
                paddingTop: "5.5em"
            },
            "& .add-files-icon": {
                top: "5.8em"
            },
            "& .add-file-title": {
                top: "4.5em"
            },
            "& .select-folder": {
                top: "8.5em"
            },
        }
    },
    tagsInput: {
        textAlign: "left",
        marginBottom: "5px",
        width: "100%",
        padding: "5px",
        backgroundColor: theme.customize.opacityColor,
        borderRadius: "5px",
        border: theme.customize.border,
        "& .react-tagsinput-tag a::before": {
            color: theme.customize.iconsColor,
        },
        "& .users-icon": {
            float: "left",
            "& img": {
                maxWidth: "20px",
                width: "100%"
            },
            "& svg": {
                width: "20px",
                display: "block",
                color: theme.customize.iconsColor,
                fill: theme.customize.iconsColor
            }
        },
        "& .users-lists": {
            paddingLeft: "20px"
        },
        "& span": {
            color: theme.customize.color,
            textTransform: "none",
            backgroundColor: "transparent !Important",
            borderRight: "1px solid "+theme.customize.borderColor,
            borderRadius: "0px",
            lineHeight: "normal",
            paddingTop: "0px",
            paddingBottom: "0px",
            "& a": {
                top: "1px"
            }
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            float: "none"
        },
    },
    uploadingProgress: {
        backgroundColor: theme.customize.opacityColor,
        border:  theme.customize.border,
        "& .transfer_loader": {
            maxWidth: "170px",
            margin: "3em auto 1.3em",
            "& span": {
                color: "#3e98c7"
            }
        },
        "& h2": {
            margin: "0 0 0.3em",
            padding: "0",
            fontSize: "1.375em",
            lineHeight: "1.2",
            color: theme.customize.color
        },
        "& p": {
            fontSize: "0.875em",
            lineHeight: "1.2857142857em",
            margin: "0 0 5px",
            padding: "0",
            color: theme.customize.color,
            "&.orangeColor": {
                color: theme.customize.linksColor
            }
        },
    },
    uploaderCompleteContent: {
        padding: "0px 20px",
        marginBottom: "20px",
        "& img": {
            width: "10.625rem",
            margin: "1.875em auto 0px",
            [theme.breakpoints.down("xs")]: {
                width: "9rem"
            },
        },
        "& h2": {
            fontSize: "1.375em",
            lineHeight: "1.2",
            margin: "0 0 0.1818181818em",
            padding: "0",
            color: theme.customize.color
        },
        "& p": {
            fontSize: "0.875em",
            lineHeight: "1.2857142857em",
            margin: "0",
            padding: "0",
            textAlign: "center",
            whiteSpace: "pre-wrap",
            color: theme.customize.color
        },
        "& .success-image": {
            marginBottom: "10px"
        }
        // "& button": {
        //     padding: "0px",
        //     margin: "0 0 0.9285714286em",
        //     fontSize: "1em",
        //     color: grayColor[0],
        //     textTransform: "inherit",
        //     textDecoration: "underline",
        //     "&:hover, &:active, &:focus": {
        //         color: grayColor[0],
        //         textDecoration: "underline",
        //     }
        // }
    },
    uploadingContent: {
        marginBottom: "20px"
    },
    downloadLink: {
        "& a": {
            wordBreak: "break-all",
            fontSize: "13px",
            color: theme.customize.linksColor
        },
        marginBottom: "5px",
        marginTop: "5px",
        lineHeight: "18px"
    },
    editor: {
        ...editor(theme)
    },
    uploaderList: {
        position: "relative",
        width: "100% !Important",        
        borderRadius: "0px 0px 5px 5px",
        border: theme.customize.border,
        borderTop: "none",
        "& .uploader-add-files-count": {
            color: theme.customize.color,
            display: "block",
            textAlign: "right",
            fontSize: "14px",
            lineHeight: "normal",
            marginBottom: "0px",
            backgroundColor: theme.customize.opacityColor,
            paddingTop: "5px",
            paddingBottom: "5px",
            "& span": {
                color: theme.customize.linksColor,
                borderLeft: "1px solid "+theme.customize.borderColor,
                marginLeft: "6px",
                paddingLeft: "5px",
                verticalAlign: "top",
                maxWidth: "118px",
                width: "100%",
                display: "inline-block",
                textAlign: "left",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
            }
        },
    },
    filesList: {
        listStyle: "none",
        margin: "0",
        padding: "0",
        "& .file": {
            backgroundColor: theme.customize.opacityColor,
            cursor: "default",
            margin: "0",
            outline: "0",
            padding: "10px 8px",
            position: "relative",
            textAlign: "left",
            "&:hover": {
                backgroundColor: theme.customize.hoverColor,
            }
        },
        "& .file-header": {
            position: "relative"
        },
        "& .file-title": {
            fontWeight: "400",
            color: theme.customize.color,
            display: "block",
            fontSize: "14px",
            lineHeight: "normal",
            margin: "0",
            overflow: "hidden",
            padding: "0",
            "& .file-name": {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                width: "calc(100% - 150px)",
                display: "inline-block",
                verticalAlign: "middle",
            },
            "& .icon":{
                maxWidth: "18px",
                width: "100%",
                marginRight: "5px",
                textAlign: "center",
                display: "inline-block",
                "& img": {
                    verticalAlign: "bottom",
                    width: "100%",
                },
                "&.file-icon": {
                    "& img": {
                        maxWidth: "13px"
                    }
                },
            }
        },
        "& .file-detail":{
            float: "right",
            borderLeft: "1px solid "+theme.customize.borderColor,
            paddingLeft: "5px",
            maxWidth: "110px",
            width: "100%",
            "& svg": {
                width: "9px",
                height: "7px"
            },
            "& .folder-detail": {
                marginLeft: "0.25em"
            }
        },
        "& .filelist-action-delete": { 
            cursor: "pointer",
            position: "absolute",
            right: "-8px",
            top: "-4px",
            width: "auto",
            display: "none",
            "& img": {
                width: "28px",
            },
            "& svg": {
                width: "28px",
                color: theme.customize.iconsColor,
                fill: theme.customize.iconsColor
            },
            [theme.breakpoints.down("xs")]: {
                display: "block"
            },
        },
        "& .file:hover": {
            "& .filelist-action-delete": { 
                display: "block"
            }
        },
    },
    transferTypeLink: {
        marginBottom: "25px",
        "& button": {
            color: theme.customize.color+" !important",
            fontSize: "16px",
            textTransform: "none",
            padding: "0px",
            margin: "0px",
            textShadow: theme.customize.textShadow,
            "& img": {
                maxWidth: "28px",
                marginRight: "8px"
            },
            "& svg": {
                width: "28px",
                height: "28px",
                marginRight: "8px",
                color: theme.customize.iconsColor,
                fill: theme.customize.iconsColor
            }
        }
    },
    hidden: {
        display: "none !important"
    },
    hiddenLabel: {
        position: "absolute",
        fontSize: "0px"
    },
    uncheckedIcon: {
        ...customCheckboxRadioSwitchStyle.uncheckedIcon,
        borderColor: theme.customize.color,
    },
    checkedIcon: {
        ...customCheckboxRadioSwitchStyle.checkedIcon,
        borderColor: "#2171C6",
        background: "#2171C6",
        fill: whiteColor
    },
    fromEmail: {
        "& .MuiInput-root:before": {
            content: "'From: '",
            display: "block",
            position: "absolute",
            left: "0px",
            top: "0px",
            bottom: "0px",
            width: "50px",
            height: "19px",
            marginTop: "auto",
            marginBottom: "auto",
            color: grayColor[13],
            fontSize: "14px",
            borderBottom: "0px !important"
        },
        "& input": {
            paddingLeft: "50px"
        }
    },
    zipCheckbox: {
        display: "flex"
    },
    storageExceedMessage: {
        backgroundColor: theme.customize.opacityColor,
        color: theme.customize.color,
        textAlign: "left",
        padding: "10px",
        borderRight: theme.customize.border,
        borderLeft: theme.customize.border
    }
});

export default homePageStyle;

import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import SuccessPageStyle from "../../assets/jss/successPageStyle.jsx";
import Button from "../components/CustomButtons/Button.jsx"
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import MaterialIcon from "@mdi/react";
import { mdiFolder } from '@mdi/js';

const Success = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;

        this.state = {
            
        };
    }  
    render() {
        const { classes } = this.props;
        return (
            <div>
                <div className={classes.panelContent}>
                    <div className={classes.detailProgressUp}>
                        <div class="panel-container">
                            <h2>Manage your transfers easily with Sendlinx Pro</h2>
                            <GridContainer>
                                <GridItem xs={12} sm={8} className={classes.textLeft}>
                                    <div classname="plus-lists">
                                        <ul>
                                            <li>See when your files were downloaded</li>
                                            <li>See who downloaded your files</li>
                                            <li>Review and resend your old transfers</li>
                                        </ul>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={4} className={classes.textLeft}>
                                    <Button type="button" className="button" round>Tell me more</Button>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>
                    <h1>Your transfer details</h1>
                    <span className="panel-subtitle">
                        <div class="metadata">
                            <span>1 file</span>
                            <span>1.2 KB</span>
                            <span>Will be deleted in 1 week</span>
                        </div>
                    </span>
                    <GridContainer className={classes.transferDetail}>
                        <GridItem xs={12} sm={5} className={classes.textLeft}>
                            <div class="recipients">
                                <h2 class="recipients-title">Sending to</h2>
                                <ul class="recipients-list">
                                    <li>
                                        <p class="recipient-email">leanprogrammers@outlook.com</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="message">
                                <h2 class="message-title">Message</h2>
                                <p class="message-content">test</p>
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={2}>
                        </GridItem>
                        <GridItem xs={12} sm={5} className={classes.textLeft}>
                            <div class="details-filelist">
                                <h2 class="details-subtitle">2 files</h2>
                                <ul className={classes.filesList}>
                                    <li>
                                        <div class="file">
                                            <div class="file-header">
                                                <div class="poptip">
                                                    <h6 class="file-title">image.jpg</h6>
                                                </div>
                                                <div class="file-details">
                                                    <span class="file-detail">1 KB</span>
                                                    <span class="file-detail1">jpg</span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="file">
                                            <div className="file-header">
                                                <div className="poptip">
                                                    <h6 className="file-title">Folder Name</h6>
                                                </div>
                                                <div className="file-details">
                                                    <span className="file-detail">
                                                        <MaterialIcon path={mdiFolder} className="MuiSvgIcon-root"/>
                                                        <span className="folder-detail">Folder</span>
                                                    </span>
                                                    <span className="filedetail1">1 item</span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

Success.propTypes = {
    classes: PropTypes.object
};

export default withStyles(SuccessPageStyle)(Success);

import {
    whiteColor,
    dangerColor
} from "../material-kit-pro-react.jsx";

const userSubscriptionPageStyle = theme => ({
    main: {
        marginTop: "0px"
    },
    container: {
        width: "100%",
        zIndex: "2",
        top: "62px",
        position: "relative",
        paddingTop: "20px",
        height: "calc(100vh - 62px)"
    },
    title: {
        color: whiteColor,
        marginBottom: "20px",
        width: "100%",
        textShadow: "2px 2px 2px #000",
        marginTop: "0px"
    },
    center: {
        display: "block",
        textAlign: "center"
    },
    danger: {
        display: "block",
        textAlign: "center",
        color: dangerColor[0]
    }
});

export default userSubscriptionPageStyle;

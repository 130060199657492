import React from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import ReactTable from "react-table";
import CsvFileIcon from "../../../assets/img/csv-file-icon.png";
import linkTableViewStyle from "../../../assets/jss/linkTableViewStyle";
import Api from "../../../assets/js/utils/Api";
import Button from "../../components/CustomButtons/Button";
import AttachmentsModal from  "../../components/User/AttachmentsModal";

class UserRecentActivity extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;

        let userId = null;
        if(this.props.row.original.user_id){
            userId = this.props.row.original.user_id;
        }
        this.state = {
            row: this.props.row,
            userId: userId,
            checkedFileIds: this.props.checkFileIds,
            attachmentsModal: false,
            attachmentFiles: []
        }
    }
    componentWillReceiveProps(props){
        let userId = null;
        if(props.row.original.user_id){
            userId = props.row.original.user_id;
        }
        this.setState({
            row: props.row,
            userId: userId,
            checkedFileIds: props.checkFileIds
        });
    }
    onAttachmentsModal(status = false, files = []){
        this.setState({
            attachmentsModal: status,
            attachmentFiles: files
        })
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    getTableData(row){
        if(row === null){
            return [];
        }
        let tableData = [];
        row.original.recentActivities.map(activity => {
            let activityArray = {
                date_time: activity.created_at,
                name: (
                    <p title={activity.file_name}>
                        { activity.file_name }
                    </p>
                ),
            };
            if(activity.action === 'Move' || activity.action === 'Copy'){
                activityArray.action = (
                    <p title={activity.action+' to '+activity.path}>
                        { activity.action+' to '+activity.path }
                    </p>
                );
            }else if(activity.action === 'Updated Document(s)'){
                activityArray.action = (
                    <p title={activity.action}>
                        { activity.action } <Button color="custom" simple onClick={() => this.onAttachmentsModal(true, activity.files)}>view More</Button>
                    </p>
                );
            }else{
                activityArray.action = (
                    <p title={activity.action}>
                        { activity.action }
                    </p>
                );
            }
            
            tableData.push(activityArray);
            return null;
        });

        return tableData;
    }
    render(){
        const { classes } = this.props;
        const { row, userId, checkedFileIds, attachmentsModal, attachmentFiles } = this.state;
        let tableData = this.getTableData(row);
        
        const apiUrl = Api.getUsersActivityExportUrl(userId, checkedFileIds);

        return (
            <div className={classes.UserRecentActivitySection}>
                <p>Recent Activities (Last 5)</p>
                <ReactTable
                    columns={[
                        {
                            Header: "Date/Time",
                            accessor: "date_time",
                            headerClassName: "hd_date_time",
                            className: "hd_date_time td_date_time",
                        },
                        {
                            Header: "Action",
                            accessor: "action",
                            headerClassName: "hd_action",
                            className: "hd_action td_action",
                        },
                        {
                            Header: "Files Affected",
                            accessor: "name",
                            headerClassName: "hd_name",
                            className: "hd_name td_name",
                        },
                    ]}
                    data={tableData}
                    pageSizeOptions={[500]}
                    showPaginationTop={false}
                    minRows={0}
                    showPaginationBottom={false}
                    className={"-striped -highlight "+classes.filesTable+" "+classes.UserRecentActivity}
                    getNoDataProps={() => this.getNoDataProps()}
                    sortable={false}
                />
                {
                    tableData.length > 0 ?
                        <div className="csv-file-icon">
                            <a target="_blank" rel="noopener noreferrer" href={apiUrl} className={classes.csvFileIcon}>
                                <img src={CsvFileIcon} alt={CsvFileIcon} />
                            </a>
                        </div>
                    :
                        <></>
                }
                {
                    attachmentsModal ?
                        <AttachmentsModal 
                            open={attachmentsModal} 
                            onClose={() => this.onAttachmentsModal(false)}
                            attachmentFiles={attachmentFiles}
                            title={"Folder Files"}
                        />
                    :
                        <></>
                }
            </div>
        )
    }
}

UserRecentActivity.propTypes = {
    classes: PropTypes.object
};

export default (withStyles(linkTableViewStyle)(UserRecentActivity));
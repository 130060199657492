import Cookie from './Cookie';
import Config from '../../../../Config';
import axios from 'axios';

const Auth = {
    hasAccessToken: function(){
        if(Cookie.read("oauth_token") && Cookie.read("oauth_token").length > 0){
            return true;
        }
        return false;
    },
    validateToken(){
        const that = this;
        return new Promise(function(resolve, reject){
            if(!that.hasAccessToken()){
                return false;
            }
            that.getUserInfo().then(data => {
                return resolve(data);
            }).catch(err => {
                return resolve(false);
            });
        });
    },
    getUserInfo(){
        const that = this;
        return new Promise(function(resolve, reject){
            let userInfo = null;
            if(localStorage.getItem("sendlinx_userinfo") !== null){
                userInfo = JSON.parse(localStorage.getItem("sendlinx_userinfo"));
                resolve(userInfo);
            }
            let headers = Config.getApiHeaders();
            axios({
                url: Config.getApiUrl()+"user/me",
                method: "GET",
                headers: headers
            }).then(res => {
                if(res.data.code === 200){
                    const body = res.data.body;
                    Cookie.write("expires_at", body.token_expires_at);
                    if(userInfo === null){
                        localStorage.setItem("sendlinx_userinfo", JSON.stringify(body));
                        resolve(body);
                    }else{
                        localStorage.setItem("sendlinx_userinfo", JSON.stringify(body));
                    }
                }else{
                    reject(res.data.body);
                }
            }).catch((err) => {
                if(typeof(err.response) === "object" && err.response.data.code !== 500){
                    that.logout();
                    window.location.href = "/";
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    isLoggedIn(){
        try{
            if(!this.hasAccessToken()){
                return false;
            }

            return true;
        }catch(e){
            this.logout(); 
            return false; 
        }
    },
    logout: function(){
        let headers = Config.getApiHeaders();
        Cookie.dispose("oauth_token");
        Cookie.dispose("oauth_secret");
        axios({
            url: Config.getApiUrl()+"user/logout",
            method: "POST",
            headers: headers
        }).then(res => {
            
        }).catch((err) => {
            
        });
        localStorage.removeItem("oauth-identity");
        localStorage.removeItem("sendlinx_userinfo");
        localStorage.removeItem("show_first_view_shared");
    },
};

export default Auth;
import React from 'react';
import gpdrStyle from '../../assets/jss/gpdrStyle.jsx';
import Cookie from '../../assets/js/utils/Cookie';
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../components/Grid/GridItem";
import GridContainer from "../components/Grid/GridContainer.jsx";
import Link from '@material-ui/core/Link';

const GpdrBanner = class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hideBanner: this.isBannerHidden(),
        };
        this.savePreferences = this.savePreferences.bind(this);
        this.agree = this.agree.bind(this);
    }


    savePreferences() {
        Cookie.options.duration = 365;
        Cookie.write("hide_cookies_banner", 1);
        Cookie.options.duration = false;
        this.setState({
            showPreferencePopup: false,
            hideBanner: true
        });
    }
    isBannerHidden() {
        var hideBanner = Cookie.read("hide_cookies_banner");
        if (hideBanner && hideBanner.length > 0) {
            return true;
        }
        return false;
    }
    agree() {
        Cookie.options.duration = 365;
        Cookie.write("hide_cookies_banner", 1);
        Cookie.options.duration = false;
        this.setState({
            showPreferencePopup: false,
            hideBanner: true
        });
    }
    render() {
        const { hideBanner } = this.state;
        const { classes } = this.props;
        if (hideBanner) {
            return <></>
        }
        if (navigator.userAgent.indexOf("Instagram") !== -1) {
            return <></>
        }

        return (
            <div className={classes.cookiesGdprbanner}>
                <div className={classes.userPrivacyBanner} id="footer_cookies_banner_wrapper">
                    <div className={classes.container + ' ' + classes.container2}>
                        <GridContainer className={classes.containerRow}>
                            <GridItem sm={6} className={classes.containerCol}>

                                <p className={classes.whiteColor + ' ' + classes.small}>
                                    This website uses cookies to ensure you get the best experience on our website. To learn more about our privacy policy <Link href="https://sendlinx.com/privacy-policy/" target="_blank" className={classes.anchorClick}>Click here</Link>
                                </p>

                            </GridItem>
                            <GridItem sm={3} className={classes.rightSide}>
                                <div >
                                    <span onClick={this.agree} id="cookie_banner_agree_button" className={classes.btnAgree + ' ' + classes.small}>I Agree</span>
                                </div>
                            </GridItem>
                        </GridContainer>

                    </div>
                </div>
            </div >
        );
    }
}
export default withStyles(gpdrStyle)(GpdrBanner);
import {
    grayColor,
    whiteColor,
    // infoColor,
    blackColor
} from "./material-kit-pro-react.jsx";

const successPageStyle = theme => ({
    panelContent: {
        paddingLeft: "5em",
        paddingRight: "5em",
        minHeight: "calc(100% - 4.0625em)",
        paddingBottom: '4em',
        paddingTop: "3em",
        position: "relative",
        "& h1": {
            fontSize: "2.5em",
            margin: "0",
            textAlign: "left",
        },
        "& .panel-subtitle": {
            textAlign: "left",
            display: "block",
            fontSize: "0.875em",
            marginTop: "0.25em",
            paddingBottom: "2.1875em",
            marginBottom: "2.1875em",
            borderBottom: ".0625em solid "+grayColor[17],
            "& .metadata": {
                height: "3.125em",
                display: "flex",
                alignItems: "center",
                "&>span:not(:last-child):after": {
                    content: '"\\00B7"',
                    display: "inline-block",
                    padding: "0 .375em",
                    textDecoration: "none",
                }
            }
        },
        [theme.breakpoints.down("md")]: {
            paddingLeft: "3em",
            paddingRight: "3em",
            "& h1": {
                fontSize: "2em",
            },
        },
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "2em",
            paddingRight: "2em",
            paddingTop: "5.5em"
        },
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "1em",
            paddingRight: "1em",
            "& h1": {
                fontSize: "1.5em",
            },
        },
    },
    detailProgressUp: {
        left: "-5em",
        position: "relative",
        width: "calc(100% + 10em)",
        marginBottom: ".3125rem",
        marginTop: "-2.8125rem",
        padding: "2.5rem",
        [theme.breakpoints.down("md")]: {
            paddingRight: "0px",
            paddingLeft: "0px",
            width: "100%",
            left: "0px"
        },
        "& .panel-container": {
            backgroundColor: grayColor[16],
            position: "relative",
            padding: "1.875rem 2.5rem",
            borderRadius: "5px",
            overflow: "hidden",
            backgroundImage: "linear-gradient(135deg, rgba(255,255,255,0.02) 0%, rgba(58,102,255,0.02) 100%)",
            boxShadow: "0 5px 10px 0 rgba(0,0,0,0.2)",
            [theme.breakpoints.down("md")]: {
                padding: "1.5rem",
            },
            [theme.breakpoints.down("xs")]: {
                padding: "1rem",
            },
            "& h2": {
                margin: "0",
                fontSize: "1.5rem",
                width: "100%",
                textAlign: "left",
                marginBottom: ".625rem",
            },
            "&::before, &::after": {
                content: '""',
                position: "absolute",
                zIndex: "0",
                borderRadius: "999px",
                borderWidth: "18px",
                borderStyle: "solid",
                width: "37.5rem",
                height: "37.5rem",
                right: "-10.625rem",
                top: "-27.6875rem",
                borderColor: grayColor[18],
                opacity: "0.07",
            },
            "&::before": {
                borderWidth: "14px",
                width: "23.75rem",
                height: "23.75rem",
                left: "-14rem",
                top: "-9.375rem",
                borderColor: blackColor,
                opacity: "0.04",
            },
            "& ul": {
                listStyle: "none",
                margin: "0",
                padding: "0",
                textAlign: "left",
                "& li": {
                    fontSize: "0.875em",
                    padding: "0em 0 0 1.4285714286em",
                    lineHeight: "2.3",
                    margin: "0",
                    background: "url(/assets/img/benefit-icon.svg) no-repeat left center",
                }
            },
            "& button": {
                float: "right",
                backgroundColor: grayColor[0],
                width: "10rem",
                color: whiteColor,
                cursor: "pointer",
                border: "0",
                borderRadius: "5px",
                fontSize: "0.875em",
                height: "3.5714285714em",
                padding: "0 1.2857142857em",
                textDecoration: "none",
                marginTop: "0px",
                position: "relative",
                zIndex: "1",
                "-webkit-transition": "border-color 0.2s cubic-bezier(0.77, 0, 0.175, 1),background-color 0.2s cubic-bezier(0.77, 0, 0.175, 1),color 0.2s cubic-bezier(0.77, 0, 0.175, 1)",
                transition: "border-color 0.2s cubic-bezier(0.77, 0, 0.175, 1),background-color 0.2s cubic-bezier(0.77, 0, 0.175, 1),color 0.2s cubic-bezier(0.77, 0, 0.175, 1)",
                [theme.breakpoints.down("xs")]: {
                    float: "none",
                    marginTop: "5px"
                },
            }
        }
    },
    transferDetail: {
        "& ul": {
            padding: ".125em 0 0 0",
            marginTop: "0",
            marginBottom: "1em",
            "& li": {
                lineHeight: "1.625em",
                listStyle: "none",
                marginBottom: ".625em",
                position: "relative",
                "& p": {
                    fontSize: ".875em",
                    lineHeight: "1.28571em",
                    maxWidth: "100%",
                    overflow: "hidden",
                    position: "relative",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                }
            }
        },
        "& .message": {
            marginTop: "2.1875em",
        },
        "& h2": {
            fontWeight: "500",
            fontSize: "1em",
            marginTop: "0.83em",
            marginBottom: ".9375em",
        }
    },
    filesList: {
        listStyle: "none",
        margin: "0",
        padding: "0",
        "& .file": {
            borderBottom: "1px solid #e8ebed",
            cursor: "default",
            margin: "0",
            outline: "0",
            padding: "0px 0px .57143em 0",
            position: "relative",
            textAlign: "left",
        },
        "& .file-header": {
            position: "relative"
        },
        "& .file-title": {
            fontWeight: "400",
            color: "#17181a",
            display: "block",
            fontSize: "0.875em",
            lineHeight: "1.4285714286em",
            margin: "0",
            overflow: "hidden",
            padding: "0",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            textTransform: "inherit"
        },
        "& .file-details": {
            fontWeight: "400",
            color: "#6a6d70",
            display: "block",
            fontSize: "0.75em",
            lineHeight: "1.1666666667em",
            margin: "0",
        },
        "& .file-detail":{
            "& svg": {
                width: "9px",
                height: "7px"
            },
            "&:after": {
                content: "'\\00B7'",
                display: "inline-block",
                padding: "0 0.375em",
                textDecoration: "none",
            },
            "& .folder-detail": {
                marginLeft: "0.25em"
            }
        },
    },
    textLeft: {
        textAlign: "left"
    }
});

export default successPageStyle;

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { hydrate, render } from 'react-dom';
import App from './app/views/App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import configureStore from './store';
import "./app/assets/scss/material-kit-pro-react.scss?v=1.7.0";
let store = configureStore();

const rootElement = document.getElementById('my-root');
function renderApp(){
    if (rootElement.hasChildNodes()) {
        hydrate(
            <Provider store={store}>
                <App store={store} />
            </Provider>,
            rootElement
        );
    } else {
        render(
            <Provider store={store}>
                <App store={store} />
            </Provider>,
            rootElement
        );
    }
}

store.subscribe(renderApp);
renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
    onUpdate: function(registration, serviceWorker){
        registration.update();
        serviceWorker.postMessage('SKIP_WAITING');
    },
    onSuccess: function(registration, serviceWorker){
        //console.log("Service Worker Installed");
    }
});

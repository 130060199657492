import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from "prop-types";
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import MaterialIcon from "@mdi/react";
import { mdiOpenInNew, mdiEmail, mdiFacebook, mdiTwitter } from '@mdi/js';
import Button from "../components/CustomButtons/Button.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import helpPageStyle from "../../assets/jss/helpPageStyle.jsx";

const Help = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;

        this.state = {
            
        };
    }  
    render() {
        const { classes } = this.props;
        return (
            <div>
                <div className={classes.panelContent}>
                    <GridContainer className={classes.heading}>
                        <GridItem xs={12} sm={6} className={classes.textLeft}>
                            <h1 className={classes.marginZero}>Quick Help</h1>
                        </GridItem>
                        <GridItem xs={12} sm={6}  className={classes.textRight+" "+classes.alignCenter}>
                            <a className="status none" href={"/"}>All Systems Operational</a>
                        </GridItem>
                    </GridContainer>
                    <form action="" method="GET" target="_blank" className={classes.helpForm}>
                        <GridContainer>
                            <GridItem xs={9} sm={10}>
                                <div className="textfield textfield-search">
                                    <input type="search" className="textfield-field" placeholder="What can we help you with?"/>
                                </div>
                            </GridItem>
                            <GridItem xs={3} sm={2} className={classes.noPaddingLeft}>
                                <Button color="custom" type="submit" className="button button-right button-search-help">Search</Button>
                            </GridItem>
                        </GridContainer>
                    </form>
                    <div className={classes.frequentQuestions}>
                        <GridContainer>
                            <GridItem xs={12} sm={6} className={classes.textLeft}>
                                <a href={"/"} className="question">
                                    <h3>How do I send a transfer?</h3>
                                    <p>Add your files by clicking the big + button, add the address you want to send it to, add your own email address and you’re good to go.</p>
                                    <MaterialIcon path={mdiOpenInNew} className="MuiSvgIcon-root" />
                                </a>
                            </GridItem>
                            <GridItem xs={12} sm={6} className={classes.textLeft}>
                                <a href={"/"} className="question">
                                    <h3>How do I download a transfer?</h3>
                                    <p>Easy! Click the download button in your email, select the files you want or click download all, we’ll do the rest.</p>
                                    <MaterialIcon path={mdiOpenInNew} className="MuiSvgIcon-root" />
                                </a>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={6} className={classes.textLeft}>
                                <a href={"/"} className="question">
                                    <h3>Why is my upload slow?</h3>
                                    <p>Plug-ins, extensions or company networks often cause this. Don’t worry - we’ve got some tips and tricks to get you up to speed.</p>
                                    <MaterialIcon path={mdiOpenInNew} className="MuiSvgIcon-root" />
                                </a>
                            </GridItem>
                            <GridItem xs={12} sm={6} className={classes.textLeft}>
                                <a href={"/"} className="question">
                                    <h3>Why doesn't the transfer start?</h3>
                                    <p>Sounds like something is interrupting your connection to us. Anti-virus software or firewalls are notorious for messing with our service.</p>
                                    <MaterialIcon path={mdiOpenInNew} className="MuiSvgIcon-root" />
                                </a>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={6} className={classes.textLeft}>
                                <a href={"/"} className="question">
                                    <h3>How do I log in to my Sendlinx account?</h3>
                                    <p>To access your Sendlinx account, click the “Log in” button in the top right corner. Enter your details and you’ll be on your way in no time.</p>
                                    <MaterialIcon path={mdiOpenInNew} className="MuiSvgIcon-root" />
                                </a>
                            </GridItem>
                            <GridItem xs={12} sm={6} className={classes.textLeft}>
                                <a href={"/"} className="question">
                                    <h3>Can I delete my upload?</h3>
                                    <p>Accidentally uploaded the secret nuclear launch codes? Happens to the best of us! But don’t panic, there’s still hope.</p>
                                    <MaterialIcon path={mdiOpenInNew} className="MuiSvgIcon-root" />
                                </a>
                            </GridItem>
                        </GridContainer>
                    </div>
                    <div className={classes.moreAnswers}>
                        <a href={"/"} className="button more-answers-button">More answers</a>
                    </div>
                    <div className={classes.socialLinksContent}>
                        <h4>Still haven’t found what you’re looking for?</h4>
                        <ul>
                            <li>
                                <a href="/"><MaterialIcon path={mdiEmail} className="MuiSvgIcon-root" /></a>
                            </li>
                            <li>
                                <a href="/"><MaterialIcon path={mdiTwitter} className="MuiSvgIcon-root" /></a>
                            </li>
                            <li>
                                <a href="/"><MaterialIcon path={mdiFacebook} className="MuiSvgIcon-root" /></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

Help.propTypes = {
    classes: PropTypes.object
};

export default withStyles(helpPageStyle)(Help);

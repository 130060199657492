import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import successModalStyle from "../../../assets/jss/user/successModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const NoPreviewModal = class extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="success-modal-slide-title"
                aria-describedby="success-modal-slide-description"
            >
                <DialogTitle
                    id="success-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        <h4 className={classes.modalTitle+" "+classes.textCenter}>Couldn't Preview the file. Please try downloading and opening it locally</h4> 
                </DialogTitle>
                <DialogContent id="success-modal-slide-description" className={classes.modalBody}>
                    
                </DialogContent>
                <DialogActions className={classes.modalFooter + " " + classes.justifyContentCenter}>
                    <Button color="custom" onClick={() => this.props.onDownload()}>
                        Download
                    </Button>
                    <Button onClick={() => this.props.onClose()} color="custom">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

NoPreviewModal.defaultProps = {
    open: false,
}
NoPreviewModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
}
export default withStyles(successModalStyle)(NoPreviewModal);
import { grayColor, whiteColor } from "../material-kit-pro-react.jsx";

const officeFileModalStyle = theme => ({
    modalRoot: {
        display: "block",
        overflow: "hidden"
    },
    modal: {
        [theme.breakpoints.up("sm")]: {
            maxWidth: "100%",
            margin: "auto"
        },
        borderRadius: "0px",
        overflow: "visible",
        maxHeight: "unset",
        width: "100%",
        marginTop: "0px !important",
        marginBottom: "0px !important",
    },
    modalHeader: {
        borderBottom: "none",
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        minHeight: "0px"
    },
    modalTitle: {
        margin: "0",
        marginTop: "20px",
        lineHeight: "1.5",
        textAlign: "center"
    },
    modalCloseButton: {
        "&, &:hover": {
        color: grayColor[0]
        },
        "&:hover": {
        opacity: "1"
        },
        cursor: "pointer",
        padding: "1rem",
        margin: "-1rem -1rem -1rem auto",
        backgroundColor: "transparent",
        border: "0",
        WebkitAppearance: "none",
        float: "right",
        fontSize: "1.5rem",
        fontWeight: "500",
        lineHeight: "1",
        textShadow: "0 1px 0 " + whiteColor,
        opacity: ".5"
    },
    modalClose: {
        width: "16px",
        height: "16px"
    },
    modalBody: {
        padding: "0px !important",
        position: "relative",
        overflow: "visible",
        textAlign: "center",
        "& .second-grid": {
            marginTop: "25px",
        },
        "& .popup-listing-content": {
            background: grayColor[16],
            paddingBottom: "15px",
            textAlign: "center",
        },
        "& .sd-post-image-bg": {
            "& img": {
                display: "inline-block",
                maxWidth: "100%",
            }
        },
        "& .sd-post-margin-content": {
            background: whiteColor,
            padding: "15px",
            maxWidth: "90%",
            margin: "-40px auto 0 auto",
            position: "relative",
            "& h2": {
                marginBottom: "15px",
                marginTop: "0px",
                fontSize: "24px",
                lineHeight: "normal",
                fontWeight: "400",
                color: "#515151"
            },
            "& .sd-post-short-content": {
                marginBottom: "15px",
                fontSize: "14px",
            },
            "& .sd-readmorebtn": {
                transition: "all 0.5s ease-out 0s",
                textDecoration: "none !important",
                color: grayColor[4],
                border: "2px solid "+grayColor[4],
                padding: "8px 15px",
                fontSize: "13px",
                display: "inline-block",
                boxSizing: "border-box",
                lineHeight: "normal !important",
                "&:hover": {
                    background: grayColor[4],
                    color: whiteColor,
                }
            }
        }
    },
    modalFooter: {
        padding: "15px",
        paddingTop: "0",
        margin: "0 auto"
    },
    modalFooterCenter: {
        marginLeft: "auto",
        marginRight: "auto"
    },
    instructionNoticeModal: {
        marginBottom: "25px"
    },
    imageNoticeModal: {
        maxWidth: "150px"
    },
    modalLarge: {
        [theme.breakpoints.up("md")]: {
        maxWidth: "800px"
        }
    },
    modalSmall: {
        [theme.breakpoints.up("sm")]: {
        width: "300px",
        margin: "auto"
        },
        margin: "0 auto"
    },
    modalSmallBody: {
        marginTop: "20px"
    },
    modalSmallFooterFirstButton: {
        margin: "0",
        paddingLeft: "16px",
        paddingRight: "16px",
        width: "auto"
    },
    modalSmallFooterSecondButton: {
        marginBottom: "0",
        marginLeft: "5px"
    },
    modalLogin: {
        maxWidth: "360px",
        overflowY: "visible",
        width: "100%",
        "& $modalCloseButton": {
        color: whiteColor,
        top: "-10px",
        right: "10px",
        textShadow: "none",
        position: "relative"
        },
        "& $modalHeader": {
        borderBottom: "none",
        paddingTop: "24px",
        paddingRight: "24px",
        paddingBottom: "0",
        paddingLeft: "24px"
        },
        "& $modalBody": {
        paddingBottom: "0",
        paddingTop: "0"
        },
        "& $modalFooter": {
        paddingBottom: "0",
        paddingTop: "0"
        }
    },
    modalLoginCard: {
        marginBottom: "0",
        margin: "0",
        "& $modalHeader": {
        paddingTop: "0"
        }
    },
    modalSignup: {
        maxWidth: "900px",
        width: "100%",
        "& $modalHeader": {
        paddingTop: "0"
        },
        "& $modalTitle": {
        textAlign: "center",
        width: "100%",
        marginTop: "0.625rem"
        },
        "& $modalBody": {
        paddingBottom: "0",
        paddingTop: "0"
        }
    },
    modalSignupCard: {
        padding: "40px 0",
        margin: "0"
    },
    modalCloseButtonCustom: {
        color: grayColor[1]+" !important",
        top: "16px !important",
        right: "16px !important",
        position: "absolute !important"
    },
    skipLink: {
        textDecoration: "none !important",
        cursor: "pointer"
    },
    officeForm: {
        display: "none"
    },
    officeIframeContainer: {
        width: "inherit",
        height: "inherit",
        position: "relative",
        "& iframe": {
            border: "none",
            height: "100%",
            width: "100%",
            position: "absolute",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px"
        }
    },
    loading: {
        position: "absolute",
        top: "0px",
        left: "0px",
        right: "0px",
        bottom: "0px",
        margin: "auto",
        "& .MuiCircularProgress-root": {
            width: "100px !important",
            height: "100px !important",
            "& svg": {
                width: "inherit",
                height: "inherit"
            }
        }
    },
    mainContainer: {
        height: "100%",
        width: "100%",
        backgroundColor: "#d8d8d8"
    },
    topContainer: {
        WebkitBoxAlign: "center",
        MsFlexAlign: "center",
        WebkitBoxDirection: "normal",
        WebkitBoxOrient: "horizontal",
        // display: "-webkit-box",
        // display: "-moz-box",
        // display: "-ms-flexbox",
        // display: "-webkit-flex",
        display: "flex",
        WebkitFlexDirection: "row",
        MsFlexDirection: "row",
        flexDirection: "row",
        WebkitAlignItems: "center",
        alignItems: "center",
        backgroundColor: whiteColor,
        height: "60px",
        position: "relative",
        width: "100%",
        WozBoxSizing: "border-box",
        boxSizing: "border-box",
        borderBottom: "1px solid #D9D9D9"
    },
    middleContainer: {
        backgroundColor: whiteColor,
        WebkitBoxDirection: "normal",
        WebkitBoxOrient: "vertical",
        // display: "-webkit-box",
        // display: "-moz-box",
        // display: "-ms-flexbox",
        // display: "-webkit-flex",
        display: "flex",
        height: "100%",
        WebkitFlexDirection: "column",
        MsFlexDirection: "column",
        flexDirection: "column",
        position: "relative",
        marginRight: "385px",
        overflowY: "auto",
        WebkitTransition: "margin linear .1s",
        WozTransition: "margin linear .1s",
        transition: "margin linear .1s",
        "@media screen and (max-width: 768px)": {
            marginRight: "0px !important"
        }
    },
    rightContainer: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        marginBottom: "40px",
        zIndex: "10",
        WebkitTransition: "all linear .1s",
        MozTransition: "all linear .1s",
        transition: "all linear .1s",
        width: "385px",
        maxWidth: "85vw",
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        backgroundColor: whiteColor,
        borderLeft: "1px solid #D9D9D9",
        padding: "40px 40px"
    },
    expandedMainContainer: {
        "& $rightContainer": {
            right: "-385px",
        },
        "& $middleContainer": {
            marginRight: "0px"
        }
    },
    backButton: {
        background: "transparent !important",
        color: theme.customize.buttonsColor,
        boxShadow: "none !important",
        marginLeft: "20px"
    },
    expandButton: {
        background: "transparent !important",
        color: theme.customize.buttonsColor,
        boxShadow: "none !important",
        marginRight: "20px"
    },
    basicFileDetails: {
        WebkitFlex: "1",
        MsFlex: "1",
        flex: "1",
        marginLeft: "15px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textAlign: "left",
        fontWeight: "bold"
    },
    fileDetails: {
        textAlign: "left",
        "& ul": {
            paddingLeft: "0px",
            listStyleType: "none",
            "& li": {
                fontSize: "13px",
                "& div:first-child": {
                    fontWeight: "bold"
                }
            }
        }
    },
    detailsTitle: {
        fontWeight: "bold",
        marginBottom: "10px",
        fontSize: "17px"
    },
    overlayContainer: {
        WebkitBoxDirection: "normal",
        WebkitBoxOrient: "vertical",
        display: "flex",
        height: "100%",
        WebkitFlexDirection: "column",
        MsFlexDirection: "column",
        flexDirection: "column",
        overflowY: "auto",
        WebkitTransition: "margin linear .1s",
        WozTransition: "margin linear .1s",
        transition: "margin linear .1s",
        zIndex: "1",
        position: "absolute",
        top: "0px",
        left: "0px",
        right: "0px",
        bottom: "0px",
        cursor: "pointer"
    },
    fileIcon: {
        height: "auto",
        width: "auto",
        padding: "0px 3px",
        minWidth: "0px",
        margin: "0px",
        marginRight: "5px",
        "& img": {
            width: "24px"
        },
        color: theme.customize.color,
        "& .star_filled": {
            color: theme.customize.sidebarMenuIconsColor
        },
        "& .privacy-icon": {
            width: "18px",
            marginLeft: "5px"
        }
    },
});

export default officeFileModalStyle;

import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import CustomTabs from "../../components/CustomTabs/CustomTabs.jsx";
import ReactTable from "react-table";


import groupMembersModalStyle from "../../../assets/jss/user/groupMembersModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const GroupMembersModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;

        this.state = {
            sharedWith: this.props.sharedWith,
            usersChecked: this.props.usersChecked,
            activeTab: this.props.tab
        };

    }
    handleToggle(user) {
        const { usersChecked } = this.state;
        const currentIndex = usersChecked.indexOf(user.id);
        const newChecked = [...usersChecked];
    
        if (currentIndex === -1) {
            newChecked.push(user.id);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            usersChecked: newChecked,
        });
    }
    handleCheckAll(){
        const { sharedWith, usersChecked } = this.state;

        let usersCheckedArray = [];
        if(usersChecked.length !== sharedWith.length){
            sharedWith.map(user => {
                usersCheckedArray.push(user.user_id);
                return null;
            });
        }

        this.setState({
            usersChecked: usersCheckedArray,
        });
    }
    getTrProps(state, rowInfo){
        if (rowInfo && rowInfo.row) {
            // const { checked } = this.state;
            return {
                // onClick: (e) => this.handleUserToggle(rowInfo.original),
            }
        }else{
            return {}
        }
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    onToggleAllUsers(e, type){
        const usersChecked = [];
        if(e.target.checked){
            const { sharedWith } = this.state;
            sharedWith.map((user, key) => {
                if(type === 'active'){
                    if(user.status !== 'active'){
                        return null;
                    }
                }else if(type === 'viewonly'){
                    if(user.status !== 'viewonly'){
                        return null;
                    }
                }
                
                usersChecked.push(user.user_id);
                return null;
            });
        }

        this.setState({
            usersChecked: usersChecked
        });
    }
    handleUserToggle(user) {
        if(this.loadingFolder){
            return;
        }
        const { usersChecked } = this.state;
        const currentIndex = usersChecked.indexOf(user.user_id);
        const newChecked = [...usersChecked];
    
        if (currentIndex === -1) {
            newChecked.push(user.user_id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            usersChecked: newChecked,
        });
    }
    getColumns(type){
        const { classes } = this.props;
        let columns = [
            {
                Header: (
                    <Checkbox
                        tabIndex={-1}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        onChange={(e) => this.onToggleAllUsers(e, type)}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                accessor: "check",
                sortable: false,
                filterable: false,
                headerClassName: "hd_check",
                className: "hd_check td_check",
            },
            {
                Header: "First Name",
                accessor: "firstName",
                headerClassName: "hd_name",
                className: "hd_name td_name",
            },
            {
                Header: "Last Name",
                accessor: "lastName",
                headerClassName: "hd_name",
                className: "hd_name td_name",
            },
            {
                Header: "Email Address",
                accessor: "email",
                headerClassName: "hd_email",
                className: "hd_email td_email",
            }
        ];

        return columns;
    }
    getTableData(type){
        const { classes } = this.props;
        const { sharedWith, usersChecked } = this.state;

        if(sharedWith === null || sharedWith.length === 0){
            return [];
        }
        let tableData = [];

        sharedWith.map(user => {
            if(type === 'active'){
                if(user.status !== 'active'){
                    return null;
                }
            }else if(type === 'viewonly'){
                if(user.status !== 'viewonly'){
                    return null;
                }
            }

            let userArray = {
                id: user.user_id,
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={usersChecked.includes(user.user_id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleUserToggle(user)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                firstName: user.first_name,
                lastName: user.last_name,
                email: user.email,
            };
            
            tableData.push(userArray);
            return null;
        });
        return tableData;
    }
    onTabChange(tab){
        this.setState({activeTab: tab});
    }
    getTableContent(type){
        const { classes } = this.props;

        return (
            <ReactTable
                columns={this.getColumns(type)}
                data={this.getTableData(type)}
                pageSizeOptions={[500]}
                defaultPageSize={50000000000}
                showPaginationTop={false}
                minRows={0}
                showPaginationBottom={false}
                className={"-striped -highlight "+classes.filesTable}
                getTrProps={(state, rowInfo) => this.getTrProps(state, rowInfo)}
                getNoDataProps={() => this.getNoDataProps()}
                sortable={false}
            />
        )
    }
    render() {
        const { usersChecked, activeTab } = this.state;

        const { classes } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="uploadFile-slide-title"
                aria-describedby="uploadFile-slide-description"
            >
                <DialogTitle
                    id="uploadFile-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <h5 className={classes.modalTitle}>
                        Select Group Members
                    </h5>
                </DialogTitle>
                <DialogContent
                    id="uploadFile-slide-description"
                    className={classes.modalBody}
                >
                    <div className={classes.customTabs}>
                        <CustomTabs
                            tabs={[
                                {
                                    tabName: "All",
                                    tabContent: this.getTableContent('all')
                                },
                                { 
                                    tabName: "Active", 
                                    tabContent: this.getTableContent('active')
                                },
                                { 
                                    tabName: "View Only",
                                    tabContent: this.getTableContent('viewonly')
                                }
                            ]}
                            headerColor="primary"
                            activeTab={activeTab}
                            onChange={(tab) => this.onTabChange(tab)}
                        />
                    </div>
                    <div className={classes.transferFooter}>
                        <Button color="custom" onClick={() => this.props.onSuccess(usersChecked)} type="button">
                            Submit
                        </Button>
                    </div>
                </DialogContent>      
            </Dialog>
        );
    }
};

GroupMembersModal.defaultProps = {
    open: false,
    path: "",
    sharedWith: []
};
GroupMembersModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
    sharedWith: PropTypes.array
};
export default withStyles(groupMembersModalStyle)(GroupMembersModal);

import {
    dangerColor,
    grayColor,
} from "../material-kit-pro-react.jsx";
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
  
const storageModalStyle = theme => ({
    ...modalStyle(theme),
    textCenter: {
        textAlign: "center"
    },
    justifyContentCenter: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "center !important",
        alignSelf: "center !important",
        textAlign: "center"
    },
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "600px !important"
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        padding: "0px 8px 0px 8px !important",
        "& .MuiTabs-flexContainer": {
            justifyContent: "center"
        }
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        marginTop: theme.spacing(2),
        justifyContent: "space-between !important",
        MsFlexPack: "space-between !important",
        WebkitBoxPack: "space-between !important",
    },
    errorMessage: {
        color: dangerColor[0]
    },
    numberInput: {
        
    },
    filesTable: {
        "& .rt-thead .rt-th": {
            fontSize: "12px",
            fontWeight: "bold",
            "&.hd_hide": {
                display: 'none'
            },
        },
        "& .rt-table": {
            "&:not(:hover)": {
                "&::-webkit-scrollbar": {
                    width: "15px",
                },
                "&::-webkit-scrollbar-track": {
                    backgroundColor: "rgba(0,0,0,0.3)",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(0,0,0,0.1)",
                    
                }
            }
        },
        "& .rt-tbody .rt-td, & .rt-tbody .rt-td a": {
            fontSize: "12px",
        },
        "& .rt-tbody .rt-td": {
            padding: "0px 5px",
            margin: "5px 0px",
            "&.td_hide": {
                display: "none"
            }
        },
        "& .hd_check": {
            width: "30px !important",
            textAlign: "center !important",
            flex: "none !important",
            marginRight: "10px !important",
            "& .MuiCheckbox-root": {
                paddingLeft: "0px",
                paddingRight: "0px"
            },
            "& .rt-resizer": {
                display: "none !important"
            },
        },
        "& .td_check": {
            "& .MuiButtonBase-root": {
                padding: "0px",
            }            
        },
        "& .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after": {
            color: grayColor[17],
            opacity: "1 !important"
        },
        "& .rt-td": {
            fontSize: "12px"
        },
        "& .rt-tr": {
            cursor: "pointer",
            alignItems: "center"
        },
        "& .rt-tbody": {
            overflow: "unset",
            minHeight: "200px",
            "& .rt-tr-group": {
                flexGrow: "0",
            }
        },
        [theme.breakpoints.down("xs")]: {
            padding: "10px"
        },
        "&.usersTable": {
            "& .left": {
                textAlign: "left"
            }
        },
    },
    loadMore: {
        marginTop: theme.spacing(2),
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
});
  
export default storageModalStyle;
  
import { blackColor } from "../material-kit-pro-react.jsx";
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";

const detailSectionStyle = theme => ({
    ...modalStyle(theme),
    dialog: {
        right: "0px !important",
        left: "auto !important",
        maxWidth: "320px",
        width: "100%",
        boxShadow: "5px 10px 15px #000",
        "& .MuiDialog-paper": {
            marginTop: "0px !important",
            borderRadius: "0px"
        },
        "& .MuiBackdrop-root": {
            left: "auto"
        }
    },
    textCenter: {
        textAlign: "center"
    },
    detailsCloseButton: {
        position: "absolute",
        top: "0px",
        right: "0px",
        margin: "0px",
        "& svg": {
            marginRight: "0px",
            color: blackColor,
            width: "24px",
            height: "24px"
        },
        padding: "5px 10px",
    },
    detailsHeader: {
        position: "relative",
        padding: "5px 10px",
        "& .fileName": {
            textAlign: "left",
            fontSize: "18px",
            paddingRight: "30px",
            wordBreak: "break-all"
        }
    },
    detailBody: {
        padding: "10px 0px 0px",
        "& .card": {
            margin: "0px",
            background: "transparent",
            boxShadow: "none",
            "& .card-header": {
                padding: "0px",
                margin: "0px",
                background: "transparent",
                boxShadow: "none",
                color: theme.customize.pageTitleColorModal,
                "& .MuiTab-root": {
                    width: "100%",
                    borderBottom: "2px solid",
                    borderColor: "transparent",
                    borderRadius: "0px",
                    "&.Mui-selected": {
                        borderColor: theme.customize.borderColorModal 
                    }
                }
            },
            "& .card-body": {
                padding: "0px",
                overflowY: "auto",
                height: "100%",
            },
            "& .file-detail": {
                textAlign: "left",
                borderTop: "1px solid",
                borderColor: theme.customize.borderColorModal,
                marginTop: "20px",
                padding: "20px 10px",
                "& h4": {
                    fontWeight: "500"
                },
                "& p": {
                    marginBottom: "0px",
                    "&.file-detail-heading": {
                        fontWeight: "500",
                        marginTop: "15px"
                    }
                }
            }
        },
        "& .thumbnail-icon": { 
            height: "160px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            padding: "0px 10px",
            marginTop: "10px",
            "& button": {
                margin: "0px",
                padding: "0px",
                cursor: "auto",
                width: "auto",
                height: "auto"
            },
            "& svg, & img": {
                width: "60px !important",
                height: "60px !important",
                objectFit: "contain"
            },
            "& img.thumbnail": {
                width: "100% !important",
                objectFit: "cover",
                height: "160px !important",
            }
        }
    },
    justifyContentCenter: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "center !important",
        alignSelf: "center !important",
        marginTop: "0px"
    },
    imagePreview: {
        width: "100%",
    }
});
  
export default detailSectionStyle;
  
import { dangerColor, successColor, primaryColor } from "../material-kit-pro-react.jsx";
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
  
const confirmationModalStyle = theme => ({
    ...modalStyle(theme),
    textCenter: {
        textAlign: "center"
    },
    justifyContentCenter: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "space-between !important",
        alignSelf: "center !important",
        width: "100%",
        maxWidth: "300px"
    },
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "400px !important"
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingBottom: "0px",
        paddingTop: "0px",
        "& ul": {
            fontSize: "14px",
            paddingLeft: "15px"
        },
        "& .sd_loader": {
            width: "300px !important",
            height: "300px !important",
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
            "& > div": {
                width: "250px !important",
                height: "250px !important",
                [theme.breakpoints.down("xs")]: {
                    width: "150px !important",
                    height: "150px !important",
                },
            },
            "& svg": {
                "& circle": {
                    strokeWidth: "1px"
                }
            }
        }
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        marginTop: theme.spacing(2),
        justifyContent: "space-between !important",
        MsFlexPack: "space-between !important",
        WebkitBoxPack: "space-between !important",
    },
    danger: {
        color: dangerColor[0],
        marginTop: "10px"
    },
    success: {
        color: successColor[0]
    },
    justifyCenter: {
        justifyContent: "center !important",
    },
    anchor: {
        color: primaryColor[0],
        cursor: "pointer",
        textAlign: "left",
        display: "inline-block"
    },
});
  
export default confirmationModalStyle;
  
/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import {
    grayColor,
    whiteColor,
    infoColor,
    blackColor,
    lightBlueColor,
    dangerColor
  } from "../../material-kit-pro-react.jsx";

import scrollbarStyle from 'react-custom-scroll/dist/customScroll.css';

const downloadPageStyle = theme => ({
    ...scrollbarStyle,
    transferForm: {
        position: "relative",
        backgroundColor: theme.customize.opacityColor,
        border: theme.customize.border,
        borderRadius: "10px",
        boxShadow: "0 0 12px 0 rgba(0,0,0,0.1), 0 10px 30px 0 rgba(0,0,0,0.2)",
        height: "auto",
        maxWidth: "38em",
        width: "100%",
        overflow: "hidden",
        "& .rcs-custom-scroll": {
            position: "relative",
        },
        "&.password-form": {
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            "& .downloader-heading": {
                borderBottom: "none",
                minHeight: "auto"
            },
            "& input": {
                color: theme.customize.color
            },
            "& .passwordCheck-notValid-customizable": {
                color: dangerColor[0]
            },
            "& .form-button": {
                fontSize: "1em",
                lineHeight: "30px",
                width: "9.75em",
                padding: "9px 30px",
                textTransform: "capitalize",
                margin: "0px",
                backgroundColor: infoColor[0]
            },
            "& label": {
                top: "10px"
            },
            "& .viewPasswordIcon": {
                cursor: "pointer",
                marginTop: "-4px"
            },
            "& .MuiFormControl-root": {
                "& >svg": {
                    right: "20px",
                    bottom: "7px",
                }
            }
        },
        [theme.breakpoints.down("sm")]: {
            maxWidth: "30em",
        },
        [theme.breakpoints.down("xs")]: {
            transform: "none",
            width: "95%"
        },
        "& a.closeIcon svg":  {
            width: "32px",
            height: "32px",
            top: "5px",
            right: "5px",
            position: "absolute",
            fill: infoColor[0],
            [theme.breakpoints.down("xs")]: {
                width: "1em",
                height: "1em",
            },
        },
        "& a.closeIcon": {
            fontSize: "0px"
        }
    },
    downloaderHeading: {
        minHeight: "17em",
        height: "auto",
        paddingTop: "1.875em",
        paddingBottom: "0.75em",
        borderBottom: "1px solid "+grayColor[17],
        [theme.breakpoints.down("xs")]: {
            minHeight: "15.7em",
        },
        "& .arrowIcon": {
            border: "4px solid "+grayColor[17],
            borderRadius: "100%",
            width: "90px",
            height: "90px",
            marginRight: "auto",
            marginLeft: "auto",
            marginBottom: "0.75em",
        },
        "& svg": {
            width: "50px",
            height: "50px",
            fill: grayColor[17],
            marginTop: "15px"
        },
        "& h2": {
            margin: "0 0 0.0625em",
            padding: "0 0.9090909091em",
            fontSize: "1.375em",
            lineHeight: "1.2",
            color: theme.customize.color
        },
        "& p": {
            fontSize: "0.8125em",
            lineHeight: "1.3846153846em",
            padding: "0 1.5384615385em",
            margin: "0px",
            color: theme.customize.color
        },
        "& .downloader-message": {
            padding: "11px 20px 10px",
            width: "100%",
            color: theme.customize.color,
            "& p": {
                fontSize: "0.875em",
                lineHeight: "1.42",
                margin: "0",
                padding: "0",
                textAlign: "left",
                color: theme.customize.color
            },
            "& ul, & ol": {
                textAlign: "left",
                color: theme.customize.color
            }
        }
    },
    filesList: {
        listStyle: "none",
        margin: "0",
        padding: "0",
        color: theme.customize.color,
        "& .file": {
            borderBottom: "1px solid #e8ebed",
            cursor: "default",
            margin: "0 0.625em",
            outline: "0",
            padding: "0.5em 0.625em",
            position: "relative",
            textAlign: "left",
            color: theme.customize.color,
            overflow: "hidden"
        },
        "& .file-header": {
            position: "relative",
            paddingRight: "40px"
        },
        "& .file-title": {
            fontWeight: "400",
            display: "block",
            fontSize: "0.875em",
            lineHeight: "1.4285714286em",
            margin: "0",
            overflow: "hidden",
            padding: "0",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            textTransform: "initial",
            color: theme.customize.color
        },
        "& .file-details": {
            fontWeight: "400",
            display: "block",
            fontSize: "0.75em",
            lineHeight: "1.1666666667em",
            margin: "0",
            color: theme.customize.color
        },
        "& .file-detail":{
            "& svg": {
                width: "9px",
                height: "7px"
            },
            "&:after": {
                content: "'\\00B7'",
                display: "inline-block",
                padding: "0 0.375em",
                textDecoration: "none",
            },
            "& .folder-detail": {
                marginLeft: "0.25em"
            }
        },
        "& .file:hover": {
            "& .filelist-action-delete": { 
                display: "block"
            }
        },
        "& .downloadIcon": {
            position: "absolute",
            right: "0px",
            top: "0px",
            bottom: "0px",
            margin: "auto",
            "& .MuiButton-label": {
                width: "auto"
            },
            "& button": {
                margin: "0px",
                marginTop: "2px"
            },
            "& svg": {
                fill: theme.customize.color
            }
        }
    },
    transferFooter: {
        height: "4.5em",
        padding: "0 1.25em",
        position: "relative",
        "&.footer-with-logo": {
            height: "5.5em", 
        },
        "& .transfer-button": {
            left: "50%",
            position: "absolute",
            top: "50%",
            "-webkit-transform": "translate(-50%, -50%)",
            transform: "translate(-50%, -50%)",
            display: "inline-block",
            fontSize: "1em",
            width: "9.75em",
            padding: "9px 30px",
            textTransform: "capitalize",
            margin: "0px",
        },
        "& a.transfer-button": {
            color: whiteColor,
            borderRadius: "30px",
            fontWeight: '400',
            backgroundColor: theme.customize.buttonsColor
        },
        "& .footer-logo": {
            maxWidth: "80px",
            width: "100%",
            position: "absolute",
            bottom: '3px',
            right: '5px'
        }
    },
    processingWarning: {
        color: theme.customize.color
    },
    errorHeading: {
        borderBottom: "0px !important"
    },
    businessLeagelHeader: {
        display: "flex",
        position: "fixed",
        width: "100%",
        zIndex: "1100",
        justifyContent: "space-between",
        padding: "0px 15px",
        alignItems: "center",
        "& img": {
            maxHeight: "60px",
            objectFit: "cover",
            objectPosition: "center",
            padding: "6px 8px"
        },
        "& p": {
            marginBottom: "0px",
            color: theme.customize.color
        }
    },
    backgroundSlider: {
        position: "fixed",
        top: "0px",
        width: "100%",
        height: "100%",
        "& .background-image": {
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "100%",
            height: "100%",
            display: "block"
        },
        "& .banner-text-content": {
            height: "auto !important",
            position: "absolute",
            bottom: "21px",
            right: "160px",
            color: theme.customize.color,
            "& .banner-text": {
                maxWidth: "500px",
                margin: "0px auto",
                "& p": {
                    fontSize: "20px",
                    lineHeight: "28px",
                    marginBottom: "0px",
                    textShadow: "2px 2px 2px "+blackColor,
                }
            },
            [theme.breakpoints.down("md")]: {
                "& div.banner-text": {
                    "& p": {
                        fontSize: "18px",
                        lineHeight: "24px"
                    }
                },
            },
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        }
    },
    aboutBusiness: {
        marginTop: "10px",
        color: theme.customize.color,
        "& a": {
            color: lightBlueColor
        }
    },
    downloadPageContent: {
        maxWidth: "1200px",
        position: "relative",
        zIndex: "2",
        left: "0px",
        right: "0px",
        marginRight: "auto",
        marginLeft: "auto",
        top: "8em",
        "& .leftAdColumn, & .rightAdColumn": {
            maxWidth: "160px",
            width: "100%",
        },
        "& .downloadTopColumns": {
            display: "flex",
            justifyContent: "space-around",
            alignItems: "flex-start",
            "& .banner-image": {
                width: "100%"
            }
        },
        "& .downloadBottomAd": {
            paddingTop: "30px",
            paddingBottom: "50px",
            "& .banner-image": {
                maxWidth: "728px",
                width: "100%"
            },
            "&.downloadHeaderAd": {
                paddingTop: "0px",
                paddingBottom: "30px"
            }
        },
        [theme.breakpoints.down("sm")]: {
            "& .downloadTopColumns": {
                display: "block",
                "& $transferForm": {
                    margin: "20px auto"
                }
            },
            "& .leftAdColumn, & .rightAdColumn": {
                margin: "0px auto"
            },
        },
    },
    iframeContent: {
        width: "100%",
        "& iframe": {
            width: "100%",
            minHeight: "600px"
        }
    }
});

export default downloadPageStyle;

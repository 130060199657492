/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import {
    whiteColor, blackColor,
  } from "../../material-kit-pro-react.jsx";


const backgroundSliderStyle = theme => ({
    BackgroundSlider: {
        position: "fixed",
        top: "0px",
        width: "100%",
        height: "100%",
        "& .slick-slider, .slick-slider div": {
            height: "100%",
        },
        "& .background-image": {
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "100%",
            height: "100%",
            display: "block"
        },
        "& .banner-text-content": {
            height: "auto !important",
            position: "absolute",
            bottom: "21px",
            right: "160px",
            color: whiteColor,
            "& .banner-text": {
                maxWidth: "500px",
                margin: "0px auto",
                "& p": {
                    fontSize: "20px",
                    lineHeight: "28px",
                    marginBottom: "0px",
                    textShadow: "2px 2px 2px "+blackColor,
                }
            },
            [theme.breakpoints.down("md")]: {
                "& div.banner-text": {
                    "& p": {
                        fontSize: "18px",
                        lineHeight: "24px"
                    }
                },
            },
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        }
    }
});

export default backgroundSliderStyle;

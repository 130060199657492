import {
    whiteColor,
    container,
    infoColor
} from "./material-kit-pro-react.jsx";


const gpdrStyle = theme => ({
    container: {
        ...container,
        position: "relative",
        padding: "0px",
        height: "100%",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            maxWidth: "none"
        }
    },
    containerRow: {
        alignItems: "center",
        marginTop: "8px",
        paddingTop: "0px",
        "@media only screen and (max-width: 319px)": {
            marginTop: "3px",
        }
    },
    containerCol: {
        color: whiteColor,
        display: "block",
        marginLeft: "16.6666666667%",
        "& p": {
            margin: "0px !important"
        },
        position: "relative",
        width: "100%",
        minHeight: "1px",
        paddingRight: "15px",
        paddingLeft: "15px",
        textAlign: "left",
        fontSize: "100%",
        marginRight: "0px !important",
        [theme.breakpoints.down("md")]: {
            marginLeft: "70px"
        },
        [theme.breakpoints.down("sm")]: {
            marginLeft: "20px",
            width: "auto",
            flexBasis: "80%",
            maxWidth: "80%"
        },
        [theme.breakpoints.down("sm")]: {
            marginLeft: "10px",
            flexBasis: "75%",
            maxWidth: "75%",
            paddingRight: "0px",
            paddingLeft: "0px",
        },
        "@media only screen and (max-width: 319px)": {
            marginLeft: "6px",
        }
    },
    rightSide: {
        justifyContent: "center",
        display: "flex",
        [theme.breakpoints.down("md")]: {
            display: "flex",
            maxWidth: "none",
            flexBasis: "unset",
            width: "auto"
        }
    },
    whiteColor: {
        color: whiteColor
    },
    small: {
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "18px",
        "@media only screen and (max-width: 319px)": {
            lineHeight: "16px",
        }
    },
    anchorClick: {
        fontWeight: "bold",
        color: whiteColor,
        "&:hover": {
            color: whiteColor,
            backgroundColor: "transparent"
        },
        "&:focus": {
            color: whiteColor,
            backgroundColor: "transparent"
        },
    },
    marginRight: {
        marginRight: "8px"
    },
    btn: {
        borderRadius: "30px",
        backgroundColor: "#866539",
        borderColor: "#866539",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#9f7f55",
            borderColor: "#9f7f55",
        },
        color: whiteColor,
        display: "flex",
        alignItems: "center",
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingTop: "8px",
        paddingBottom: "8px",
        "& span": {
            fontWeight: "bold",
            paddingLeft: "4px",
            whiteSpace: "nowrap"
        },
    },
    btnAgree: {
        borderRadius: "30px",
        backgroundColor: "#FFD819",
        borderColor: "#FFD819",
        fontSize: "12px",
        whiteSpace: "nowrap",
        "&:hover": {
            backgroundColor: "#e5be00",
            borderColor: "#d8b400",
        },
        color: "#212529",
        display: "flex",
        alignItems: "center",
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingTop: "8px",
        paddingBottom: "8px",
        fontWeight: "bold",
        cursor: "pointer",

    },
    userPrivacyBanner: {
        position: "fixed",
        width: "100%",
        display: "block",
        bottom: "0",
        left: "0",
        zIndex: "1500",
        backgroundColor: infoColor[0],
        height: "50px",
        "@media only screen and (max-width: 470px)": {
            height: "70px",
        }
    },

    "@media only screen and (max-width: 768px)": {
        rightSide: {
            justifyContent: "center",
            margin: "0 auto",
            padding: "0px"
        },
        btn: {
            paddingLeft: "16px",
            paddingRight: "16px"
        },
        btnAgree: {
            paddingLeft: "16px",
            paddingRight: "16px",
        },
    },
    "@media only screen and (max-width: 400px)": {
        btn: {
            paddingLeft: "10px",
            paddingRight: "10px"
        },
        btnAgree: {
            paddingLeft: "10px",
            paddingRight: "10px",
        },
    }
});

export default gpdrStyle;
/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { 
    whiteColor,
    infoColor,
    grayColor
} from "../../material-kit-pro-react";

const customFileUploadStyle = theme => ({
    inputFile: {
        opacity: "0",
        position: "absolute",
        top: "0",
        right: "0",
        bottom: "0",
        left: "0",
        width: "100%",
        height: "100%",
        zIndex: "-1"
    },
    inputFileWrapper: {
        "& button svg": {
            color: "inherit"
        }
    },
    addFilesButton: {
        outline: "0",
        paddingTop: "0",
        color: theme.customize.color,
        "& ul": {
            listStyle: "none",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 8px",
            marginBottom: "0px",
            backgroundColor: theme.customize.opacityColor,
            border: theme.customize.border,
            borderBottom: "none",
            borderRadius: "5px 5px 0px 0px",
        },
        "& img":{
            width: "1.7em",
            marginRight: "0.4em",
            verticalAlign: "bottom",
        },
        "& Button":{
            "& svg":{
                cursor: "pointer",
                height: "1.7em",
                marginRight: "0.2em",
                width: "1.7em",
                fill: theme.customize.iconsColor,
                color: theme.customize.iconsColor,
                fontSize: "inherit",
                verticalAlign: "bottom",
                "&:hover": {
                    fill: theme.customize.iconsColor,
                    color: theme.customize.iconsColor,
                }
            },
            cursor: "pointer",
            fontSize: "0.8125em",
            lineHeight: "normal",
            margin: "0",
            fontWeight: "400",
            padding: "0",
            textAlign: "left",
            color: theme.customize.color+" !Important",
            display: "inline-block",
            verticalAlign: "bottom",
            backgroundColor: "transparent !Important",
            boxShadow: "none !Important",
            textTransform: "capitalize"
        },
    },
    uploaderFooter: {
        bottom: "0",
        cursor: "default",
        height: "3.25em",
        left: "20px",
        position: "absolute",
        right: "20px",
        textAlign: "left",
        "& .uploader-add-more": {
            cursor: "pointer"
        },
        "& .uploader-add-files": {
            display: "inline-block",
            height: "3.25em",
            verticalAlign: "middle",
            width: "1.5em",
            fill: infoColor[0],
            fontSize: "inherit",
            transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover": {
            "& .uploader-add-files": {
                fill: infoColor[1]
            }
        },
        "& .uploader-add-files-content": {
            display: "inline-block",
            paddingLeft: "0.625em",
            position: "relative",
            top: "0.0625em",
            verticalAlign: "middle",
        },
        "& .uploader-add-files-label": {
            display: "block",
            color: "#6a6d70",
            fontSize: "0.8125em",
            lineHeight: "1.3em"
        },
        "& .uploader-add-files-count": {
            display: "block",
            color: "#6a6d70",
            fontSize: "0.75em",
            lineHeight: "1.4em"
        },
        "& .popup-files-selector": {
            background: whiteColor,
            borderRadius: "0.3125em",
            margin: "0",
            padding: "0.625em 0",
            position: "absolute",
            textAlign: "center",
            zIndex: "40",
            border: "0.0625em solid "+grayColor[0],
            minWidth: "7.8125em",
            width: "auto",
            bottom: "45px",
            display: "none",
            "&.active": {
                display: "block"
            },
            "& .popup-menu": {
                display: "flex",
                "-webkit-box-orient": "vertical",
                "-webkit-box-direction": "normal",
                "-ms-flex-direction": "column",
                "flex-direction": "column"
            },
            "& button": {
                fontSize: "0.875em",
                color: "#17181a",
                border: "0",
                background: "transparent",
                width: "100%",
                textAlign: "left",
                cursor: "pointer",
                padding: "0 0.875em",
                lineHeight: "2",
                outline: "none",
                "&:hover": {
                    background: infoColor[1],
                    color: whiteColor,
                }
            }
        }
    },
    mobile: {
        "& .select-folder": {
            display: "none"
        },
        "& .or-text": {
            display: "none"
        },
        "& .button-folder": {
            display: "none"
        }
    },
});

export default customFileUploadStyle;

import { blackColor } from "../material-dashboard-pro-react.jsx";
import { grayColor, whiteColor, infoColor, successColor } from "../material-kit-pro-react.jsx";

import customCheckboxRadioSwitchStyle from "../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
const restoreFileModalStyle = theme => ({
    ...customCheckboxRadioSwitchStyle,
    modalRoot: {
        overflow: "auto",
        display: "block"
    },
    modal: {
        [theme.breakpoints.up("sm")]: {
            maxWidth: "500px",
            margin: "auto"
        },
        borderRadius: "6px",
        overflow: "visible",
        maxHeight: "unset",
        width: "60%",
        marginTop: "130px !important",
        [theme.breakpoints.down("sm")]: {
            marginTop: "50px !important",
            margin: theme.spacing(3)
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: "30px !important",
            margin: theme.spacing(2),
            width: "400px",
            maxWidth: "100%"
        }
    },
    modalHeader: {
        borderBottom: "none",
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        minHeight: "0px"
    },
    modalTitle: {
        margin: "0",
        lineHeight: "1.5",
        textAlign: "center",
        marginTop: "20px",
    },
    modalCloseButton: {
        "&, &:hover": {
            color: grayColor[0]
        },
        "&:hover": {
            opacity: "1"
        },
        cursor: "pointer",
        padding: "1rem",
        margin: "-1rem -1rem -1rem auto",
        backgroundColor: "transparent",
        border: "0",
        WebkitAppearance: "none",
        float: "right",
        fontSize: "1.5rem",
        fontWeight: "500",
        lineHeight: "1",
        textShadow: "0 1px 0 " + whiteColor,
        opacity: ".5"
    },
    modalClose: {
        width: "16px",
        height: "16px"
    },
    modalBody: {
        paddingTop: "24px",
        paddingRight: "24px",
        paddingBottom: "16px",
        paddingLeft: "24px",
        position: "relative",
        overflow: "visible",
        textAlign: "left",
        "& >p": {
            marginBottom: "0px"
        },
        "& h6": {
            textTransform: "none"
        },
        "& .sd_loader": {
            width: "300px !important",
            height: "300px !important",
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
            "& > div": {
                width: "250px !important",
                height: "250px !important",
                [theme.breakpoints.down("xs")]: {
                    width: "150px !important",
                    height: "150px !important",
                },
            },
            "& svg": {
                "& circle": {
                    strokeWidth: "1px"
                }
            }
        }
    },
    modalFooter: {
        padding: "15px",
        paddingTop: "0",
        margin: "0 auto"
    },
    modalFooterCenter: {
        marginLeft: "auto",
        marginRight: "auto"
    },
    instructionNoticeModal: {
        marginBottom: "25px"
    },
    imageNoticeModal: {
        maxWidth: "150px"
    },
    modalLarge: {
        [theme.breakpoints.up("md")]: {
        maxWidth: "800px"
        }
    },
    modalSmall: {
        [theme.breakpoints.up("sm")]: {
            width: "300px",
            margin: "auto"
        },
        margin: "0 auto"
    },
    modalSmallBody: {
        marginTop: "20px"
    },
    modalSmallFooterFirstButton: {
        margin: "0",
        paddingLeft: "16px",
        paddingRight: "16px",
        width: "auto"
    },
    modalSmallFooterSecondButton: {
        marginBottom: "0",
        marginLeft: "5px"
    },
    modalLogin: {
        maxWidth: "360px",
        overflowY: "visible",
        width: "100%",
        "& $modalCloseButton": {
            color: whiteColor,
            top: "-10px",
            right: "10px",
            textShadow: "none",
            position: "relative"
        },
        "& $modalHeader": {
            borderBottom: "none",
            paddingTop: "24px",
            paddingRight: "24px",
            paddingBottom: "0",
            paddingLeft: "24px"
        },
        "& $modalBody": {
            paddingBottom: "0",
            paddingTop: "0"
        },
        "& $modalFooter": {
            paddingBottom: "0",
            paddingTop: "0"
        }
    },
    modalLoginCard: {
        marginBottom: "0",
        margin: "0",
        "& $modalHeader": {
            paddingTop: "0"
        }
    },
    modalSignup: {
        maxWidth: "900px",
        width: "100%",
        "& $modalHeader": {
            paddingTop: "0"
        },
        "& $modalTitle": {
            textAlign: "center",
            width: "100%",
            marginTop: "0.625rem"
        },
        "& $modalBody": {
            paddingBottom: "0",
            paddingTop: "0"
        }
    },
    modalSignupCard: {
        padding: "40px 0",
        margin: "0"
    },
    modalCloseButtonCustom: {
        color: grayColor[1]+" !important",
        top: "16px !important",
        right: "16px !important",
        position: "absolute !important"
    },
    modalDuplicates: {
        maxWidth: "600px",
        width: "100%",
    },
    duplicates: {
        "& ul": {
            listStyleType: "none",
            paddingLeft: "0px",
            textAlign: "left",
            "& li": {
                cursor: "pointer",
                padding: "8px 10px",
                display: "flex",
                alignItems: "center",
                transition: "all 0.3s ease",
                "& svg": {
                    marginRight: "5px"
                },
                "&.active": {
                    background: infoColor[0]
                },
                "&:hover:not(.active)": {
                    background: infoColor[8]
                }
            }
        }
    },
    checkIcon: {
        color: successColor[0]
    },
    skipIcon: {
        color: infoColor[2]
    },
    duplicateSelection: {
        color: blackColor,
        "& ul": {
            listStyleType: "none",
            paddingLeft: "0px",
            textAlign: "left",
            "& li": {
                cursor: "pointer",
                padding: "8px 0px",
                marginTop: "8px",
                borderTop: "1px solid "+grayColor[4]
            }
        }
    },
    selectiveCheckboxes: {
        display: "flex",
        "& $label": {
            whiteSpace: "nowrap",
            paddingLeft: "0px",
            color: blackColor
        },
        "& $checkRoot": {
            padding: "5px"
        },
        "& .MuiFormControlLabel-root": {
            marginLeft: "0px",
            width: "50%"
        }
    },
    duplicateFile: {
        display: "flex",
        "& $label": {
            paddingLeft: "0px",
            color: blackColor
        },
        "& $checkRoot": {
            padding: "5px"
        },
        "& .MuiFormControlLabel-root": {
            marginLeft: "0px",
            width: "50%"
        }
    },
    duplicateFileName: {
        fontSize: "14px",
        paddingLeft: "5px"
    },
    selectionButtons: {
        display: "flex",
        justifyContent: "flex-end",
        margin: "0px",
        "& button": {
            marginLeft: "10px"
        }
    },
    checkboxSection: {
        "& p": {
            marginTop: "10px",
            marginBottom: "0px"
        },
        "& label": {
            display: "block",
            marginLeft: "0px",
            marginRight: "0px",
            marginTop: "5px"
        }
    }
});

export default restoreFileModalStyle;

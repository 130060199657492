import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from "prop-types";
import axios from "axios";
import Api from "../../assets/js/utils/Api";
import { helper } from "../../assets/js/utils/Element";
import MaterialIcon from "@mdi/react";
import { mdiArrowDown, mdiClose, mdiFolder } from '@mdi/js';
import Button from "../components/CustomButtons/Button";
import LoaderComponent from "../components/Loader";
import withStyles from "@material-ui/core/styles/withStyles";
import downloadPageStyle from "../../assets/jss/material-kit-pro-react/views/downloadPageStyle.jsx";
import { Link as RouterLink } from 'react-router-dom';
import Link from "@material-ui/core/Link";
import logo from "../../assets/img/logo.png";
import CustomInput from "../components/CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const Download = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        let recipient = "";
        if(this.props.match.params.hasOwnProperty("recipient")){
            recipient = this.props.match.params.recipient;
        }

        this.state = {
            response: null,
            id: this.props.match.params.id,
            recipient: recipient,
            loading: true,
            message: "",
            cancelToken: null,
            error: false,
            errorMessage: null,
            singleFile: true,
            showLogo: false,
            password: "",
            passwordIncorrect: false,
            viewPassword: false,
            validation: {
                password: "",
                isValid: false,
            },
        };
        this.cancelToken = null;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount(){
        this.loadPreview();
        document.body.classList.add('hide_header_business_account');
        document.body.classList.add('hide_business_background_photo');
    }
    componentDidUpdate(prevProps, prevState){
        const { response } = this.state;
        if(prevState.response === null && response !== null && response.hasOwnProperty('businessUser') && response.businessUser !== null && (response.businessUser.icon_photo_url !== '' && response.businessUser.icon_photo_url !== null)){
            document.body.classList.add('business_account');
            document.body.classList.remove('hide_header_business_account');
            this.setState({
                showLogo: true
            })
        }else if(prevState.response === null && response !== null){
            document.body.classList.remove('hide_header_business_account');
        }

        if(prevState.response === null && response !== null && response.hasOwnProperty('businessUser') && response.businessUser !== null && (response.businessUser.background_photo !== '' && response.businessUser.background_photo !== null)){
            document.body.classList.add('business_background_photo');
            document.body.classList.remove('hide_business_background_photo');
        }else if(prevState.response === null && response !== null){
            document.body.classList.remove('hide_business_background_photo');
        }
    }
    componentWillUnmount(){
        if (this.cancelToken){
            this.cancelToken.cancel('Request Cancelled')
        }
        document.body.classList.remove('business_account');
        document.body.classList.remove('business_background_photo');
    }
    loadPreview(){
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source
        });
        const { id, recipient} = this.state;
        Api.getTransfer(id, recipient, source).then(data => {
            let filesCount = 0;
            if(data.files){
                filesCount = Object.keys(data.files).length;
            }
            that.setState({
                response: data,
                loading: false,
                error: false,
                singleFile: (filesCount === 1)
            });
        }).catch(err => {
            if(err.message !== "Request Cancelled"){
                this.setState({
                    loading: false,
                    error: true,
                    errorMessage: err.message
                });
            }
        });
    }
    renderFile(item, key){
        const { classes } = this.props;
        const { singleFile } = this.state;
        if(item.type === "file"){
            return <li key={key}>
                <div className="file">
                    <div className="file-header">
                        <div className={classes.poptip}>
                            <h6 className="file-title">{item.file.name}</h6>
                        </div>
                        <div className="file-details">
                            <span className="file-detail">{item.file.size}</span>
                            <span className="file-detail1">{item.file.extension}</span>
                            {
                                singleFile ?
                                    <></>
                                :
                                <Link href={item.file.downloadLink} className={"downloadIcon"} target="_blank">
                                    <Button color="custom" justIcon size="sm">
                                        <MaterialIcon path={mdiArrowDown} className="MuiSvgIcon-root" />
                                    </Button>
                                </Link>
                            }
                        </div>
                    </div>
                </div>
            </li>;
        }
        let sizeInMBs = (item.size/(1024*1024));
        return <li key={key}>
            <div className="file">
                <div className="file-header">
                    <div className={classes.poptip}>
                        <h6 className="file-title">{item.name}</h6>
                    </div>
                    <div className="file-details">
                        <span className="file-detail">
                            <MaterialIcon path={mdiFolder} className="MuiSvgIcon-root"/>
                            <span className="folder-detail">Folder</span>
                        </span>
                        <span className="filedetail1">{item.files.length} items</span>
                        {
                            singleFile || sizeInMBs >= 500 ?
                                <></>
                            :
                            <Link href={item.downloadLink} className={"downloadIcon"} target="_blank">
                                <Button color="custom" justIcon size="sm">
                                    <MaterialIcon path={mdiArrowDown} className="MuiSvgIcon-root" />
                                </Button>
                            </Link>
                        }
                    </div>
                </div>
            </div>
        </li>;
    }
    renderFiles(){
        const { response } = this.state;
        if(response === null){
            return null;
        }
        const { classes } = this.props;
        if(response.files.length > 10){
            return <li>
                <div className="file">
                    <div className="file-header">
                        <div className={classes.poptip}>
                            <h6 className="file-title">{response.files.length} files</h6>
                        </div>
                        <div className="file-details">
                            <span className="file-detail1">{helper.getFormatedSize(response.totalSize)}</span>
                        </div>
                    </div>
                </div>
            </li>
        }
        let renderFiles = [];
        for(var key in response.files){
            const item = response.files[key];
            renderFiles.push(this.renderFile(item, key));
        }
        // response.files.map((file, key) => {
        //     renderFiles.push(this.renderFile(file, key));
        //     return null;
        // });
        return renderFiles;
    }
    handleChange(e, name){
        let state = {};
        let value = e.target.value;
        state[name] = value;
        this.setState(state);
    }
    validateForm(){
        let validation = {
            password: 'success',
            isValid: true
        };

        const values = this.state;
        
        if(values.password.length <= 0){
            validation.password = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    handleSubmit(e){
        e.preventDefault();
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }

        const that = this;
        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, loading: true});
        
        const { id, recipient, password} = this.state;

        let requestData = {
            recipient: recipient,
            password: password
        };
        Api.previewPassword(id, requestData, source).then(data => {
            if(data.passwordIncorrect){
                that.setState({
                    passwordIncorrect: true,
                    loading: false
                })
                return;
            }
            
            let filesCount = Object.keys(data.files).length;
            that.setState({
                response: data,
                loading: false,
                error: false,
                singleFile: (filesCount === 1),
                passwordIncorrect: false
            });
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({
                    loading: false,
                    error: true,
                    errorMessage: err.message
                });
            }  
        });
    }
    handleAdUrl(e, bannerUrl){
        e.preventDefault();
        if(bannerUrl){
            window.open(bannerUrl, '_blank');
        }
    }
    onViewPassword(){
        this.setState({
            viewPassword: !this.state.viewPassword
        })
    }
    render() {
        const { classes } = this.props;
        const { response, loading, message, error, errorMessage, showLogo, validation, passwordIncorrect, viewPassword } = this.state;

        let htmlClass = 'layout_page_downloads';
        if(response && response.hasOwnProperty('businessUser') && response.businessUser !== null && response.businessUser.download_style === "boxed"){
            htmlClass += ' boxed_style';
        }

        const { user } = this.store.getState();
        
        return (
            <>
                <div className={classes.landingPageContainer}>
                    {
                        response && response.hasOwnProperty('businessUser') && response.businessUser !== null && (response.businessUser.profile_photo_url !== '' && response.businessUser.profile_photo_url !== null) ?
                            <div className={classes.businessLeagelHeader+" business-legal-header"}>
                                <img className={"business-logo"} src={response.businessUser.profile_photo_url} alt="company logo" />
                            </div>
                        :
                            <></>
                    }
                    <div className={classes.downloadPageContent}>
                        {
                            response && response.hasOwnProperty('businessUserAds') && response.businessUserAds !== null && ( response.businessUserAds.header_banner_photo !== '' && response.businessUserAds.header_banner_photo !== null ) ?
                                <div className={"downloadBottomAd downloadHeaderAd"}>
                                    <Link href="/" onClick={(e) => this.handleAdUrl(e, response.businessUserAds.header_banner_url)}>
                                        <img className="banner-image" src={response.businessUserAds.header_banner_photo} alt='ad-banner' />
                                    </Link>
                                </div>
                            :
                                <></>
                        }
                        <div className={"downloadTopColumns"}>
                            <div className={"leftAdColumn"}>
                                {
                                    response && response.hasOwnProperty('businessUserAds') && response.businessUserAds !== null && ( response.businessUserAds.left_banner_photo !== '' && response.businessUserAds.left_banner_photo !== null ) ?
                                        <Link href="/" onClick={(e) => this.handleAdUrl(e, response.businessUserAds.left_banner_url)}>
                                            <img className="banner-image" src={response.businessUserAds.left_banner_photo} alt='ad-banner' />
                                        </Link>
                                    :
                                        <></>
                                }
                            </div>
                            {
                                error === false && response !== null && response.hasPassword === true ?
                                    <div className={classes.transferForm+" password-form"}>
                                        <div className={classes.transferContent}>
                                            <RouterLink to={'/'} className={"closeIcon"}>
                                                <MaterialIcon path={mdiClose} className="MuiSvgIcon-root" />
                                            </RouterLink>
                                        </div>
                                        <div className={classes.downloaderHeading+" downloader-heading"}>
                                            <h2>Please enter password to access file(s)</h2>       
                                        </div>
                                        <form onSubmit={this.handleSubmit} className={"form"}>
                                            {
                                                passwordIncorrect === true ?
                                                    <div className={"passwordCheck-notValid-customizable"}>
                                                        <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                                                        <span className="checkPasswordText-lowerletter">Password is Incorrect</span>
                                                    </div>
                                                :
                                                <></>
                                            }
                                            <CustomInput
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.customFormControlClasses
                                                }}
                                                success={validation.password === "success"}
                                                error={validation.password === "error" || passwordIncorrect}
                                                id="input-password"
                                                labelText="Password"
                                                inputProps={{
                                                    onChange: (e) => this.handleChange(e, 'password'),
                                                    name: "password",
                                                    value: this.state.password,
                                                    type: viewPassword ? "text" : "password",
                                                    endAdornment: (
                                                        <InputAdornment 
                                                            className={classes.inputAdornment+' viewPasswordIcon'} 
                                                            onClick={() => this.onViewPassword() }
                                                            position='end'
                                                        >
                                                            {
                                                                this.state.password ?
                                                                    viewPassword ?
                                                                        <VisibilityOffIcon className={classes.inputAdornmentIcon}/>
                                                                    :
                                                                        <VisibilityIcon className={classes.inputAdornmentIcon}/>
                                                                :
                                                                    <></>
                                                            }
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <div className={classes.formFooter}>
                                                <Button color="custom" className={"form-button"} type="submit" round disabled={loading ? true: false}>
                                                    {
                                                        loading ?
                                                            <LoaderComponent color="white" />
                                                        :
                                                            "Submit"
                                                    }
                                                </Button>
                                            </div>
                                        </form>
                                    </div>
                                :
                                    <div className={classes.transferForm}>
                                        <div className={classes.transferContent}>
                                            <RouterLink to={'/'} className={"closeIcon"}>
                                                Close
                                                <MaterialIcon path={mdiClose} className="MuiSvgIcon-root" />
                                            </RouterLink>
                                            <div className={classes.downloaderHeading+" downloader-heading "+(error?classes.errorHeading:"")}>
                                                <div className="arrowIcon">
                                                    <MaterialIcon path={mdiArrowDown} className="MuiSvgIcon-root" />
                                                </div>
                                                {
                                                    error === true ?
                                                        <p className={classes.processingWarning}>{errorMessage}</p>
                                                    :
                                                        <h2>Ready when you are</h2>
                                                }
                                                {
                                                    response !== null ?
                                                        <>
                                                            {
                                                                response.deletion_time === "Unlimited" || response.expire_at === null ?
                                                                    <></>
                                                                :
                                                                    <p>Files will be deleted in {response.deletion_time}</p>
                                                            }
                                                            
                                                            <div className="downloader-message">
                                                                <div dangerouslySetInnerHTML={{__html: response.message}}></div>
                                                            </div>
                                                        </>
                                                    :
                                                    <></>
                                                }
                                            </div>
                                            {
                                                response !== null ?
                                                    <ul className={classes.filesList}>
                                                        {this.renderFiles()}
                                                    </ul>
                                                :
                                                <></>
                                            }
                                        </div>
                                        {
                                            error === false ?
                                                <div className={classes.transferFooter+" "+(showLogo ? 'footer-with-logo': '')}>
                                                    {
                                                        response !== null ?
                                                            response.state === "uploaded" ?
                                                                <p className={classes.processingWarning}>Please wait we are processing your files. It may take several minutes depending on total size of files.</p>
                                                            :
                                                            <a href={response.downloadUrl} className="transfer-button">
                                                                Download
                                                            </a>
                                                            
                                                        :
                                                            loading ?
                                                                <Button color="custom" disabled className="transfer-button" type="button" round>
                                                                    <LoaderComponent color="white" />
                                                                </Button>
                                                            :
                                                                message
                                                        
                                                    }
                                                    {
                                                        showLogo ?
                                                            <img src={logo} alt={"Sendlinx"} className='footer-logo' />
                                                        :
                                                            <></>
                                                    }
                                                </div>
                                            :
                                            <></>
                                        }
                                        {
                                            response && response.hasOwnProperty('businessUser') && response.businessUser !== null && response.businessUser.about !== null ?
                                                <div className={classes.aboutBusiness} dangerouslySetInnerHTML={{__html: response.businessUser.about.replace(/href/g, "target='_blank' href")}}></div>
                                            :
                                            <></>
                                        }
                                    </div>
                            }
                            <div className={"rightAdColumn"}>
                                {
                                    response && response.hasOwnProperty('businessUserAds') && response.businessUserAds !== null && ( response.businessUserAds.right_banner_photo !== '' && response.businessUserAds.right_banner_photo !== null ) ?
                                        <Link href="/" onClick={(e) => this.handleAdUrl(e, response.businessUserAds.right_banner_url)}>
                                            <img className="banner-image" src={response.businessUserAds.right_banner_photo} alt='ad-banner' />
                                        </Link>
                                    :
                                        <></>
                                }
                            </div>
                        </div>
                        {
                            response && response.hasOwnProperty('businessUserAds') && response.businessUserAds !== null && ( response.businessUserAds.bottom_banner_photo !== '' && response.businessUserAds.bottom_banner_photo !== null ) ?
                                <div className={"downloadBottomAd"}>
                                    <Link href="/" onClick={(e) => this.handleAdUrl(e, response.businessUserAds.bottom_banner_url)}>
                                        <img className="banner-image" src={response.businessUserAds.bottom_banner_photo} alt='ad-banner' />
                                    </Link>
                                </div>
                            :
                                <></>
                        }
                    </div>
                    <Helmet 
                        htmlAttributes={{
                            class: htmlClass,
                            title: process.env.REACT_APP_TITLE
                        }}
                    />
                </div>
                {
                    response && response.hasOwnProperty('businessUser') && response.businessUser !== null && (response.businessUser.background_photo !== '' && response.businessUser.background_photo !== null ) && user.theme === "standard" ?
                        <div className="business_bg_slider">
                            <div className={classes.backgroundSlider+" business-bg-slider"}>
                                <div className="background-image" style={{backgroundImage: 'url('+response.businessUser.background_photo+')'}}></div>
                            </div>
                        </div>
                    :
                        <></>
                }
            </>
        )
    }
}

Download.propTypes = {
    classes: PropTypes.object
};

export default withStyles(downloadPageStyle)(Download);

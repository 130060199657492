import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";

import errorPageStyle from "../../assets/jss/material-kit-pro-react/views/errorPageStyles.jsx";

class ErrorPage extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <div className={classes.pageHeader}>
                    <div className={classes.contentCenter}>
                        <GridContainer>
                            <GridItem md={12}>
                                <h1 className={classes.title}>403</h1>
                                <h2 className={classes.subTitle}>File not found.</h2>
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
        );
    }
}

ErrorPage.propTypes = {
    classes: PropTypes.object
};

export default withStyles(errorPageStyle)(ErrorPage);

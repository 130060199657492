import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import LoaderComponent from "../Loader";
import { helper } from '../../../assets/js/utils/Element';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CustomInput from "../CustomInput/CustomInput";
import sendlinxIcon from "../../../assets/img/sendlinx-icon.png";
import facebookIcon from "../../../assets/img/facebook-icon.png";
import twitterIcon from "../../../assets/img/twitter-icon.png";
import linkedinIcon from "../../../assets/img/linkedin-icon.png";
import emailIcon from "../../../assets/img/email-icon.png";
import generateQrIcon from "../../../assets/img/generate-qr-icon.png";
import newLinkModalStyle from "../../../assets/jss/user/newLinkModalStyle.jsx";
import MaterialIcon from "@mdi/react";
import {  mdiLink } from '@mdi/js';
import Link from "@material-ui/core/Link";
import QRCode from 'qrcode.react';
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ShareLinkModal from "./ShareLinkModal";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const NewLinkModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        
        this.state = {
            snackbarOpen: false,
            title: "",
            linkPassword: this.props.linkPassword,
            viewPassword: false,
            shareLinkModal: false
        };

        this.copyDownloadLink = this.copyDownloadLink.bind(this);
        this.closeSnackbar = this.closeSnackbar.bind(this);
    }
    copyDownloadLink(){
        let { downloadLink } = this.props;
        downloadLink = window.location.protocol+"//"+window.location.host+downloadLink;
        helper.copyTextToClipboard(downloadLink);
        this.setState({snackbarOpen: true});
    }
    closeSnackbar(){
        this.setState({snackbarOpen: false});
    }
    handleChange(e, name) {
        this.setState({
            [name]: e.target.value
        });
    }
    downloadQRCode(){
        const canvas = document.getElementById("qr-code");
        const pngUrl = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "qr-code.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }
    onViewPassword(){
        this.setState({
            viewPassword: !this.state.viewPassword
        })
    }
    onShareLinkModal(e){
        e.preventDefault();
        this.setState({
            shareLinkModal: true
        })
    }
    render() {
        const { classes, saving, loadingMessage, successMessage, errorMessage, downloadLink, linkName } = this.props;
        const { title, linkPassword, viewPassword, shareLinkModal } = this.state;
        let downloadUrl = window.location.protocol+"//"+window.location.host+downloadLink

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="processingModal-modal-slide-title"
                aria-describedby="processingModal-modal-slide-description"
            >
                <DialogTitle
                    id="processingModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    {
                        linkName ?
                            <Button
                                simple
                                className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                                key="close"
                                aria-label="Close"
                                onClick={() => this.props.onClose()}
                            >
                                {" "}
                                <CloseIcon className={classes.modalClose} />
                            </Button>
                        :
                            <></>
                    }
                    {
                        errorMessage !== null ?
                            <></>
                        :
                            saving ?
                                <h4 className={classes.modalTitle+" "+classes.textCenter}>{loadingMessage}</h4> 
                            :
                            <></>
                    }
                </DialogTitle>
                <DialogContent
                    id="processingModal-modal-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            linkName ?
                                <>
                                    <CustomInput
                                        labelText="Destination Link Name (optional)"
                                        id="input-linkTitle"
                                        value={title}
                                        inputProps={{
                                            name: "linkTitle",
                                            type: "text",
                                            onChange: (e) => this.handleChange(e, 'title'),
                                        }}
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Link Password (optional)"
                                        id="input-password"
                                        value={linkPassword}
                                        inputProps={{
                                            name: "linkPassword",
                                            type: viewPassword ? "text" : "password",
                                            onChange: (e) => this.handleChange(e, 'linkPassword'),
                                            endAdornment: (
                                                <InputAdornment 
                                                    className={classes.inputAdornment+' viewPasswordIcon'} 
                                                    onClick={() => this.onViewPassword() }
                                                    position='end'
                                                >
                                                    {
                                                        linkPassword ?
                                                            viewPassword ?
                                                                <VisibilityOffIcon className={classes.inputAdornmentIcon}/>
                                                            :
                                                                <VisibilityIcon className={classes.inputAdornmentIcon}/>
                                                        :
                                                            <></>
                                                    }
                                                </InputAdornment>
                                            ),
                                        }}
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                    />
                                </>
                            :
                            errorMessage !== null ?
                                <p className={classes.errorMessage}>{errorMessage}</p>
                            :
                                saving ?
                                    <LoaderComponent color="custom" align="center" />
                                :
                                <>
                                    <h4>{successMessage}</h4>
                                    {
                                        downloadLink !== null ?
                                            <>
                                                <div className={classes.downloadLink}>
                                                    <p>Share your link:</p>
                                                    <div className={classes.dNone}>
                                                        <QRCode value={downloadUrl} id='qr-code' />
                                                    </div>
                                                    <ul className='share-icon-lists'>
                                                        <li className='sendlinx-icon'>
                                                            <a href='/' onClick={(e) => this.onShareLinkModal(e)}>
                                                                <img src={sendlinxIcon} alt="sendlinx" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href={'https://www.facebook.com/sharer/sharer.php?u='+downloadUrl} target="_blank" rel="noopener noreferrer">
                                                                <img src={facebookIcon} alt="facebook" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href={'https://twitter.com/intent/tweet?url='+downloadUrl} target="_blank" rel="noopener noreferrer">
                                                                <img src={twitterIcon} alt="twitter" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href={'https://www.linkedin.com/shareArticle?mini=true&url='+downloadUrl} target="_blank" rel="noopener noreferrer">
                                                                <img src={linkedinIcon} alt="linkedin" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href={'mailto:?&subject=&cc=&bcc=&body='+downloadUrl} target="_blank" rel="noopener noreferrer">
                                                                <img src={emailIcon} alt="email" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <Link onClick={this.downloadQRCode}>
                                                                <img src={generateQrIcon} alt='generate qr code' />
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                    <div className='download-link-section'>
                                                        <div className='icon'>
                                                            <MaterialIcon path={mdiLink} className="MuiSvgIcon-root" />    
                                                        </div>
                                                        <p>{downloadUrl}</p>
                                                        <Button color="custom" size='sm' type="button" round onClick={this.copyDownloadLink} >Copy Link</Button>
                                                    </div>
                                                </div>
                                                
                                            </>
                                        :
                                        <></>
                                    }
                                </>
                        }
                </DialogContent>
                <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                    {
                        linkName ?
                            <Button color="custom" onClick={() => this.props.onLinkName(title, linkPassword)} round>
                                Continue
                            </Button>
                        :
                        saving === false ?
                            <>
                                <Button color="custom" onClick={() => this.props.onSuccess()} round>
                                    OK
                                </Button>
                            </>
                        :
                        <></>
                    }
                </DialogActions>
                {
                    shareLinkModal ?
                        <ShareLinkModal
                            open={shareLinkModal}
                            store={this.store}
                            history={this.history}
                            linkPassword={linkPassword}
                            downloadLink={downloadUrl}
                            onClose={() => this.props.onClose()}
                            onSuccess={() => this.props.onSuccess()}
                            linkHash={this.props.linkHash}
                            expire={this.props.expire}
                        />
                    :
                    null
                }
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    className={classes.snackbar}
                    open={this.state.snackbarOpen}
                    autoHideDuration={1000}
                    onClose={this.closeSnackbar}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">Your link has been copied</span>}
                    action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.closeSnackbar}
                    >
                        <CloseIcon />
                    </IconButton>,
                    ]}
                />
            </Dialog>
        )
    }
}

NewLinkModal.defaultProps = {
    open: false,
    loadingMessage: "",
    successMessage: "",
    saving: false,
    errorMessage: null,
    downloadUrl: null,
    linkName: false,
    onLinkName: function(){ },
    linkPassword: ""
}
NewLinkModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    loadingMessage: PropTypes.string,
    saving: PropTypes.bool,
    errorMessage: PropTypes.string,
    downloadUrl: PropTypes.string,
    linkName: PropTypes.bool,
    onLinkName: PropTypes.func
}
export default withStyles(newLinkModalStyle)(NewLinkModal);